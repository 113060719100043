export const color = {
  bg_login: "#DEDEDE",
  rizz_main: "#D41B24", //D41B24
  rizz_secondary: "#24231D",
  rizz_primary: "#D41B24",
  rizz_ems: "#EE220C",
  rizz_process: "#FF9300",
  rizz_complete: "#60D936",

  dplus_primary: "#fd8103",
  dplus_main: "#fd8103",
  dplus_secondary: "#24231D",
  dplus_disable: "#ffb66c",

  focus_waitpay: "#FF9300",
  focus_waittocheck: "#FFFB01",
  focus_successpay: "#faad14",
  focus_complete: "#60D936",
  focus_reject: "#EE220C",
  focus_register: "#98ccfc",
};
