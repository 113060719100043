import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../components/hoc/WrapperComponents";
import { Table, Button as BT, Modal, Row, Col, Input, DatePicker, Select, Icon } from "antd";
import { Label } from "../../styles/styled";
import { ColumnStoreScene, color } from "../../resource";
import {
  POST,
  GET,
  GET_ALL_STORE,
  ADD_STORE,
  EDIT_STORE
} from "../../service";
import "react-image-lightbox/style.css";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
const page_name = 'ร้านค้า'


@WrapperComponents()
@observer
class StoreScene extends Component {
  
  constructor(props) {
    
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      visible: false,
      visibleActive: false,
      search: '',
      data: [],
      optionLevel: [],
      dataSet: {},
      optionCompany: [{ label: 'Focus Shop', value: 'FOCUS_SHOP' }, { label: 'Apple House', value: 'APPLE_HOUSE' }],
      optionStatus: [{ value: 'ACTIVE', label: 'ACTIVE' }, { value: 'INACTIVE', label: 'INACTIVE' }]
    }
  }

  componentDidMount() {
    this.onGetAll()
  }

  async onGetAll(){
    try {

      this.setState({ isLoadingData: true})

      const search = this.state.search || ""

      const res = await GET(`${GET_ALL_STORE}?search=${search}`);
      if (res.success) {
        this.setState({ 
          data: res.result.stores.map( (e, i) => {
            return {
              no: i+1,
              ...e
            }
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
    finally{
      this.setState({ isLoadingData: false})
    }
  }

  onAdd = () => {
    this.setState({ 
      action: 'ADD',
      title: 'เพิ่มร้านค้า',
      btnOK: 'บันทึก',
      visible: true
    })
    this.setState({ 
      dataSet: { 
        status: 'ACTIVE'
    } })
  }

  onEdit(record){
    this.setState({
      action: 'EDIT',
      title: 'แก้ไขร้านค้า',
      btnOK: 'แก้ไข',
      visible: true
     })
    this.setState({ dataSet: record })
  }

  async handleOk(){
    try {

        const { action } = this.state
        if(!action){
          Modal.error({ title: "NO ACTION" })
        }
        this.setState({ loading: true })
        let r = {}

        const dataSet = this.state.dataSet
        const data = {
          store_no: dataSet.store_no,
          store_name: dataSet.store_name,
          branch_name: dataSet.branch_name,
          company: dataSet.company,
          status: dataSet.status
        }

        if(action === 'ADD'){
          r = await POST(ADD_STORE, data)
        }else{
          r = await POST(EDIT_STORE, data)
        }
        
        const { success, message } = r
        if(success){
          await this.onGetAll()
          this.setState({ visible: false })
          Modal.success({ title: message })
        }
 

    } catch (error) {
      Modal.error({ title: error.message })
    }
    finally{
      this.setState({ loading: false })
    }
   
  }

  onChange(e){
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        [e.target.name]: e.target.value
      }
    })
  }

  onSelect(obj){
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        [obj.e.props.name]: obj.v
      }
    })
  }

  onExcel = () => {
      const fileName = `${moment().format("YYYYMMDD_mmss")}`
      const column = ColumnStoreScene().map(e => e.title)
      // const field = ColumnReportFrequencyCustomerScene().map(e => e.dataIndex)
      const data = this.state.data.map(e => {
        return {
          no: e.no,
          store_no: e.store_no,
          store_name: e.store_name,
          branch_name: e.branch_name,
          company: e.company,
          status: e.status
        }
      })

      const datas = [{
          sheetData: data,
          sheetName: ['รายงาน'],
          sheetHeader: column
        }
      ]
      const toExcel = new ExportJsonExcel({ fileName, datas });
      toExcel.saveExcel();
  }

  render() {
    const {
      data,
      isLoadingData,
    } = this.state;

    return (
      <div>

      <Modal
            title={
              <p style={{ textAlign: "center", marginBottom: "0px" }}>
                <Icon type='shop' /> {this.state.title}
              </p>
            }
            visible={this.state.visible}
            onOk={()=> this.handleOk()}
            onCancel={()=> { this.setState({ visible: false }) }}
            width={"35vw"}
            confirmLoading={this.state.loading}
            cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
            okText={<font style={{ fontSize: "1vw" }}>{this.state.btnOK}</font>}
          >

        <Row style={{ marginBottom: "1em" }} gutter={16}> 
          <Col span={12} >
            <Label>รหัสร้านค้า <span style={{ color: "red" }}>* </span></Label>
            <Input
              disabled={this.state.action === 'EDIT' ? true : false }
              required
              name="store_no"
              value={this.state.dataSet.store_no}
              onChange={(e) => this.onChange(e)}
            />
          </Col>
          <Col span={12}>
            <Label>ชื่อร้านค้า <span style={{ color: "red" }}>* </span></Label>
            <Input 
              required
              value={this.state.dataSet.store_name}
              name="store_name"
              maxLength={10}
              onChange={(e) => this.onChange(e)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "1em" }} gutter={16}>
          <Col span={24}>
            <Label>สาขา <span style={{ color: "red" }}>* </span></Label>
            <Input 
              required
              value={this.state.dataSet.branch_name}
              name="branch_name"
              maxLength={10}
              onChange={(e) => this.onChange(e)}
            />
          </Col>
     
        </Row>
        <Row style={{ marginBottom: "1em" }} gutter={16}>
          <Col span={12}>
            <Label>บริษัท <span style={{ color: "red" }}>* </span></Label>
              <Select  
                required
                onSelect={((v, e) => this.onSelect({ v, e }))}
                value={this.state.dataSet.company}
                style={{ width: '100%'}}>
                {this.state.optionCompany.map((e, i) => (
                  <Select.Option name={'company'} key={i} value={e.value}>{e.label}</Select.Option>
                ))}
              </Select>
          </Col>
          <Col span={12}>
            <Label>สถานะ <span style={{ color: "red" }}>* </span></Label>
              <Select  
                required
                onSelect={((v, e) => this.onSelect({ v, e }))}
                value={this.state.dataSet.status}
                style={{ width: '100%'}}>
                {this.state.optionStatus.map((e, i) => (
                  <Select.Option name={'status'} key={i} value={e.value}>{e.label}</Select.Option>
                ))}
              </Select>
          </Col>
        </Row>

        </Modal>


        <div className={"column"}>
            {/*###################### HEAD ######################*/}

            <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}> 
              <Input.Search
                  placeholder="ค้นหารายการ" 
                  name="search" 
                  onChange={e => this.setState({ search: e.target.value })}
                  onPressEnter={()=>{this.onGetAll()}}
                  onSearch={() => this.onGetAll()}
                  // onKeyPress={()=>{this.onGetAll()}}
                 
                  />  
            </Col>
          
            <Col span={1}><BT 
                  onClick={()=>{this.onGetAll()}}
                  type="primary"
                  style={{
                    backgroundColor: color.dplus_primary,
                    borderColor: color.dplus_primary,
                  }}
                  >ค้นหา</BT></Col>
          </Row>


          {/*###################### TABLE ######################*/}

          <Table
            style={{ textAlign: "cenetr" }}
            title={() => 
                    <div className={"row-between"}>
                    <h3>{page_name}</h3>  
                    {/* <BT 
                      disabled={ this.state.data.length === 0 ? true : false }
                      style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: this.state.data.length && "#fff",
                          backgroundColor: this.state.data.length > 0 && "#4caf50",
                          borderColor: this.state.data.length > 0 && "#4caf50",
                          alignItems: "center",
                          
                      }}
                      onClick={this.onExcel} 
                        > {'Export excel'}
                      </BT>  */}
                      <BT 
                      style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#fff",
                          backgroundColor: "#007bff",
                          borderColor: "#007bff",
                          alignItems: "center",
                        }}
                      onClick={this.onAdd} 
                        > {'เพิ่ม'}
                    </BT> 
                    </div>
                  }
            columns={ ColumnStoreScene (
              (record) => this.onEdit(record)
            )}
            dataSource={data}
            bordered
            pagination={{ pageSize: 10 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
          />
        </div>
      </div>
    );
  }

}

export default StoreScene;
