import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../components/hoc/WrapperComponents";
import dayjs from "dayjs";
import moment from "moment";
import {
  Table,
  Button as BT,
  Modal,
  Input,
  message,
  DatePicker,
  Icon,
  Row,
  Col,
  Select,
} from "antd";
import { columnTableClaimFocus, color, STORE_FOCUS } from "../../resource";
import autobind from "autobind-decorator";
import {
  GET,
  GET_ALL_CLAIM,
  POST,
  UPDATE_TRACKING,
  UNACTIVE,
  UPDATE_CLAIM_STATUS,
  PATH_IMAGE,
  GET_OPTION_SHIPPING,
  GET_OPTION_STATUS_INTERNAL,
  CREATE_TRACKING,
  SEND_MESSAGE_LINE,
  EDIT_NOTE,
  EDIT_STATUS_INTERNAL,
} from "../../service";
import { encryptParameter } from "../../functions/functions";
import { ModalEMS, ModalActive } from "../../components/Modal";
import Lightbox from "react-image-lightbox";
import { PATH_IMAGE_PAYMENT } from "../../service";
import "react-image-lightbox/style.css";
import ExportJsonExcel from "js-export-excel";
import { ModalDetailClaim } from "./modal";
const fn = require("../../functions/functions");
let defaultData = [];

@WrapperComponents()
@observer
class ClaimScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      loading: false,
      isLoadingData: true,
      visible: false,
      visibleActive: false,
      visibleModalMessage: false,
      visibleModalNote: false,
      visibleModalDetail:false,
      visibleModalStatusInternal: false,
      shipping_date: moment(),
      tracking: "",
      shipping_id: "",
      shipping_name: "",
      unactive_code: "",
      data: [],
      searchText: "",
      fullTextSearch: "",
      claim_doc: "",
      isLoadingEMS: false,
      isLoadingActive: false,
      no_warranty: "",
      shipping_type: "SELF",
      option_shipping: [],
      option_status_internal: [],
      start_date: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      isOpen: false,
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,
      dataSet: {},
      message: "",
      note: "",
      status_internal: "",
      expanded:false
    };
  }

  componentDidMount() {
    this.onFetchClaimFocus();
    this.getOptionShipping();
    this.getOptionStatusInternal();
  }

  getOptionShipping = async () => {
    const { shipping_type } = this.state;
    try {
      const res = await GET(GET_OPTION_SHIPPING(shipping_type));
      const { success, result } = res;
      if (success) {
        const option = result.map((el) => {
          return {
            value: `${el.shipping_id}|${el.shipping_name}`,
            label: el.shipping_name,
          };
        });

        this.setState({
          option_shipping: option,
          shipping_id: "",
          shipping_name: "",
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("Claim => getOptionShipping", error.message);
    }
  };

  getOptionStatusInternal = async () => {
    try {
      const res = await GET(GET_OPTION_STATUS_INTERNAL);
      if (res.success) {
        this.setState({ option_status_internal: res.result.option });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor(
        "Claim => getOptionStatusInternal",
        error.message
      );
    }
  };

  @autobind
  async onFetchClaimFocus() {
    this.setState({ isLoadingData: true });
    const { user_role, fullTextSearch } = this.state;
    let obj = {
      date_start: !fullTextSearch ? this.state.start_date : "",
      date_end: !fullTextSearch ? this.state.end_date : "",
      text_search: fullTextSearch || "",
      role: user_role,
    };
    try {
      const res = await POST(GET_ALL_CLAIM, obj);
      const start = moment(new Date());

      if (res.success) {
        const data = res.result.map((el, i) => {
          const end = moment(el.dateexp);
          const diff = end.diff(start, "days");

          return {
            ...el,
            no: i + 1,
            key: el.no_claim,
            time_warranty: diff <= 0 || el.status === 4 ? 0 : diff,
            dateclaim_formate: moment(el.dateclaim).format("DD/MM/YYYY"),

            shipping_formate: el.shipping_date
              ? dayjs(el.shipping_date).format("DD/MM/YYYY")
              : "",
            confirm_ems:
              el.status === 3 && (el.tracking === null || el.tracking === "")
                ? "กรอกเลขพัสดุ"
                : el.tracking,
            unactive:
              (el.status === 3 || el.status === 4) &&
              (el.unactive_code === null || el.unactive_code === "")
                ? "รหัสประกัน"
                : el.unactive_code,
            register_type: el.type,
            shipping_id: el.shipping_id,
            shipping_name: el.shipping_name,
            shipping_type: el.shipping_type,
          };
        });
        defaultData = data;

        this.setState({
          data,
          isLoadingData: false,
          visible: false,
          tracking: "",
          isLoadingEMS: false,
          isLoadingActive: false,
          visibleActive: false,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("Claim => onFetchClaimFocus", error.message);
    }
  }
  @autobind
  downloadExcel() {
    const data_excel = this.state.data;
    let option = {};
    let datatable = [];
    let date_export = dayjs(new Date()).format("DD-MM-YYYY");

    if (data_excel) {
      data_excel.map((el) => {
        datatable.push({
          no_claim: el.no_claim,
          name: el.name,
          line_name: el.line_name,
          tel: el.tel,
          dateclaim: moment(el.dateclaim).format("DD-MM-YYYY HH:mm:ss"),
          brandmobile: el.brandmobile,
          modelmobile: el.modelmobile,
          itemcode: el.item_code,
          nameproduct: el.nameproduct,
          detail: el.detail,
          time_warranty: el.time_warranty,
          status_name: el.status_name_th,
          status_internal_name: el.status_internal_name,
          confirm_ems: el.confirm_ems,
          shipping_date: el.shipping_date,
          address: el.address,
          line_name: el.line_name,
          note: el.note,
        });
      });
    }
    option.fileName = `รายงานลงทะเบียนรับประกันสินค้า ${date_export}`;
    option.datas = [
      {
        sheetData: datatable,
        sheetName: date_export,
        sheetHeader: [
          "เลขที่เคลม",
          "ชื่อ-นามสกุล",
          "ชื่อไลน์",
          "เบอร์โทร",
          "วันที่เคลม",
          "แบรนด์",
          "รุ่น",
          "รหัสสินค้า",
          "ชื่อสินค้า",
          "สาเหตุการเคลม",
          "วันคงเหลือ",
          "สถานะ",
          "สถานะภายใน",
          "เลขพัสดุ",
          "วันที่ที่จัดส้ง",
          "ที่อยู่จัดส่ง",
          "หมายเหตุ",
        ],
      },
    ];
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  start = () => {
    let claim_doc = this.state.selectedRowKeys;
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState(
        {
          selectedRowKeys: [],
          loading: false,
        },
        () =>
          this.props.history.push({
            pathname: "/billshipping",
            state: {
              id: encryptParameter({ claim_id: claim_doc }),
            },
          })
      );
    }, 1000);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  /**
   * ฟังก์ชั่นสำหรับวิ่งไปหน้า Detail Claim
   */
  @autobind
  goDetail(record) {
    this.props.history.push(`/claim_detail?id=${record.no_claim}`);
  }
  @autobind
  goCreate() {
    this.props.history.push("/claim_create");
  }

  @autobind
  insertEMS(record, type) {
    const {
      no_claim,
      no_warranty,
      tracking,
      shipping_date,
      user_id,
      shipping_id,
      shipping_name,
      tel,
      shipping_type,
    } = record;

    if (type === 0) {
      this.setState({
        visible: true,
        claim_doc: no_claim,
        type,
        no_warranty,
        user_id,
        shipping_id,
        shipping_name,
        tel,
        shipping_type,
      });
    } else {
      this.setState({
        visible: true,
        claim_doc: no_claim,
        type,
        shipping_id,
        shipping_name,
        shipping_date: shipping_date ? moment(shipping_date) : moment(),
        tracking: tracking,
        no_warranty,
        user_id,
        tel,
        shipping_type,
      });
    }
  }
  @autobind
  insertUnactiveCode(record, type) {
    const { no_claim, no_warranty, unactive_code } = record;

    if (type === 0) {
      this.setState({
        visibleActive: true,
        claim_doc: no_claim,
        type,
        no_warranty,
      });
    } else {
      this.setState({
        visibleActive: true,
        claim_doc: no_claim,
        type,
        unactive_code,
        no_warranty,
      });
    }
  }

  @autobind
  handleCancel() {
    this.setState({
      visible: false,
      claim_doc: "",
      type: 0,
      shipping_date: moment(),
      tracking: "",
      shipping_id: "",
    });
  }
  @autobind
  handleCancel_active() {
    this.setState({ visibleActive: false, type: 0, unactive_code: "" });
  }

  @autobind
  async handleOk() {
    const {
      tracking,
      shipping_date,
      no_warranty,

      shipping_id,
      shipping_type,
      shipping_name,
      claim_doc,
    } = this.state;
    if (
      !shipping_type ||
      (shipping_type === "SELF" && (!tracking || !shipping_name))
    ) {
      message.error("กรุณาใส่ข้อมูลให้ครบ");
    } else if (
      !shipping_type ||
      (shipping_type === "SYSTEM" && !shipping_name)
    ) {
      message.error("กรุณาใส่ข้อมูลให้ครบ");
    } else {
      this.setState({ isLoadingEMS: true }, async () => {
        try {
          let obj = {
            shipping_id,
            shipping_date,
            shipping_type,
          };

          if (shipping_type === "SYSTEM") {
            const obj_create = {
              no_claim: claim_doc,
              ...obj,
            };

            let res_tracking = await POST(CREATE_TRACKING, obj_create);
            if (res_tracking.success) {
              let res = await POST(UPDATE_TRACKING, {
                status: "TRANSPORTED",
                tracking: res_tracking.result.tracking,
                no_warranty,
                sorting_code: res_tracking.result.sorting_code,
                ...obj,
              });
              if (res.success) {
                Modal.success({ title: res.message });
                this.onFetchClaimFocus();
              }
            }
          } else {
            obj = { ...obj, status: "TRANSPORTED", tracking, no_warranty };
            let res = await POST(UPDATE_TRACKING, obj);
            if (res.success) {
              Modal.success({ title: res.message });
              this.onFetchClaimFocus();
            }
          }
        } catch (error) {
          this.setState({ visible: false, ems: "", isLoadingEMS: false });
          Modal.error({ title: error.message });
          return fn.larkNotiMonitor("Claim => handleOk", error.message);
        }
      });
    }
  }
  @autobind
  async handleOk_active() {
    const { unactive_code, no_warranty } = this.state;

    if (!unactive_code) {
      message.error("กรุณาใส่ข้อมูลให้ครบ");
      return;
    } else {
      this.setState({ isLoadingActive: true }, async () => {
        try {
          const res = await POST(UNACTIVE, {
            unactive_code,
            no_warranty,
          });

          if (res.result) {
            this.onFetchClaimFocus();
          } else {
            message.error("เลขรับประกันไม่ถูกต้องหรืออาจถูกใช้งานไปแล้ว");
            this.setState({ isLoadingActive: false });
          }
        } catch (error) {
          this.setState({
            visibleActive: false,
            unactive_code: "",
            isLoadingActive: false,
          });
        }
      });
    }
  }

  onModalMessage(record) {
    this.setState({
      visibleModalMessage: true,
      dataSet: record,
      message: `เลขที่เคลม ${record.no_claim}: `,
    });
  }

  onModalDetail(record) {
    this.setState({
      visibleModalDetail: true,
      dataSet: record,
      message: `เลขที่เคลม ${record.no_claim}: `,
    });
  }

  onModalNote(record) {
    this.setState({
      visibleModalNote: true,
      dataSet: record,
      note: record.note,
    });
  }

  onModalStatusInternal(record) {
    this.setState({
      visibleModalStatusInternal: true,
      dataSet: record,
      status_internal: record.status_internal,
    });
  }

  onChangeMessage(message) {
    this.setState({ message });
  }

  onChangeNote(note) {
    this.setState({ note });
  }

  onChangeStatusInternal(status_internal) {
    this.setState({ status_internal });
  }

  async handleSendMessage() {
    try {
      this.setState({ loading: true });
      const { line_id } = this.state.dataSet;
      const message = this.state.message;

      if (!line_id) {
        Modal.error({ title: "ยังไม่ได้ OTP ผ่าน LINE" });
        return;
      }

      const r = await POST(SEND_MESSAGE_LINE, { line_id, message });
      if (r.success) {
        this.setState({ visibleModalMessage: false });
        Modal.success({ title: r.message });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleEditNote() {
    try {
      this.setState({ loading: true });
      const { no_claim } = this.state.dataSet;
      const { note } = this.state;

      const r = await POST(EDIT_NOTE, { no_claim, note });
      if (r.success) {
        this.setState({ visibleModalNote: false });
        Modal.success({ title: r.message });

        this.onFetchClaimFocus();
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleEditStatusInternal() {
    try {
      this.setState({ loading: true });
      const { no_claim } = this.state.dataSet;
      const { status_internal } = this.state;

      const r = await POST(EDIT_STATUS_INTERNAL, { no_claim, status_internal });
      if (r.success) {
        this.setState({ visibleModalStatusInternal: false });
        Modal.success({ title: r.message });

        this.onFetchClaimFocus();
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  @autobind
  onChange(e, ref) {
    if (ref === "shipping") {
      const id = e.split("|")[0];
      const name = e.split("|")[1];

      this.setState({ shipping_id: id, shipping_name: name });
    } else if (ref === "fullTextSearch") {
      this.setState({ fullTextSearch: e });
    } else {
      this.setState({ [ref]: e }, () =>
        ref === "shipping_type" ? this.getOptionShipping() : ""
      );
    }
  }

  @autobind
  onKeyPressSearch(event, type) {
    if (event.key === "Enter") {
      if (type === "fullTextSearch") {
        this.setState({ fullTextSearch: event.target.value });
        this.onFetchClaimFocus();
      } else if (type === "searchText") {
        this.onSearch();
      }
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }
  @autobind
  onChangeRangePicker(dates) {
    let start = moment(dates[0]).format("YYYY-MM-DD");
    let end = moment(dates[1]).format("YYYY-MM-DD");

    this.setState(
      {
        selectedRowKeys: [],
        start_date: start,
        end_date: end,
        isLoadingData: true,
      },
      () => this.onFetchClaimFocus()
    );
  }
  @autobind
  clickImage(src_image) {
    this.setState({
      zoomImage: `${PATH_IMAGE}${src_image}`,
      isOpen: true,
    });
  }
  @autobind
  async approveClaim() {
    const { data, selectedRowKeys } = this.state;
    let result = [];
    let notcolect = [];
    result = selectedRowKeys.map((sl) => {
      let r = data.filter((el) => el.no_claim === sl);
      if (r[0]) {
        return {
          no_claim: r[0].no_claim,
          no_warranty: r[0].no_warranty,
          user_id: r[0].user_id,
          status: r[0].status,
        };
      }
    });

    result.map((el) => {
      if (el.status !== 2) {
        notcolect.push(el.no_claim);
      }
    });
    if (notcolect.length > 0) {
      message.error(`สถานะงานเคลม :${notcolect[0]} ไม่สามารถอนุมัติได้`);
    } else {
      this.setState({ isLoadingData: true });
      try {
        let res;
        await Promise.all(
          result.map(async (el) => {
            res = await POST(UPDATE_CLAIM_STATUS, {
              no_warranty: el.no_warranty,
              status: "USED",
              user_id: el.user_id,
              ems_tracting: "",
              reject_detail: "",
            });
          })
        );
        if (res.success) {
          this.onFetchClaimFocus();
        }
      } catch (error) {
        Modal.error({ title: error.message });
        return fn.larkNotiMonitor("Claim => approveClaim", error.message);
      }
    }
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      data,
      isLoadingData,
      shipping_date,
      tracking,
      searchText,
      unactive_code,
      isOpen,
      option_shipping,
      option_status_internal,
      shipping_type,
      shipping_id,
      shipping_name,
      fullTextSearch,
      dataSet,
      visibleModalDetail,
      expanded
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        {/*###################### MODAL TRICKING ######################*/}
        <Modal
          title={
            <p style={{ textAlign: "center", marginBottom: "0px" }}>
              กรุณาใส่เลขพัสดุ
            </p>
          }
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={"30vw"}
          confirmLoading={this.state.isLoadingEMS}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.rizz_primary,
              color: "#fff",
              borderColor: color.rizz_primary,
            },
          }}
        >
          <ModalEMS
            obj={{
              shipping_date,
              tracking,
              option_shipping,
              shipping_type,
              shipping_id,
              shipping_name,
            }}
            onChange={(e, ref) => this.onChange(e, ref)}
          />
        </Modal>
        {/*###################### MODAL Activate ######################*/}
        <Modal
          title={
            <p style={{ textAlign: "center" }}>
              กรุณาใส่เลขรหัสที่ต้องการยกเลิก
            </p>
          }
          visible={this.state.visibleActive}
          onOk={this.handleOk_active}
          onCancel={this.handleCancel_active}
          width={"30vw"}
          confirmLoading={this.state.isLoadingActive}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          okButtonProps={{
            style: {
              backgroundColor: color.rizz_primary,
              color: "#fff",
              borderColor: color.rizz_primary,
            },
          }}
        >
          <ModalActive
            obj={{ unactive_code }}
            onChange={(e, ref) => this.onChange(e, ref)}
          />
        </Modal>
        {/*###################### MODAL SEND MESSAGE ######################*/}
        <Modal
          title={
            <p style={{ textAlign: "center", marginBottom: "0px" }}>
              <Icon type="message" /> ส่งข้อความ
            </p>
          }
          visible={this.state.visibleModalMessage}
          onOk={(e) => this.handleSendMessage(e)}
          onCancel={() => {
            this.setState({ visibleModalMessage: false });
          }}
          width={"35vw"}
          confirmLoading={this.state.loading}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ส่ง</font>}
        >
          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={24}>
              {/* <p>{`${dataSet.no_claim}`}</p> */}
              <Input.TextArea
                style={{
                  width: "100%",
                  alignItems: "right",
                  marginRight: "10px",
                }}
                // defaultValue={`เลขที่เคลม ${dataSet.no_claim}: ${this.state.message}`}
                value={this.state.message}
                showCount={true}
                maxLength={500}
                onChange={(e) => this.onChangeMessage(e.target.value)}
                autosize={{ minRows: 6 }}
              />
            </Col>
          </Row>
        </Modal>
        {/*###################### MODAL SEND REMARK ######################*/}
        <Modal
          title={
            <p style={{ textAlign: "center", marginBottom: "0px" }}>
              หมายเหตุ
              <div>{`${dataSet.no_claim} ${dataSet.name} `}</div>
            </p>
          }
          visible={this.state.visibleModalNote}
          onOk={(e) => this.handleEditNote(e)}
          onCancel={() => {
            this.setState({ visibleModalNote: false });
          }}
          width={"35vw"}
          confirmLoading={this.state.loading}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>บันทึก</font>}
        >
          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={24}>
              <Input.TextArea
                style={{
                  width: "100%",
                  alignItems: "right",
                  marginRight: "10px",
                }}
                // defaultValue={`เลขที่เคลม ${dataSet.no_claim}: ${this.state.message}`}
                value={this.state.note}
                showCount={true}
                maxLength={500}
                onChange={(e) => this.onChangeNote(e.target.value)}
                autosize={{ minRows: 6 }}
              />
            </Col>
          </Row>
        </Modal>
        {/*###################### MODAL EDIT STATUS INTERNAL ######################*/}
        <Modal
          title={
            <p style={{ textAlign: "center", marginBottom: "0px" }}>
              แก้ไขสถานะภายใน
              <div>{`${dataSet.no_claim} ${dataSet.name} `}</div>
            </p>
          }
          visible={this.state.visibleModalStatusInternal}
          onOk={(e) => this.handleEditStatusInternal(e)}
          onCancel={() => {
            this.setState({ visibleModalStatusInternal: false });
          }}
          width={"25vw"}
          confirmLoading={this.state.loading}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>แก้ไข</font>}
        >
          <Row style={{ marginBottom: "1em" }} gutter={24}>
            <Col span={24}>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => this.onChangeStatusInternal(e)}
                placeholder="เลือกสถานะ"
                value={this.state.status_internal}
              >
                {option_status_internal.map((el, i) => {
                  return (
                    <Select.Option value={el.value}>{el.label}</Select.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Modal>
        <ModalDetailClaim record={dataSet} visible={visibleModalDetail} onClose={()=>this.setState({visibleModalDetail:false})} fn={this.clickImage}/>
        {isOpen && (
          <Lightbox
            mainSrc={this.state.zoomImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}

        <div className={"column"}>
          {/*###################### HEAD ######################*/}
          <div className={"row-between"}>
            <div style={{ display: "flex" }}>
              <BT
                type="primary"
                onClick={this.start}
                disabled={!hasSelected}
                loading={loading}
              >
                บิลใบส่งสินค้า
              </BT>
              <BT
                type="primary"
                style={{
                  backgroundColor: color.dplus_primary,
                  borderColor: color.dplus_primary,
                }}
                onClick={this.downloadExcel}
                loading={loading}
              >
                Export excel
              </BT>
              <span style={{ marginLeft: 8 }}>
                {hasSelected ? `เลือก ${selectedRowKeys.length} รายการ` : ""}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input.Search
                style={{
                  width: "20vw",
                  borderRadius: "10vw",
                  paddingLeft: "1vw",
                }}
                placeholder={"ค้นหาข้อมูล"}
                value={searchText}
                onKeyPress={(e) => this.onKeyPressSearch(e)}
                onSearch={(value) => this.onSearch()}
                onChange={(e) => this.onChange(e.target.value, "searchText")}
              />
              {/* <Button title={'ยื่นเรื่องเคลม'} type={'focus'} onPress={() => this.goCreate()} /> */}
              <BT
                type="primary"
                style={{
                  backgroundColor: color.dplus_primary,
                  borderColor: color.dplus_primary,
                }}
                onClick={this.goCreate}
              >
                ยื่นเรื่องเคลม
              </BT>
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <DatePicker.RangePicker
                //value={moment(new Date())}
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(this.state.start_date),
                  moment(this.state.end_date),
                ]}
                onChange={this.onChangeRangePicker}
                size="default"
              />

              {/* <Button title={'ยืนยัน'} type={'focus'} onPress={() => this.goCreate()} /> */}
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: 8 }}>
                {hasSelected ? `เลือก ${selectedRowKeys.length} รายการ` : ""}
              </span>

              <BT
                type="primary"
                style={{
                  backgroundColor: !hasSelected
                    ? color.dplus_disable
                    : color.dplus_primary,
                  borderColor: !hasSelected
                    ? color.dplus_disable
                    : color.dplus_primary,
                }}
                onClick={this.approveClaim}
                disabled={!hasSelected}
                loading={loading}
              >
                อนุมัติเรื่องเคลม
              </BT>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Input.Search
              style={{
                width: "20vw",
                borderRadius: "10vw",
                paddingLeft: "1vw",
              }}
              placeholder={"ค้นหาข้อมูลทั้งหมดโดยไม่อิงตามวันที่"}
              value={fullTextSearch}
              onKeyPress={(e) => this.onKeyPressSearch(e, "fullTextSearch")}
              onChange={(e) => this.onChange(e.target.value, "fullTextSearch")}
            />
          </div>
          <div className="text-subtitle">
            <font>
              {
                "(* ค้นหาได้เฉพาะ เลขที่เคลม , ชื่อนามสกุล, ชื่อไลน์, รหัสรับประกัน และเบอร์โทรศัพท์)"
              }
            </font>
          </div>

          {/*###################### TABLE ######################*/}
          <Table
            style={{ textAlign: "cenetr" }}
            title={() => <h2>การเคลมสินค้า</h2>}
           expandedRowRender={(record) => this.renderExpand(record)}
            rowSelection={rowSelection}
            columns={columnTableClaimFocus(
              (record) => this.goDetail(record),
              (record, type) => this.insertEMS(record, type),
              (record) => this.onModalMessage(record),
              //(record) => this.onModalDetail(record),
              (record) => this.onModalNote(record),
              (record) => this.onModalStatusInternal(record),
              expanded
            )}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            bordered
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
            pagination={{
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ถึง ${total} รายการ`,
              pageSize: 10,
              total: data.length || 0,
              pageSizeOptions: [10, 25, 50, 100, 500],
              defaultPageSize: 10,
              defaultCurrent: 1,
            }}
          />
        </div>
      </div>
    );
  }
  renderExpand(record) {


   
   
    return (
      <div style={{ padding: "1vw", display: "flex", alignItems: "center" }}>
        <div>
          <img
            src={`${PATH_IMAGE}${record.picclaim}`}
            style={{ width: 100, height: 100 }}
            onClick={() => this.clickImage(record.picclaim)}
            className={"contain"}
          />
        </div>

        <div style={{ marginLeft: "1vw" }}>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>ชื่อสินค้า</p>
            <p>{record.nameproduct}</p>
          </div>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>รุ่นมือถือ</p>
            <p>
              {record.brandmobile}` `{record.modelmobile}
            </p>
          </div>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>อาการเสีย</p>
            <p>{record.detail}</p>
          </div>
        </div>
        <div style={{ marginLeft: "1vw" }}>
          {record.date_pay && (
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>วันที่ชำระ</p>

              <p>{record.date_pay}</p>
            </div>
          )}
          {record.time_pay && (
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>เวลาชำระ</p>
              <p>{record.time_pay}</p>
            </div>
          )}
          {record.money_pay && (
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>ยอดเงินที่ชำระ</p>

              <p>{record.money_pay}</p>
            </div>
          )}
        </div>
      </div>
    );

  }
}

export default ClaimScene;
