import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu, Icon, Avatar } from "antd";
import { menu, color, image } from "../resource";
import user from "../mobx/user";
import { Observer } from "mobx-react";
import { Label } from "../styles/styled";
import { version } from "../service";
const { Header, Content, Sider } = Layout;

const styles = {
  defaultHeader: {
    background: color.rizz_secondary,
    padding: "0px 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default withRouter((props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [renderMenu, setRenderMenu] = useState([]);
  useEffect(() => {

    if (!user.role && !user.auth) {
      return props.history.replace("/login");
    }

    const activeMenu = menu.filter(e => e.show)
    setRenderMenu(activeMenu)

    const findPath = menu.findIndex((el) =>
      props.location.pathname.includes(el.path)
    );

    if (findPath === -1) return props.history.replace("/");
  }, [props.location.pathname]);

  function onClick(path) {
    props.history.push(path);
  }

  function onLogout() {
    user.logout();
    props.history.push("/login");
  }

    require("../styles/focus_bar.css");
    return (
      <Observer>
        {() =>
          user.role && (
            <Layout style={{ minHeight: "100vh" }}>
              <Layout>
                <Sider
                  breakpoint="lg"
                  collapsible
                  collapsed={collapsed}
                  onCollapse={(collapsed) => setCollapsed(collapsed)}
                >
                  <img
                    src={image.logo_brand}
                    style={{ width: "100%" }}
                    alt={""}
                  />
                  <Menu
                    theme="dark"
                    mode="inline"
                    inlineIndent={15} 
                    defaultSelectedKeys={[
                      (
                        renderMenu.findIndex((el) =>
                          props.location.pathname.includes(el.path)
                        ) + 1
                      ).toString(),
                    ]}
                  >
                    {renderMenu.map((el, index) => {
                  
                      if(!!el.subMenu){
                        return <Menu.SubMenu 
                                key={index}
                                title={<> <Icon type={el.icon} />{el.name}</>}
                                >   
                                {el.subMenu.map((sub, subIndex) => 
                                      <Menu.Item
                                      style={{ paddingLeft: '0px !important' }}
                                      key={`$${index}_${subIndex}`}
                                      onClick={() => onClick(sub.path)}
                                    >
                                      <Icon type={sub.icon} />
                                      <span className="nav-text">{sub.name}</span>
                                    </Menu.Item>
                            )}
                       
                        </Menu.SubMenu>

                      }else{
                      return (
                            <Menu.Item
                              key={index}
                              onClick={() => onClick(el.path)}
                            >
                              <Icon type={el.icon} />
                              <span className="nav-text">{el.name}</span>
                            </Menu.Item>
                      

                        )
                      }
                    })}

                    <Menu.Item
                      onClick={(_) => onLogout()}
                    >
                      <Icon type={"logout"} />
                      <span className="nav-text">
                        ออกจากระบบ
                        <br />
                      </span>
                    </Menu.Item>
                      <p style={{
                        paddingTop: '25px',
                        paddingLeft: '35px'
                      }}>
                        {version}
                      </p>
                  </Menu>
                </Sider>

                <Layout>
                  <Header style={styles.defaultHeader}>
                    <Label style={{ color: "#fff" }}>
                      <Icon type={"user"} /> {user.name} [{user.role}]
                    </Label>
                    <div />
                    #b1ec9c
                    <Avatar
                      style={{
                        color: "#fff",
                        backgroundColor: "#fff",
                        transform: "translateX(-50%)",
                      }}
                      size={"large"}
                    >
                      <img src={image.fa_ablemen} width="20" />
                    </Avatar>
                  </Header>
                  <Content
                    style={{
                      margin: "24px 16px",
                      background: "#fff",
                      minHeight: 280,
                    }}
                  >
                    {props.children}
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          )
        }
      </Observer>
    );
  
});
