import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button as BT, Table, Input, Modal, Row, Col } from "antd";
import { FocuscolumnTableHistory, color } from "../../resource";
import autobind from "autobind-decorator";
import { GET, GET_HISTORY, PATH_IMAGE } from "../../service";
import dayjs from "dayjs";
const fn = require("../../functions/functions");
let defaultData = [];

@observer
class HistoryScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingData: false,
      searchText: "",
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,
    };
  }

  componentDidMount() {

  }

  @autobind
  async onFetchHistory() {
    const { user_role, search } = this.state;
    try {
      this.setState({ isLoadingData: true})
      const res = await GET(`${GET_HISTORY}?role=${user_role}&search=${search || ''}`);
      let data = [];
      if (res.success) {
        data = res.result.map((el, i) => {
          const { data, customer } = el;
          return {
            no: i + 1,
            ...customer,
            telephone: customer.tel,
            last_date: dayjs(customer.dateclaim)
              .add(543, "y")
              .format("DD/MM/YYYY"),
            data,
          };
        });
      }
      defaultData = data;

      this.setState({ data });
      
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("history => onFetchHistory", error.message);
    }finally{
      this.setState({ isLoadingData: false})
    }
  }

  @autobind
  onChange(e, ref) {
    this.setState({ [ref]: e });
  }

  @autobind
  onKeyPressSearch(event) {
    if (event.key === "Enter") {
      this.onSearch();
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }

  render() {
    const { data, isLoadingData, searchText } = this.state;

    return (
      <div>
        <div className={"column"}>
          {/*###################### HEAD ######################*/}
          {/* <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input.Search
                style={{
                  width: "20vw",
                  borderRadius: "10vw",
                  paddingLeft: "1vw",
                }}
                placeholder={"ค้นหาข้อมูล"}
                value={searchText}
                onKeyPress={(e) => this.onKeyPressSearch(e)}
                onSearch={() => this.onSearch()}
                onChange={(e) => this.onChange(e.target.value, "searchText")}
              />
            </div>
          </div> */}


          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}> 
              <Input.Search
                  placeholder="ค้นหาข้อมูลลูกค้า" 
                  name="search" 
                  onChange={e => this.setState({ search: e.target.value })}
                  onPressEnter={()=>{this.onFetchHistory()}}
                  onSearch={() => this.onFetchHistory()}
                  // onKeyPress={()=>{this.onGetAll()}}
                 
                  />  
            </Col>
            <Col span={1}><BT 
                  onClick={()=>{this.onFetchHistory()}}
                  type="primary"
                  style={{
                    backgroundColor: color.dplus_primary,
                    borderColor: color.dplus_primary,
                  }}
                  >ค้นหา</BT></Col>
          </Row>
 


          {/*###################### TABLE ######################*/}
          <Table
            title={() => <h2>ประวัติการเคลม</h2>}
            // rowSelection={rowSelection}
            columns={FocuscolumnTableHistory}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            expandedRowRender={(record) => this.renderExpand(record)}
            bordered
            pagination={{ pageSize: 15 }}
            loading={isLoadingData}
            size="small"
          />
        </div>
      </div>
    );
  }

  renderExpand(record) {
    return record.data.map((el, i) => {
      return (
        <div
          style={{ padding: "1vw", display: "flex", alignItems: "center" }}
          key={`${el}${i}`}
        >
          <img
            src={`${PATH_IMAGE}${el.picclaim}`}
            style={{ width: 100, height: 100 }}
            className={"contain"}
          />
          <div style={{ marginLeft: "1vw" }}>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>เลขที่การเคลม</p>
              <p>{el.no_claim}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>สินค้า</p>
              <p>{el.nameproduct}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>วันที่แจ้งเคลม</p>
              <p>
                {dayjs(el.dateclaim)
                  .add(543, "y")
                  .format("DD/MM/YYYY")}
              </p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>อาการเสีย</p>
              <p>{el.detail}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>เลขพัสดุ</p>
              <p>{el.tracking}</p>
            </div>
            <div style={{ display: "flex", marginBottom: "-12px" }}>
              <p style={{ margin: 0, width: "100px" }}>ประเภทการเคลม</p>
              <p>{el.type_store}</p>
            </div>
          </div>
        </div>
      );
    });
  }
}

export default HistoryScene;
