import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Icon,
  Upload,
  Input,
  Row,
  Col,
  Button as BT,
  DatePicker,
  Select,
  Modal,
  Spin,
  Form,
  message,
  Radio,
} from "antd";
import autobind from "autobind-decorator";
import { PlusOutlined } from "@ant-design/icons";
import {
  GET,
  UPLOAD,
  GET_WARRANTY_BY_TEL,
  INSERT_CLAIM,
  GET_OPTION_BREAKDOWN,
  GET_ADDRESS_BY_ZIPCODE,
} from "../../service";
import moment from "moment";
import { BtnGoback } from "../../components/Button";
import { color } from "../../resource";

const fn = require("../../functions/functions");

@Form.create({ name: "CreateClaimScene" })
@observer
class CreateClaimScene extends Component {
  constructor(props) {
    super(props);
    this.props.form.setFieldsValue({
      line_id: "",
      name: "",
      telephone: "",
      other: "",
    });

    this.state = {
      claimdate: moment(new Date()),
      loading: false,
      product_id: "",
      register_date: moment(new Date()),
      imageUrl: "",
      dataSource: [],
      telephone_colect: "",
      data: [],
      status: 0,
      note: "",
      _address: [],
      radio: 1,
      detail_claim: [],
      amphoe_option: [],
      district_option: [],
      province_option: [],
      dataAddress: [],
    };
  }

  getBreakdown = async (product_type) => {
    try {
      const res = await GET(GET_OPTION_BREAKDOWN(product_type));
      const { success, result } = res;

      if (success) {
        const option = result.map((el) => {
          return { id: el.breakdown, detail: el.breakdown };
        });
        this.setState({ detail_claim: option });
      }
    } catch (error) {
      console.log(error);
    }
  };

  @autobind
  async getDataByTel() {
    const { telephone } = this.state;
    try {
      const res = await GET(GET_WARRANTY_BY_TEL(telephone));

      if (res.success) {
        if (res.result.length !== 0) {
          const { customer, address, warranty } = res.result;

          this.setState({
            name: customer.name,
            user_id: customer.user_id,
            data: warranty,
            _address: address,
            full_address: "",
            telephone_colect: "check-circle",
            telephone_colect_color: "certain-category-icon-colect",
            loading: false,
          });
          this.props.form.setFieldsValue({
            _address: address,
            data: warranty,
            name: customer.name,
            telephone: customer.telephone,
            full_address: "",
          });
        } else {
          this.setState({
            telephone_colect: "close-circle",
            telephone_colect_color: "certain-category-icon-uncolect",
            loading: false,
          });
        }
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("createClaim => getDataByTel", error.message);
    }
  }

  @autobind
  async registerClaim() {
    let type = "COMPANY";
    let channal = "Web";

    const {
      user_id,
      no_warranty,
      file,
      province,
      district,
      amphoe,
      zipcode,
      other,
      other_description,
      detail_id,
      name,
    } = this.state;
    let description =
      detail_id === 2 ? other_description : this.state.description;
    let arr = {
      no_warranty,
      file,
      province,
      district,
      amphoe,
      zipcode,
      other,
      description,
      type,
      channal,
      other_description,
      user_id,
      name,
    };

    let formdata = fn.append_focus_createclaim(arr);
    this.setState({
      loading: true,
    });

    try {
      let res = await UPLOAD(INSERT_CLAIM, formdata);

      if (res.success) {
        this.setState({
          loading: false,
        });
        this.clear();
        Modal.success({
          title: res.message,
          content: `ชุดเอกสารลงทะเบียนเคลม ${res.result.no_claim}`,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("createClaim => registerClaim", error.message);
    }
  }
  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  handleChange = (info) => {
    if (info.file.status) {
      const isJpgOrPng =
        info.file.type === "image/jpeg" || info.file.type === "image/png";
      if (!isJpgOrPng) {
        Modal.warning({ title: "ไฟล์รูปต้องเป็น JPG/PNG เท่านั้น!" });
        return;
      }

      fn.getBase64(info.file.originFileObj, (url) => {
        this.setState({ imageUrl: url });
      });

      this.setState({
        file: info.file.originFileObj,
        loading: false,
      });
    }
  };

  getAddressByZipCode = async (zipcode, ref) => {
    try {
      const res = await GET(GET_ADDRESS_BY_ZIPCODE(zipcode));
      const { success, result } = res;
      if (success) {
        let amphoe = [];
        let district = [];
        let province = "";
        let province_arr = [];
        result.forEach((el) => {
          amphoe.push({ label: el.amphoe, value: el.amphoe });
          if (amphoe[0].value === el.amphoe) {
            district.push({ label: el.district, value: el.district });
          }

          province_arr.push({ label: el.province, value: el.province });
          //province = el.province;
        });

        // if (ref !== "default") {
        //   this.setState({
        //     province,
        //     // amphoe: amphoe[0].value,
        //     // district: district[0].value,
        //   });

        //   this.props.form.setFieldsValue({
        //     province,
        //     // amphoe: amphoe[0].value,
        //     // district: district[0].value,
        //   });
        // }

        this.setState({
          amphoe_option: fn.removeDuplicates(amphoe),
          district_option: fn.removeDuplicates(district),
          province_option: fn.removeDuplicates(province_arr),
          dataAddress: result,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor(
        "createClaim => getAddressByZipCode",
        error.message
      );
    }
  };

  @autobind
  onChange(e, ref) {
    const { dataAddress } = this.state;
    this.setState({ [ref]: e });
    this.props.form.setFieldsValue({ [ref]: e });

    switch (ref) {
      case "telephone":
        this.setState({
          telephone_colect: "",
          name: "",
          product_name: "",
        });
        this.props.form.setFieldsValue({
          product_name: "",
          name: "",
        });
        break;
      case "name":
        this.setState({ name: e });
        break;
      case "zipcode":
        if (e.length === 5) {
          this.getAddressByZipCode(e, "onchange");
        }
        break;
      case "province":
        const amphoe = [];
        dataAddress.forEach((el) => {
          if (el.province === e) {
            amphoe.push({ label: el.amphoe, value: el.amphoe });
          }
        });
        this.props.form.setFieldsValue({
          amphoe: null,
        });
        this.setState({ amphoe_option: fn.removeDuplicates(amphoe), amphoe: null });
        break;
      case "amphoe":
        const district = [];
        dataAddress.forEach((el) => {
          if (el.amphoe === e) {
            district.push({ label: el.district, value: el.district });
          }
        });
        this.props.form.setFieldsValue({
          district: "",
        });
        this.setState({ district_option: district, district: "" });
        break;
    }
  }

  onChangeSelect = (value) => {
    const product = this.state.data.find((el) => el.no_warranty === value);

    this.setState(
      {
        product_name: product.nameproduct,
        no_warranty: product.no_warranty,
      },
      () => this.getBreakdown(product.product_type)
    );
  };
  onChangeClaimDetail = (value) => {
    const detail = this.state.detail_claim.find((el) => el.id === value);
    if (value !== 2) {
      this.setState({
        description: detail.detail,
        detail_id: detail.id,
      });
    } else {
      this.setState({
        detail_id: detail.id,
      });
    }
  };
  onChangeAdd = (value) => {
    const address = this.state._address.find((el) => el.address_id === value);
    const { other, district, province, zipcode, amphoe, address_id } = address;
    this.setState({
      other,
      amphoe,
      district,
      province,
      zipcode,
      addressId: address_id,
    });
    this.props.form.setFieldsValue({
      other,
      amphoe,
      district,
      province,
      zipcode,
    });
  };
  onSearchProduct = (val) => {
    this.setState({
      other: val,
    });
    this.props.form.setFieldsValue({ other: val });
  };
  @autobind
  onKeyPressSearch(event) {
    if (event.key === "Enter") {
      this.getDataByTel();
    }
  }

  onSelectAddress = (fullAddress) => {
    const { amphoe, district, province, zipcode } = fullAddress;
    this.props.form.setFieldsValue({
      district,
      amphoe,
      province,
      zipcode,
    });
    this.setState({
      amphoe,
      district,
      zipcode,
      province,
    });
  };

  @autobind
  clear() {
    this.props.form.setFieldsValue({
      line_id: "",
      product_name: "",
      claimdate: moment(new Date()),
      imageUrl: "",
      description: "",
      name: "",
      telephone: "",
      telephone_colect: "",
      telephone_colect_color: "",
      district: "",
      amphoe: "",
      province: "",
      zipcode: "",
      other: "",
      full_address: "",
      _address: [],
    });
    this.setState({
      line_id: "",
      product_name: "",
      claimdate: moment(new Date()),
      imageUrl: "",
      description: "",
      name: "",
      telephone: "",
      telephone_colect: "",
      telephone_colect_color: "",
      district: "",
      amphoe: "",
      province: "",
      zipcode: "",
      other: "",
      full_address: "",
      _address: [],
    });
  }

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // Call API
        if (!fn.phonNumberValidate(this.state.telephone)) {
          message.error("เบอร์โทรของท่านไม่ถูกต้อง");
        } else {
          this.registerClaim();
        }
      } else {
        console.log("error");
      }
    });
  }
  onChangeRadio = (e) => {
    if (e.target.value === 2) {
      this.setState({
        addressId: "",
      });
    }
    this.setState({
      radio: e.target.value,
      district: "",
      amphoe: "",
      province: "",
      zipcode: "",
      other: "",
    });
    this.props.form.setFieldsValue({
      other: "",
      amphoe: "",
      district: "",
      province: "",
      zipcode: "",
    });
  };

  @autobind
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const {
      radio,
      _address,
      full_address,
      zipcode,
      district,
      amphoe,
      province,
      loading,
      data,
      other,
      claimdate,
      product_name,
      imageUrl,
      description,
      name,
      telephone,
      telephone_colect,
      telephone_colect_color,
      detail_claim,
      detail_id,
      other_description,
      amphoe_option,
      district_option,
      province_option,
    } = this.state;

    // const uploadButton = (
    //   <div>
    //     <Icon
    //       style={{ width: "6cm", height: "7cm" }}
    //       type={this.state.loading ? "loading" : "plus"}
    //     />
    //     <div className="ant-upload-text">Upload</div>
    //   </div>
    // );

    if (loading) {
      return (
        <div className="example">
          <Spin />
        </div>
      );
    }

    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        onSubmit={this.handleSubmit}
      >
        <div>
          <BtnGoback title={"กลับ"} onClick={() => this.goBack()} />
          <Row>
            <Col span={10} push={14}>
              <div className={"border-claim"} style={{ margin: 15 }}>
                <div className={"column-claim-cr"}>
                  <font style={{ fontSize: "0.4cm" }}>ข้อมูลการเคลม</font>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="เลือกสินค้าที่ต้องการเคลม">
                    {getFieldDecorator("product_name", {
                      rules: [
                        {
                          required: true,
                          message: "เลือกสินค้าที่ต้องการเคลม",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เลือกสินค้าที่ต้องการเคลม"
                        optionFilterProp="children"
                        onChange={this.onChangeSelect}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={product_name}
                      >
                        {data.map((item) => (
                          <Select.Option value={item.no_warranty}>
                            <div>
                              {item.brandmobile}
                              {item.modelmobile}
                            </div>
                            {item.nameproduct}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -30 }}>
                  <Form.Item label="อาการเสีย">
                    {getFieldDecorator("description", {
                      rules: [
                        { required: true, message: "กรุณาระบุอาการเสีย" },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="กรุณาระบุอาการเสีย"
                        optionFilterProp="children"
                        onChange={this.onChangeClaimDetail}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={description}
                      >
                        {detail_claim.map((item) => (
                          <Select.Option value={item.id}>
                            {item.detail}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -30 }}>
                  {detail_id === "อื่นๆ" ? (
                    <Form.Item label="อาการเสีย">
                      {getFieldDecorator("other_description", {
                        rules: [
                          { required: true, message: "กรุณาระบุอาการเสีย" },
                        ],
                      })(
                        <Input
                          style={{ width: "95%" }}
                          defaultValue={other_description}
                          value={other_description}
                          onChange={(e) =>
                            this.onChange(e.target.value, "other_description")
                          }
                        />
                      )}
                    </Form.Item>
                  ) : (
                    <div />
                  )}
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -15 }}>
                  <font style={{ fontSize: "0.4cm" }}>วันที่แจ้งเคลม</font>

                  <DatePicker
                    style={{ width: "100%", marginTop: 7 }}
                    placeholder={"กรุณาเลือกวันที่แจ้งเคลม"}
                    value={claimdate}
                    format={"DD/MM/YYYY"}
                    onChange={(date) => this.onChange(date, "claimdate")}
                  />
                </div>
                <div className={"column-claim-cr"} />
                <div
                  className={"column-claim-cr"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: -40,
                  }}
                >
                  <Form.Item label="รูปภาพสินค้า">
                    {getFieldDecorator("imageUrl", {
                      rules: [{ required: true, message: "รูปภาพสินค้า" }],
                    })(
                      <Upload
                        name="avatar"
                        // listType="picture-card"
                        // className="avatar-uploader"
                        // showUploadList={false}
                        // beforeUpload={this.beforeUpload}
                        // onChange={this.handleChange}
                        required
                        fileList={[]}
                        listType="picture-card"
                        accept={"image/jpeg,image/png"}
                        customRequest={this.dummyRequest}
                        onChange={this.handleChange}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                              width: "200px",
                              height: "200px",
                            }}
                          />
                        ) : (
                          <>
                            <PlusOutlined />
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </>
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </div>
              </div>
            </Col>

            <Col span={14} pull={10}>
              <div className={"border-claim"} style={{ margin: 15 }}>
                <div className={"column-claim-cr"}>
                  <font style={{ fontSize: "0.4cm" }}>ยื่นเรื่องเคลม</font>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: -22,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"} style={{ marginTop: 5 }}>
                      <Form.Item label="เบอร์โทรศัพท์">
                        {getFieldDecorator("telephone", {
                          rules: [
                            { required: false, message: "กรุณาระบุเบอร์โทร" },
                          ],
                        })(
                          <Input
                            suffix={
                              <Icon
                                type={telephone_colect}
                                style={{ fontSize: "16px" }}
                                className={telephone_colect_color}
                              />
                            }
                            style={{ width: "100%" }}
                            defaultValue={telephone}
                            value={telephone}
                            autoFocus
                            onKeyPress={(e) => this.onKeyPressSearch(e)}
                            onChange={(e) =>
                              this.onChange(e.target.value, "telephone")
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"} style={{ marginTop: 5 }}>
                      <Form.Item label="ชื่อ-นามสกุล">
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: false,
                              message: "กรุณาใส่ ชื่อ-นามสกุล",
                            },
                          ],
                        })(
                          <Input
                            style={{ width: "95%" }}
                            defaultValue={name}
                            value={name}
                            // readonly="readonly"
                            onChange={(e) =>
                              this.onChange(e.target.value, "name")
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: -50,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div
                      className={"column-claim-cr"}
                      style={{ marginTop: 50 }}
                    >
                      {telephone_colect === "check-circle" ? (
                        <Radio.Group
                          onChange={this.onChangeRadio}
                          value={radio}
                        >
                          <Radio value={1}>ที่อยู่เดิม</Radio>
                          {_address.length < 3 ? (
                            <Radio value={2}>เพิ่มที่อยู่ใหม่</Radio>
                          ) : (
                            <div />
                          )}
                        </Radio.Group>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                </div>

                <div className={"column-claim-cr"}>
                  {radio === 1 || _address.length >= 3 ? (
                    <Form.Item label="เลือกที่อยู่">
                      {getFieldDecorator("full_address", {
                        rules: [
                          { required: true, message: "กรุณากรอกที่อยู่" },
                        ],
                      })(
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="เลือกที่อยู่"
                          optionFilterProp="children"
                          onChange={this.onChangeAdd}
                          onSearch={this.onSearchProduct}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={full_address}
                        >
                          {_address.map((address) => (
                            <Select.Option value={address.address_id}>
                              {address.address_name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  ) : (
                    <div />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-30px",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="รหัสไปรษณีย์">
                        {getFieldDecorator("zipcode", {
                          rules: [
                            {
                              required: true,
                              message: "กรุณาเลือกรหัสไปรษณีย์",
                            },
                          ],
                        })(
                          <Input
                            style={{ marginRight: 10, width: "95%" }}
                            value={zipcode}
                            placeholder={"รหัสไปรษณีย์"}
                            onChange={(e) =>
                              this.onChange(e.target.value, "zipcode")
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="จังหวัด">
                        {getFieldDecorator("province", {
                          rules: [
                            { required: true, message: "กรุณาเลือกจังหวัด" },
                          ],
                        })(
                          // <Input
                          //   style={{ marginRight: 10, width: "100%" }}
                          //   value={province}
                          //   placeholder={"จังหวัด"}
                          //   disabled
                          // />
                          <Select
                            style={{ width: "100%", marginRight: "10px" }}
                            placeholder="จังหวัด"
                            value={province}
                            onChange={(e) => this.onChange(e, "province")}
                          >
                            {province_option.map((item) => (
                              <Select.Option value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-50px",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="เขต/อำเภอ">
                        {getFieldDecorator("amphoe", {
                          rules: [
                            { required: true, message: "กรุณาเลือกเขต/อำเภอ" },
                          ],
                        })(
                          <Select
                            style={{ width: "100%", marginRight: "10px" }}
                            placeholder="เขต/อำเภอ"
                            value={amphoe}
                            onChange={(e) => this.onChange(e, "amphoe")}
                          >
                            {amphoe_option.map((item) => (
                              <Select.Option value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="แขวง/ตำบล">
                        {getFieldDecorator("district", {
                          rules: [
                            { required: true, message: "กรุณาแขวง/ตำบล" },
                          ],
                        })(
                          <Select
                            style={{ width: "100%", marginRight: "10px" }}
                            placeholder="เลือกแขวง/ตำบล"
                            value={district}
                            onChange={(e) => this.onChange(e, "district")}
                          >
                            {district_option.map((item) => (
                              <Select.Option value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                ></div>
                <div className={"column-claim-cr"} style={{ marginTop: -30 }}>
                  <Form.Item label="เพิ่มเติม">
                    {getFieldDecorator("other", {
                      rules: [
                        {
                          required: true,
                          message: "กรุณาระบุที่อยู่บ้านเลขที่ ถนนที่ตั้ง",
                        },
                      ],
                    })(
                      <Input
                        style={{ width: "100%" }}
                        defaultValue={other}
                        value={other}
                        onChange={(e) => this.onChange(e.target.value, "other")}
                      />
                    )}
                  </Form.Item>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: -15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 20,
                      marginLeft: 30,
                      justifyContent: "flex-end",
                    }}
                  >
                    <BT
                      style={{
                        width: "10vw",
                        height: "20vw",
                        background: "#FFFFFF",
                        color: "Black",
                      }}
                      onClick={() => this.clear()}
                      hidden={!telephone}
                    >
                      เคลียร์ข้อมูล
                    </BT>
                    <BT
                      htmlType="submit"
                      style={{
                        width: "10vw",
                        height: "20vw",
                        background: color.dplus_main,
                        color: "#FFFFFF",
                      }}
                    >
                      ยื่นเรื่อง
                    </BT>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    );
  }
}

export default CreateClaimScene;
