import React, { useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import App from "./App";
import { observer, Observer } from "mobx-react";
import user from "../mobx/user";
import { menu } from "../resource";
import ReactGA from "react-ga";

export default withRouter(
  observer((props) => {
    useEffect(() => {
      ReactGA.initialize("UA-215094217-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
      // if (user.role) {
      //   document.title = menu[user.role].title;
      // } else {
        document.title = "ระบบจัดการหลังบ้าน";
      // }
    });

    if (
      props.location.pathname === "/login" ||
      props.location.pathname === "/rizz/billshipping" ||
      props.location.pathname === "/billshipping"
    )
      return null;

    if (user.loading) {
      return <div />;
    }

    return (
      <App>
        <Observer>
          {() => (
            <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              {
                menu.map((el, index) => {
                  if(!!el.subMenu){ 
                   return el.subMenu.map((sub, subIndex) => 
                        <Route 
                          key={`${index}_${subIndex}`} 
                          path={`${sub.path}`} 
                          component={sub.component}  
                        />
                   )
                  }else{
                  return (
                      <Route
                        key={index}
                        path={`${el.path}`}
                        component={el.component}
                      />
                    );
                  }}
                )
              }

           
            </div>
          )}
        </Observer>
      </App>
    );
  })
);
