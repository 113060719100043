import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import {
  Icon,
  Input,
  Row,
  Col,
  Button as BT,
  DatePicker,
  Spin,
  Modal,
  Select,
  Upload
} from "antd";
import autobind from "autobind-decorator";
import { PlusOutlined } from '@ant-design/icons';
import {
  GET,
  POST,
  GET_OPTION_CHANNEL_BUY,
  GET_OPTION_WARRANTY_TYPE,
  GET_ALL_WARRANTY,
  GET_FIND_CUSTOMER,
  UPDATE_WARRANTY,
  VALIDATE_QRCODE,
  GET_PRODUCTS_BY_CATEGORY,
  ip,
} from "../../service";
import { color } from "../../resource";
import {
  CUSTOMER_WARRANTY_FORM,
  PRODUCTS_WARRANTY_FORM,
  PRODUCTS_WARRANTY_EC_FORM,
} from "../../resource/static";
const fn = require("../../functions/functions");

@observer
class EditWarrantyScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      loading: true,
      warranty_doc: query.get("id"),
      data: [],
      dataCustomer: [],
      error_hidden: true,
      line_error: "",
      error_color: "",
      lineid_colect: "",
      lineid_colect_color: "",
      category: "",
      brandDefault: "",
      item_code:"",
      user_id: "",
      line_id: "",
      product_type: "",
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,
        inv_image: "",
        inv_file: "",
        package_image: "",
        package_file: "",
    };
  }

  async componentDidMount() {
    await this.onFetchWarranty();
    await this.getChannelBuy();
    await this.getWarrantyType()
  }

  onFetchWarranty = async () => {
    const { warranty_doc, user_role } = this.state;
    let obj = {
      date_start: "",
      date_end: "",
      search: warranty_doc === "" ? "" : warranty_doc,
      role: user_role,
    };
    try {
      const res = await POST(GET_ALL_WARRANTY, obj);
      if (res.success) {
        const {
          qrcode,
          type_store,
          namestore,
          no_warranty,
          product_id,
          category,
          warranty_type,
          item_code
        } = res.result[0];

        Array.isArray(res.result) &&
          res.result.map((val) => {
            let products = [];
            if (category === "EC") {
              products = [
                { ...PRODUCTS_WARRANTY_EC_FORM[0], value: val.qrcode },
                {
                  ...PRODUCTS_WARRANTY_EC_FORM[1],
                  value: `${val.product_id}|${val.nameproduct}`,
                },
                { ...PRODUCTS_WARRANTY_EC_FORM[2], value: `${val.namestore}` },
                { ...PRODUCTS_WARRANTY_EC_FORM[3], value: `${val.warranty_type}` },
                { ...PRODUCTS_WARRANTY_EC_FORM[4], value: `${ip}${val.inv_image}` },
                { ...PRODUCTS_WARRANTY_EC_FORM[5], value: `${ip}${val.package_image}` },
              ];
            } else {
              products = [
                { ...PRODUCTS_WARRANTY_FORM[0], value: val.qrcode },
                {
                  ...PRODUCTS_WARRANTY_FORM[1],
                  value: `${val.product_id}|${val.nameproduct}`,
                },
                { ...PRODUCTS_WARRANTY_FORM[2], value: val.brandmobile },
                { ...PRODUCTS_WARRANTY_FORM[3], value: val.modelmobile },
                { ...PRODUCTS_WARRANTY_FORM[4], value: `${val.namestore}` },
                { ...PRODUCTS_WARRANTY_FORM[5], value: `${val.warranty_type}` },
                { ...PRODUCTS_WARRANTY_FORM[6], value: `${ip}${val.inv_image}` },
                { ...PRODUCTS_WARRANTY_FORM[7], value: `${ip}${val.package_image}` },
              ];
            }
        
            this.setState(
              {
                dataCustomer: [
                  { ...CUSTOMER_WARRANTY_FORM[0], value: val.tel },
                  { ...CUSTOMER_WARRANTY_FORM[1], value: val.name },
                ],
                item_code,
                brandDefault: val.brandmobile,
                user_id: val.user_id,
                dataProducts: products.map((el)=>{return {...el,nameproduct:`${el.product_id}|${el.nameproduct}`,
                modelmobile:`${el.item_code}|${el.modelmobile}`}}),
                loading: false,
                qrcode,
                type_store,
                namestore,
                no_warranty,
                product_id,
                warranty_type,
                dateexp: moment(res.result[0].dateexp).format("YYYY-MM-DD"),
                datestart: moment(res.result[0].datestart).format("YYYY-MM-DD"),
                category,
                inv_image: `${ip}${val.inv_image}`,
                package_image: `${ip}${val.package_image}`
              },
              () => this.checkWarranty()
            );
          });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor(
        "editWarranty => onFetchWarranty",
        error.message
      );
    }
  };

  getProductList = async (category) => {
    try {
      const res = await GET(GET_PRODUCTS_BY_CATEGORY(category));
      if (res.success) {
        const option = res.result.products.map((el) => {
          return (
            <Select.Option value={`${el.product_id}|${el.product_name}`}>
              {el.product_name}
            </Select.Option>
          );
        });
        this.setState({
          optionProducts: option,
          data_products: res.result,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor(
        "editWarranty => getProductList",
        error.message
      );
    }
  };

  onChange = (value, fieldName) => {
    const { dataCustomer, dataProducts, dataModelByBrand } = this.state;
    if (fieldName === "tel" || fieldName === "fullname") {
      Array.isArray(dataCustomer) &&
        dataCustomer.forEach((val) => {
          if (val.fieldName === fieldName) {
            val.value = value;
          }
        });
    }else {
      Array.isArray(dataProducts) &&
        dataProducts.forEach((val) => {
          if (fieldName === "nameproduct") {
            this.setState({
              product_id: value.split("|")[0],
              product_name: value.split("|")[1],
            });
          } else if (fieldName === "brandmobile") {
            const dataFilter = dataModelByBrand.filter(
              (item) => item.brand_name === value
            );

            const option = dataFilter[0].model.map((el) => {
              return (
                <Select.Option value={`${el.item_code}|${el.model_name}`}>
                  {el.model_name}
                </Select.Option>
              );
            });

            this.setState({ optionModels: option });
            if (val.fieldName === "modelmobile") {
              val.value = "";
            }
          }else if(fieldName === "modelmobile"){

            console.log({value})
            this.setState({
              item_code : value.split("|")[0] || "",
            });
          }

          if (val.fieldName === fieldName) {
            val.value = value;
          }
        });
    }

    this.setState({ dataCustomer, dataProducts });
  };

  @autobind
  async getTel(tel) {
    const { dataCustomer } = this.state;
    const obj = { telephone: tel };
    try {
      const res = await POST(GET_FIND_CUSTOMER, obj);
      const { success, result } = res;
      if (success) {
        Array.isArray(dataCustomer) &&
          dataCustomer.map((val) => {
            if (val.fieldName === "fullname") {
              this.setState({
                loading: false,
                data_telephone: result.telephone,
                line_id: result.line_id,
                lineid_colect: result.has_customer
                  ? "check-circle"
                  : "close-circle",
                lineid_colect_color: result.has_customer
                  ? "certain-category-icon-colect"
                  : "certain-category-icon-uncolect",
                error_hidden: false,
                error_color: result.has_customer ? "green" : "red",
                line_error: result.has_customer
                  ? "พบเบอร์โทรในระบบ"
                  : "ไม่พบเบอร์โทรในระบบ",
                user_id: result.has_customer ? result.user_id : "",
              });
              val.value = result.has_customer ? result.name : "";
            } else if (val.fieldName === "tel") {
              val.value = tel;
            }
          });

        this.setState({
          loading: false,
          data_telephone: result.telephone,
          line_id: result.line_id,
          lineid_colect: result.has_customer ? "check-circle" : "close-circle",
          lineid_colect_color: result.has_customer
            ? "certain-category-icon-colect"
            : "certain-category-icon-uncolect",
          error_hidden: false,
          error_color: result.has_customer ? "green" : "red",
          line_error: result.has_customer
            ? "พบเบอร์โทรในระบบ"
            : "ไม่พบเบอร์โทรในระบบ",
          name: result.has_customer ? result.name : "",
          user_id: result.has_customer ? result.user_id : "",
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("editWarranty => getTel", error.message);
    }
  }

  onKeyPressSearch = (e, type) => {
    const tel = e.target.value;
    if (e.key === "Enter") {
      this.getTel(tel);
    }
  };

  @autobind
  async checkWarranty() {
    const { qrcode, channel_buy, brandDefault } = this.state;
    if (qrcode) {
      try {
        let res = await GET(VALIDATE_QRCODE(qrcode));
        const { success } = res;
        const { brand, category, product_type } = res.result;
        if (success) {
          if (category !== "EC") {
            if (brandDefault) {
              const dataFilter = brand.filter(
                (item) => item.brand_name === brandDefault
              );

              const optionModel = dataFilter[0].model.map((el) => {
                return (
                  <Select.Option value={`${el.item_code}|${el.model_name}`}>
                    {el.model_name}
                  </Select.Option>
                );
              });

              this.setState({
                optionModels: optionModel,
              });
            }

            const optionBrand = brand.map((el) => {
              return (
                <Select.Option value={`${el.brand_name}`}>
                  {el.brand_name}
                </Select.Option>
              );
            });

            this.setState({
              product_type: product_type,
              dataModelByBrand: brand,
              optionBrands: optionBrand,
            });
          }

          this.setState(
            {
              channel_buy: channel_buy,
              qrcode,
              category: category,
            },

            () => this.getProductList(category),
            this.getChannelBuy()
          );
        }
      } catch (error) {
        Modal.error({ title: error.message });
        return fn.larkNotiMonitor(
          "editWarranty => checkWarranty",
          error.message
        );
      }
    }
  }

  getChannelBuy = async () => {
    try {
      const res = await GET(GET_OPTION_CHANNEL_BUY);

      if (res.success) {
        const optionChanelBuy = res.result.map((el) => {
          return (
            <Select.Option value={`${el.channel_buy_name}`}>
              {el.channel_buy_name}
            </Select.Option>
          );
        });
        this.setState({ optionChanelBuy });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("editWarranty => getChannelBuy", error.message);
    }
  };

  getWarrantyType = async () => {
    try {
      const res = await GET(GET_OPTION_WARRANTY_TYPE);

      if (res.success) {
        const optionWarrantyType = res.result.option.map((el) => {
          return (
            <Select.Option value={`${el.value}`}>
              {el.label}
            </Select.Option>
          );
        });
        this.setState({ optionWarrantyType });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("editWarranty => getChannelBuy", error.message);
    }
  };

  handleSubmit = () => {
    const {
      dataCustomer,
      dataProducts,
      no_warranty,
      product_id,
      product_name,
      dateexp,
      datestart,
      product_type,
      
    } = this.state;
    const dataCus = {};
    const dataProd = {};

    if (Array.isArray(dataCustomer) && Array.isArray(dataProducts)) {
      dataCustomer.forEach((el) => {
        dataCus[el.fieldName] = el.value;
      });
      dataProducts.forEach((el) => {
        dataProd[el.fieldName] = el.value;
      });
    }
    const data_form = [{ ...dataCus, ...dataProd }];
    data_form.forEach((el) => {
      const { brandmobile, modelmobile, fullname, tel, namestore, warranty_type } = el;

      if (
        product_type === "FILM" &&
        (!brandmobile || !modelmobile || !fullname || !tel || !namestore)
      ) {
        Modal.error({ title: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      } else if (product_type === "ACC" && (!fullname || !tel || !namestore)) {
        Modal.error({ title: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      } else {
        this.setState(
          {
            no_warranty,
            tel,
            name: fullname,
            product_id,
            dateexp,
            datestart,
            nameproduct: product_name,
            brandmobile,
            modelmobile,
            namestore,
            warranty_type,
            type_store: "COMPANY",
          },
          () => this.sendForm()
        );
      }
    });
  };

  sendForm = async () => {
    try {

      this.setState({ loading: true })

      const {
        no_warranty,
        name,
        product_id,
        dateexp,
        nameproduct,
        brandmobile,
        modelmobile,
        namestore,
        type_store,
        tel,
        user_id,
        warranty_type,
        inv_file,
        package_file,
        item_code
      } = this.state;

      let obj = {
        no_warranty,
        name,
        product_id,
        dateexp,
        nameproduct,
        brandmobile,
        modelmobile,
        namestore,
        type_store,
        tel,
        user_id,
        warranty_type,
        inv_file,
        package_file,
        item_code
      };

      console.log({obj})

      let data = new FormData()
      data.append("no_warranty", no_warranty)
      data.append("name", name)
      data.append("product_id", product_id)
      data.append("dateexp", dateexp)
      data.append("nameproduct", nameproduct)
      data.append("brandmobile", brandmobile?brandmobile : '')
      data.append("item_code", item_code?item_code : '')
      data.append("modelmobile", modelmobile?modelmobile.split("|")[1] : '')
      data.append("namestore", namestore)
      data.append("type_store", type_store)
      data.append("tel", tel)
      data.append("user_id", user_id)
      data.append("warranty_type", warranty_type)
      console.log({inv_file});
      if(!!inv_file){
        data.append("inv_image", inv_file)
      }
      
      if(!!package_file){
        data.append("package_image", package_file)
      }
     

      let res = await POST(UPDATE_WARRANTY, data);
      this.setState({ loading: false })
      if (res.success) {
        Modal.success({
          title: "บันทึกข้อมูลสำเร็จ",
        });
      }
    } catch (error) {
      this.setState({ loading: false })
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("editWarranty => sendForm", error.message);
    }
  };

  onChangeDate(date, dateString) {
    this.setState({ dateexp: dateString });
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  handleChangeInv = (info) => {

    if(info.file.status){
      const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
      if (!isJpgOrPng) {
        Modal.warning({ title: 'ไฟล์รูปต้องเป็น JPG/PNG เท่านั้น!' })
        return
      }

      fn.getBase64(info.file.originFileObj, (url) => {
        this.setState({ 
          inv_image: url,
          inv_file: info.file.originFileObj
        })
      })

    }

  };

  handleChangeProduct = (info) => {

    if(info.file.status){
      const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
      if (!isJpgOrPng) {
        Modal.warning({ title: 'ไฟล์รูปต้องเป็น JPG/PNG เท่านั้น!' })
        return
      }

      fn.getBase64(info.file.originFileObj, (url) => {
        this.setState({ 
          package_image: url, 
          package_file: info.file.originFileObj
        })
      })

    }

  };

  render() {
    const {
      dataCustomer,
      dataProducts,
      loading,
      error_color,
      error_hidden,
      line_error,
      lineid_colect,
      lineid_colect_color,
      optionProducts,
      optionChanelBuy,
      optionBrands,
      optionModels,
      optionWarrantyType,
      datestart,
      dateexp,
    } = this.state;

    const dateFormat = "YYYY-MM-DD";
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div>
          <h2>{"แก้ไขข้อมูลรับประกัน"}</h2>
          <Row>
            <Col span={14} className="bg-warranty">
              <h3>{"ลูกค้าลงทะเบียนรับประกัน"}</h3>
              <Row>
                {dataCustomer.map((val, i) => {
                  return (
                    <Col
                      key={`input_${i}`}
                      span={val.fieldName === "tel" ? 10 : 13}
                      className="col-warranty"
                    >
                      <label className="label">{val.label}</label>
                      <Input
                        suffix={
                          val.fieldName === "tel" ? (
                            <Icon
                              type={lineid_colect}
                              style={{ width: "100%", marginTop: -20 }}
                              className={lineid_colect_color}
                            />
                          ) : (
                            ""
                          )
                        }
                        type={val.type}
                        className="input-warranty"
                        value={val.value}
                        onKeyPress={
                          val.fieldName === "tel"
                            ? (e) => this.onKeyPressSearch(e, val.type)
                            : ""
                        }
                        onChange={(e) =>
                          this.onChange(e.target.value, val.fieldName)
                        }
                      />
                      {val.fieldName === "tel" ? (
                        <div
                          className={"column-claim-cr"}
                          style={{ marginTop: 1, marginLeft: 5 }}
                        >
                          <font
                            style={{ fontSize: "0.3cm", color: error_color }}
                            hidden={error_hidden}
                          >
                            {line_error}
                          </font>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col span={10} className="col-warranty">
                  <label className="label">วันที่เริ่มรับประกัน :</label>
                  <br />
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment(datestart, dateFormat)}
                    format={dateFormat}
                    disabled
                  />
                </Col>
                <Col span={13} className="col-warranty">
                  <label className="label">วันที่สิ้นสุดรับประกัน :</label>
                  <br />
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment(dateexp, dateFormat)}
                    onChange={(date, dateString) =>
                      this.onChangeDate(date, dateString)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8} className="bg-warranty">
              <h3>{"สินค้าลงทะเบียนรับประกัน"}</h3>
              <Row>
                {dataProducts.map((val, i) => {
                
                  let data_option = [];
                  switch (val.fieldName) {
                    case "nameproduct":
                      data_option = optionProducts;
                      break;
                    case "brandmobile":
                      data_option = optionBrands;
                      break;
                    case "modelmobile":
                      data_option = optionModels;
                      break;
                    case "namestore":
                      data_option = optionChanelBuy;
                      break;
                    case "warranty_type":
                      data_option = optionWarrantyType;
                      break;
                  }
                  return (
                    <Col span={23} className="col-warranty">
                      <label className="label">
                        <font color="red">* </font>
                        {val.label}
                      </label>
                      {val.fieldName === "qrcode" ? (
                        <Input
                          type={val.type}
                          className="input-warranty"
                          value={val.value}
                          disabled
                        />
                      ) : val.fieldName === "inv_image" ? (
                        

                      
                        <Upload 
                            name="inv_image"
                            required
                            fileList={[]}
                            listType="picture-card"
                            accept={"image/jpeg,image/png"}
                            customRequest={this.dummyRequest}
                            onChange={this.handleChangeInv}
                            
                          >  
                          {this.state.inv_image ? (
                                  <img
                                    src={this.state.inv_image}
                                    alt="avatar"
                                    style={{
                                      width: '150px',
                                      height: '150px'
                                    }}
                                  />
                                ) : (
                                  <>
                                      <PlusOutlined />
                                      <div
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Upload
                                      </div>
                                  </>
                                )}
                          </Upload>
                   

                      ) : val.fieldName === "package_image" ? (
                        

                        
                        <Upload 
                            name="package_image"
                            required
                            fileList={[]}
                            listType="picture-card"
                            accept={"image/jpeg,image/png"}
                            customRequest={this.dummyRequest}
                            onChange={this.handleChangeProduct}
                            
                          >  
                          {this.state.package_image ? (
                                  <img
                                    src={this.state.package_image}
                                    alt="avatar"
                                    style={{
                                      width: '150px',
                                      height: '150px'
                                    }}
                                  />
                                ) : (
                                  <>
                                      <PlusOutlined />
                                      <div
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Upload
                                      </div>
                                  </>
                                )}
                          </Upload>
                   

                      ) : (
                        <Select
                          showSearch
                          className="select-warranty"
                          value={val.value}
                          onChange={(value) =>
                            this.onChange(value, val.fieldName)
                          }
                        >
                          {data_option}
                        </Select>
                      )}
                    </Col>
                  );
                })}
              </Row>

              <Row style={{ float: "right" }}>
                <BT
                  style={{ background: color.dplus_main, color: "#FFFFFF" }}
                  onClick={() => this.handleSubmit()}
                >
                  {"บันทึก"}
                </BT>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default EditWarrantyScene;
