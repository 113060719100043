import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Form,
  Icon,
  Input,
  Row,
  Col,
  Button as BT,
  Spin,
  Modal,
  message,
  Select,
  Upload
} from "antd";
import autobind from "autobind-decorator";
import { PlusOutlined } from '@ant-design/icons';
import {
  GET,
  POST,
  GET_FIND_CUSTOMER,
  INSERT_WARRANTY,
  GET_OPTION_CHANNEL_BUY,
  GET_OPTION_WARRANTY_TYPE,
  GET_PRODUCTS_BY_CATEGORY,
  VALIDATE_QRCODE,
  INSERT_CUSTOMER,
} from "../../service";
import { BtnGoback } from "../../components/Button";
import { color } from "../../resource";
const fn = require("../../functions/functions");

@Form.create({ name: "RegisterWarranty" })
@observer
class RegisterWarranty extends Component {
  constructor(props) {
    super(props);
    this.props.form.setFieldsValue({ name: "aaa" });
    this.state = {
      loading: true,
      product_name: "",
      name: "",
      telephone: "",
      lineid_colect: "",
      qrcode_colect: "",
      error_hidden: true,
      line_error: "ยังไม่มีเบอร์โทรในระบบ",
      error_color: "red",
      product: [],
      brand: [],
      model: [],
      model_name: "กรุณาเลือกรุ่น",
      item_code:"",
      channel_type_store: "",
      channel_buy: [],
      data_model: [],
      option_warranty_type: [],
      namedis: false,
      errorqrcode_detail: "",
      errorqrcode: false,
      user_id: "",
      store_no: "",
      store_name: "",
      store_id: "",
      branch_no: "",
      channel_buy_name: "",
      brand_id: "",
      brand_name: "",
      focus_store_no: "",
      focus_store_name: "",
      line_id: "",
      inv_image: "",
      inv_file: {},
      package_image: "",
      product_file: {},
      productsAll:[]
    };
  }

  componentDidMount() {
    this.getChannelBuy();
    this.getWarrantyType();
  }

  @autobind
  async getTel(tel) {
    const obj = { telephone: tel };
    try {
      const res = await POST(GET_FIND_CUSTOMER, obj);
      const { success, result } = res;
      if (success) {
        this.setState({
          loading: false,
          data_telephone: result.telephone,
          line_id: result.line_id,
          lineid_colect: result.has_customer ? "check-circle" : "close-circle",
          lineid_colect_color: result.has_customer
            ? "certain-category-icon-colect"
            : "certain-category-icon-uncolect",
          error_hidden: false,
          error_color: result.has_customer ? "green" : "red",
          line_error: result.has_customer
            ? "พบเบอร์โทรในระบบ"
            : "ไม่พบเบอร์โทรในระบบ",
          name: result.has_customer ? result.name : "",
          namedis: result.has_customer,
          user_id: result.has_customer ? result.user_id : "",
        });
        this.props.form.setFieldsValue({
          name: result.has_customer ? result.name : "",
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("RegisterWarranty => getTel", error.message);
    }
  }

  @autobind
  async getChannelBuy() {
    try {
      const r = await GET(GET_OPTION_CHANNEL_BUY);
      if (r.success) {
        this.setState({ channel_buy: r.result, loading: false });
      }
    } catch (err) {
      console.log("getChannelBuy()=>", err.message);
    }
  }

  @autobind
  async getWarrantyType() {
    try {
      const r = await GET(GET_OPTION_WARRANTY_TYPE);
      if (r.success) {
        this.setState({ option_warranty_type: r.result.option, loading: false });
      }
    } catch (err) {
      console.log("getChannelBuy()=>", err.message);
    }
  }

  @autobind
  async createWarranty() {
    this.setState({
      loading: true,
    });

    const {
      name,
      telephone,
      product_name,
      model_name,
      brand_name,
      item_code,
      category,
      qrcode,
      user_id,
      product_id,
      line_id,
      channel_buy_name,
      warranty_type,
      inv_file,
      product_file
    } = this.state;

    let obj = {
      category,
      qrcode,
      nameproduct: product_name,
      brandmobile: brand_name,
      modelmobile: model_name,
      item_code,
      name,
      tel: telephone,
      register_type: "WEB",
      type_store: "COMPANY",
      namestore: channel_buy_name,
      product_id,
      line_id,
      warranty_type,
      inv_file,
      product_file
    };
    
    let obj_create = {};

    if (!user_id) {
      try {
        const obj_insert = {
          line_id: "",
          line_name: "",
          telephone: telephone,
        };
        const res_insert = await POST(INSERT_CUSTOMER, obj_insert);
        const { success, result } = res_insert;

        if (success) {
          obj_create = {
            ...obj,
            user_id: result.user_id,
          };
        }
      } catch (error) {
        Modal.error({ title: error.message });
        return fn.larkNotiMonitor(
          "RegisterWarranty => INSERT_CUSTOMER",
          error.message
        );
      }
    } else {
      obj_create = {
        ...obj,
        user_id: user_id,
      };
    }

    try {

      let data = new FormData()
      data.append("category", obj_create.category)
      data.append("qrcode", obj_create.qrcode)
      data.append("item_code", obj_create.item_code)
      data.append("nameproduct", obj_create.nameproduct)
      data.append("brandmobile", obj_create.brandmobile)
      data.append("modelmobile", obj_create.modelmobile)
      data.append("name", obj_create.name)
      data.append("tel", obj_create.tel)
      data.append("register_type", obj_create.register_type)
      data.append("type_store", obj_create.type_store)
      data.append("namestore", obj_create.namestore)
      data.append("product_id", obj_create.product_id)
      data.append("line_id", obj_create.line_id)
      data.append("warranty_type", obj_create.warranty_type)
      data.append("inv_image", obj_create.inv_file)
      data.append("package_image", obj_create.product_file)
      data.append("user_id", user_id)

      let res = await POST(INSERT_WARRANTY, data);
      if (res.success) {
        this.setState({
          loading: false,
        });
        this.clear();
        Modal.success({
          title: "บันทึกข้อมูลสำเร็จ",
          content: `ชุดเอกสารลงทะเบียน ${res.result.no_warranty}`,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor(
        "RegisterWarranty => createWarranty",
        error.message
      );
    }
  }

  getProductList = async (category) => {
    try {
      const res = await GET(GET_PRODUCTS_BY_CATEGORY(category));

      const {success,result} = res
      if (success) {

        this.setState({
          product: result.products,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    }
  };
  @autobind
  async checkWarranty() {
    const { qrcode, channel_buy } = this.state;
    if (qrcode) {
      try {
        let res = await GET(VALIDATE_QRCODE(fn.cutURL(qrcode)));
        const { success } = res;
        const { can_use, category, message } = res.result;
        if (success) {

          this.setState(
            {
              channel_buy: can_use ? channel_buy : [],
              qrcode: fn.cutURL(qrcode),
              category: can_use ? category : "",
              errorqrcode_detail: message,
              errorqrcode: true,
              error_color_qrcode: can_use ? "green" : "red",
              qrcode_colect: can_use ? "check-circle" : "close-circle",
              qrcode_colect_color: can_use
                ? "certain-category-icon-colect"
                : "certain-category-icon-uncolect",
            },

            () =>
              can_use
                ? (this.getProductList(category), this.getChannelBuy())
                : ""
          );
          can_use
            ? this.props.form.setFieldsValue({
                qrcode: fn.cutURL(qrcode),
              })
            : this.props.form.setFieldsValue({
                qrcode: fn.cutURL(qrcode),
                product_name: "",
                product: [],
                brand_name: "",
                brand: [],
                model_name: "",
                model: [],
                channel_buy_name: "",
                channel_buy: [],
              });
        }
      } catch (error) {
        Modal.error({ title: "QR Code หรือ  URL ไม่ถูกต้อง" });
        return fn.larkNotiMonitor(
          "RegisterWarranty => checkWarranty",
          "QR Code หรือ  URL ไม่ถูกต้อง"
        );
      }
    }
  }

  @autobind
  async onChange(e, ref) {

    let { product } = this.state;
    if (ref === "product_name") {
      let findData = product.find((el) => el.product_id === e.split("|")[0]);
      this.setState({
        [ref]: e.split("|")[1],
        product_id: findData.product_id,
        brand:findData.brands,
      });
      this.props.form.setFieldsValue({
        brand_name: "",
        model_name: "",
      });
    }else if(ref === "model_name"){
        this.setState({
          [ref]: e.split("|")[1],
          item_code: e.split("|")[0],
        });
    }else {
      this.setState({
        [ref]: e,
      });
    }

    let obj = {
      product: [],
      brand: [],
      model: [],
      brand_name: "",
      product_name: "",
      model_name: "",
      channel_buy_name: "",
      channel_buy: [],
      warranty_type: ""
    };
    switch (ref) {
      case "telephone":
        this.setState({
          lineid_colect: "",
          error_hidden: true,
          name: "",
          namedis: false,
        });
        break;
      case "qrcode":
        this.setState({
          errorqrcode: false,
          ...obj,
        });
        this.props.form.setFieldsValue({
          namedis: false,
          ...obj,
        });
    }
  }

  @autobind
  onKeyPressSearch(event, type) {
    if (event.key === "Enter") {
      type === "telephone"
        ? this.getTel(event.target.value)
        : this.checkWarranty();
    }
  }

  @autobind
  clear() {
    this.setState({
      product_name: "",
      imageUrlSlip: "",
      name: "",
      telephone: "",
      lineid_colect: "",
      error_hidden: true,
      namedis: false,
      product: [],
      brand: [],
      model: [],
      data_model: [],
      brand_name: "",
      model_name: "",
      channel_type_store: "",
      channel_buy: [],
      errorqrcode: false,
      qrcode_colect: "",
      user_id: "",
      channel_buy_name: "",
      dataModelByBrand: [],
    });
    this.props.form.setFieldsValue({
      name: "",
      telephone: "",
      qrcode: "",
      product: [],
      brand: [],
      model: [],
      item_code:"",
      data_model: [],
      namedis: false,
      brand_name: "",
      product_name: "",
      model_name: "",
      channel_type_store: "",
      channel_buy: [],
      channel_buy_name: "",
    });
  }
  // @autobind
  // phonValidate() {
  //   fn.phonNumberValidate(this.state.telephone);
  // }
  @autobind
  goBack() {
    this.props.history.goBack();
  }
  onChangeSelectBrand = (value) => {
    const { brand } = this.state;
    const filterBrand = brand.filter(
      (el) => el.brand_name === value
    );

    const option = filterBrand[0].models.map((el) => {
      return { value: `${el.item_code}|${el.model_name}`, label: el.model_name };
    });

    this.setState({
      model: option,
      brand_name: value,
    });
  };

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!fn.phonNumberValidate(this.state.telephone)) {
          message.error("เบอร์โทรของท่านไม่ถูกต้อง");
        } else {
          this.createWarranty();
        }
      }
    });
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  handleChangeInv = (info) => {
 
    if(info.file.status){
      const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
      if (!isJpgOrPng) {
        Modal.warning({ title: 'ไฟล์รูปต้องเป็น JPG/PNG เท่านั้น!' })
        return
      }

      fn.getBase64(info.file.originFileObj, (url) => {
        this.setState({ 
          inv_image: url,
          inv_file: info.file.originFileObj
        })
      })
 
    }

  };

  handleChangeProduct = (info) => {
    if(info.file.status){
      const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
      if (!isJpgOrPng) {
        Modal.warning({ title: 'ไฟล์รูปต้องเป็น JPG/PNG เท่านั้น!' })
        return
      }

      fn.getBase64(info.file.originFileObj, (url) => {
        this.setState({ 
          package_image: url, 
          product_file: info.file.originFileObj
        })
      })

    }

  };

  render() {
    const {
      error_hidden,
      line_error,
      loading,
      product_name,
      brand_name,
      name,
      telephone,
      lineid_colect,
      lineid_colect_color,
      qrcode,
      model,
      model_name,
      product,
      brand,
      errorqrcode,
      errorqrcode_detail,
      qrcode_colect,
      qrcode_colect_color,
      channel_buy,
      channel_buy_name,
      warranty_type,
      option_warranty_type,
      category,
      inv_image,
      package_image
    } = this.state;
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }

    console.log({product,model})
    const { getFieldDecorator } = this.props.form;
    getFieldDecorator("RegisterWarranty", { initialValue: [] });
    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        onSubmit={this.handleSubmit}
      >
        <div>
          <BtnGoback title={"กลับ"} onClick={() => this.goBack()} />

          <Row>
            <Col span={10} push={14}>
              <div
                className={"border-claim"}
                style={{ margin: 15, marginBottom: 30 }}
              >
                <div className={"column-claim-cr"}>
                  <font style={{ fontSize: "0.4cm" }}>
                    สินค้าลงทะเบียนรับประกัน
                  </font>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -10 }}>
                  <Form.Item label="รหัสรับประกันสินค้า (กรอกรหัสรับประกันแล้ว กด Enter)">
                    {getFieldDecorator("qrcode", {
                      rules: [
                        {
                          required: true,
                          message: "กรุณาใส่รหัสรับประกันสินค้า",
                        },
                      ],
                    })(
                      <Input
                        suffix={
                          <Icon
                            type={qrcode_colect}
                            style={{ width: "100%", marginTop: -20 }}
                            className={qrcode_colect_color}
                          />
                        }
                        defaultValue={qrcode}
                        value={qrcode}
                        onKeyPress={(e) => this.onKeyPressSearch(e, "qrcode")}
                        onChange={(e) =>
                          this.onChange(e.target.value, "qrcode")
                        }
                      />
                    )}
                  </Form.Item>

                  <div
                    className={"column-claim-cr"}
                    style={{ marginTop: -20, marginLeft: 5 }}
                  >
                    <font
                      style={{
                        fontSize: "0.3cm",
                        color: this.state.error_color_qrcode,
                      }}
                      hidden={!errorqrcode}
                    >
                      {errorqrcode_detail}
                    </font>
                  </div>
                </div>
                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="ชื่อสินค้า">
                    {getFieldDecorator("product_name", {
                      rules: [{ required: true, message: "เลือกชื่อสินค้า" }],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="ชื่อสินค้า"
                        optionFilterProp="children"
                        onChange={(e) => this.onChange(e, "product_name")}
                        value={product_name}
                      >
                        {product.map((item) => (
                          <Select.Option value={`${item.product_id}|${item.product_name}`}>
                            {item.product_name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                {category !== "EC" ? (
                  <>
                    <div
                      className={"column-claim-cr"}
                      style={{ marginTop: -20 }}
                    >
                      <Form.Item label="ยี่ห้อมือถือ">
                        {getFieldDecorator("brand_name", {
                          rules: [
                            { required: true, message: "เลือกยี่ห้อมือถือ" },
                          ],
                        })(
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="เลือกยี่ห้อ"
                            optionFilterProp="children"
                            onChange={this.onChangeSelectBrand}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={brand_name}
                          >
                            {brand.map((item) => (
                              <Select.Option value={item.brand_name}>
                                {item.brand_name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                    <div
                      className={"column-claim-cr"}
                      style={{ marginTop: -20 }}
                    >
                      <Form.Item label="รุ่นมือถือ">
                        {getFieldDecorator("model_name", {
                          rules: [
                            { required: true, message: "เลือกรุ่นมือถือ" },
                          ],
                        })(
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="เลือกรุ่นมือถือ"
                            optionFilterProp="children"
                            onChange={(e) => this.onChange(e, "model_name")}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={model_name}
                          >
                            {model.map((item) => (
                              <Select.Option value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="ช่องทางการซื้อ">
                    {getFieldDecorator("channel_buy_name", {
                      rules: [
                        { required: true, message: "เลือกช่องทางการซื้อ" },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เลือกช่องทางการซื้อ"
                        optionFilterProp="children"
                        onChange={(e) => this.onChange(e, "channel_buy_name")}
                        //onSearch={this.onSearchProduct}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={channel_buy_name}
                      >
                        {/* {model.map(item => <Select.Option value={item.model_name}>{item.model_name}</Select.Option>)} */}
                        {channel_buy.map((item) => (
                          <Select.Option value={item.channel_buy_name}>
                            {item.channel_buy_name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>


                <div className={"column-claim-cr"} style={{ marginTop: -20 }}>
                  <Form.Item label="ประเภทรับประกัน">

                    {
                    getFieldDecorator("warranty_type", {
                      rules: [
                        { required: true, message: "เลือกประเภทรับประกัน" },
                      ],
                    }
                    )
                    (
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เลือกประเภทรับประกัน"
                        optionFilterProp="children"
                        onChange={(e) => this.onChange(e, "warranty_type")}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={warranty_type}
                      >
                        {/* {model.map(item => <Select.Option value={item.model_name}>{item.model_name}</Select.Option>)} */}
                        {option_warranty_type.map((item) => (
                          <Select.Option value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>

              <div style={{ marginTop: -20, marginLeft: 30, display: 'flex' }}>

                <div style={{ width: '50%' }} >
                  <font style={{ fontSize: "0.4cm", lineHeight: "40px" }}>
                    รูปใบเสร็จ
                  </font>
                  <Upload 
                      name="inv_image"
                      required
                      fileList={[]}
                      listType="picture-card"
                      accept={"image/jpeg,image/png"}
                      customRequest={this.dummyRequest}
                      onChange={this.handleChangeInv}
                      
                    >  
                    {inv_image ? (
                            <img
                              src={inv_image}
                              alt="avatar"
                              style={{
                                width: '200px',
                                height: '200px'
                              }}
                            />
                          ) : (
                            <>
                                <PlusOutlined />
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  Upload
                                </div>
                            </>
                          )}
                    </Upload>
                </div>
                  
                <div style={{ width: '50%' }} >
                  <font style={{ fontSize: "0.4cm", lineHeight: "40px" }}>
                    รูปสินค้า
                  </font>
                  <Upload 
                      name="package_image"
                      required
                      fileList={[]}
                      listType="picture-card"
                      accept={"image/jpeg,image/png"}
                      customRequest={this.dummyRequest}
                      onChange={this.handleChangeProduct}
                      
                    >  
                     {package_image ? (
                            <img
                              src={package_image}
                              alt="avatar"
                              style={{
                                width: '200px',
                                height: '200px'
                              }}
                            />
                          ) : (
                            <>
                                <PlusOutlined />
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  Upload
                                </div>
                            </>
                          )}
                    </Upload>
                </div>
              </div>


              </div>
            </Col>
            <Col span={14} pull={10}>
              <div className={"border-claim"} style={{ margin: 15 }}>
                <div className={"column-claim-cr"}>
                  <font style={{ fontSize: "0.4cm" }}>
                    ลูกค้าลงทะเบียนรับประกัน
                  </font>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: -30,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="เบอร์โทรศัพท์ (กรอกเบอร์โทรแล้ว กด Enter)">
                        {getFieldDecorator("telephone", {
                          rules: [
                            { required: true, message: "กรุณาระบุเบอร์โทร" },
                          ],
                        })(
                          <Input
                            suffix={
                              <Icon
                                type={lineid_colect}
                                style={{ fontSize: "16px" }}
                                className={lineid_colect_color}
                              />
                            }
                            style={{ width: "95%" }}
                            defaultValue={telephone}
                            placeholder="เบอร์โทร"
                            value={telephone}
                            onKeyPress={(e) =>
                              this.onKeyPressSearch(e, "telephone")
                            }
                            onChange={(e) =>
                              this.onChange(e.target.value, "telephone")
                            }
                          />
                        )}
                      </Form.Item>

                      <div
                        className={"column-claim-cr"}
                        style={{ marginTop: -20, marginLeft: 5 }}
                      >
                        <font
                          style={{
                            fontSize: "0.3cm",
                            color: this.state.error_color,
                          }}
                          hidden={error_hidden}
                        >
                          {line_error}
                        </font>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr"}>
                      <Form.Item label="ชื่อ-นามสกุล">
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: "กรุณาใส่ ชื่อ-นามสกุล",
                            },
                          ],
                        })(
                          <Input
                            style={{ width: "95%" }}
                            defaultValue={name}
                            value={name}
                            // disabled={namedis}
                            onChange={(e) =>
                              this.onChange(e.target.value, "name")
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col span={14} pull={10}>
              <div style={{ margin: 15, marginTop: 250 }}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    margin: 15,
                  }}
                >
                  <BT
                    style={{
                      width: "10vw",
                      height: "20vw",
                      background: "#FFFFFF",
                    }}
                    onClick={() => this.clear()}
                    hidden={!telephone}
                  >
                    เคลียร์ข้อมูล
                  </BT>
                  <BT
                    htmlType="submit"
                    style={{
                      width: "10vw",
                      height: "20vw",
                      background: color.dplus_main,
                      color: "#FFFFFF",
                    }}
                  >
                    ลงทะเบียน
                  </BT>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    );
  }
}
// Form.create({ name: 'dynamic_rule' })(RegisterWarranty);
export default RegisterWarranty;
