import React, { Component } from "react";
import WrapperComponents from "../components/hoc/WrapperComponents";
import { observer } from "mobx-react";
import { color, icon } from "../resource";
import { Row, Col, message, Spin } from "antd";

import { Input } from "../components/Input";
import autobind from "autobind-decorator";
import { Button } from "../components/Button";
import { POST, LOGIN } from "../service";
import user, { KEY } from "../mobx/user";

@WrapperComponents()
@observer
class LoginScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isLoading: true,
    };
  }

  componentWillMount() {}

  async componentDidMount() {
    const res = await localStorage.getItem(KEY);
    const obj = JSON.parse(res);
    if (obj && obj.role) {
      this.props.history.push("/");
    }

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }

  @autobind
  onChange(ref, e) {
    this.setState({ [ref]: e });
  }

  @autobind
  async onLogin() {
    let { email, password } = this.state;
    try {
      const res = await POST(LOGIN, { username: email, password });
      if (res.success) {
        await user.login(res.result);
        this.props.history.push("/claim");
      }
    } catch (error) {
      if (!error.success && error.message === "ไม่พบผู้ใช้งาน") {
        message.error(error.message);
      }
    }
  }

  @autobind
  onKeyPress(e) {
    if (e.key === "Enter") {
      this.onLogin();
    }
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="example">
          <Spin />
        </div>
      );
    }

    return (
      <div style={defaultLogin}>
        <Row style={{ width: "90%" }}>
          <Col
            span={12}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={icon.dplus} className={"icon-rizz"} alt={""} /> */}
            <img src={icon.service} className={"icon-dplus"} alt={""} />
          </Col>

          <Col
            span={12}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                textAlign: "center",
                width: "23vw",
                alignSelf: "center",
              }}
            >
              <div
                style={{ backgroundColor: color.dplus_primary }}
                className={"header-login"}
              >
                <font style={{ fontSize: "1.2vw" }}>เข้าสู่ระบบ</font>
              </div>
              <div className={"content-login"}>
                <Input
                  placeholder={"ชื่อผู้ใช้งาน"}
                  value={this.state.email}
                  width={"60%"}
                  onChange={(e) => this.onChange("email", e.target.value)}
                />

                <Input
                  placeholder={"รหัสผู้ใช้งาน"}
                  value={this.state.password}
                  name={"password"}
                  width={"60%"}
                  onChange={(e) => this.onChange("password", e.target.value)}
                  onKeyPress={(e) => this.onKeyPress(e)}
                />
                <Button
                  title={"เข้าสู่ระบบ"}
                  type={"dplus"}
                  onPress={this.onLogin}
                  buttonStyle={{ width: "80%", marginTop: "10px" }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const defaultLogin = {
  backgroundColor: color.bg_login,
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

export default LoginScene;
