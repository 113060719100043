import React from "react";
import { Modal } from "antd";
import { PATH_IMAGE } from "../../../service";
export const ModalDetailClaim = ({ record, visible, onClose,fn }) => {
    return (<Modal
        title={<p style={{ textAlign: "center", marginBottom: "0px",fontSize: '20px'}}>{`รายละเอียดการเคลม ( ${record.no_claim} ${record.name} )`}</p>}
        visible={visible}
        onCancel={onClose}
        width={"720px"}
        footer={null}
    >
        <div style={{ display: "flex", alignItems: "center" }}>
            <div><img src={`${PATH_IMAGE}${record.picclaim}`} style={{ width: '250px', height: '250px' }} onClick={() => fn(record.picclaim)} 
                className={"contain"}
            /></div>
            <div style={{ marginLeft: "1vw" }}>
                <div style={{ display: "flex", marginBottom: "-12px" }}><p style={{ fontSize: '18px' }}>{`ชื่อสินค้า : ${record.nameproduct}`}</p></div>
                <div style={{ display: "flex", marginBottom: "-12px" }}><p style={{ fontSize: '18px' }}>{`รุ่นมือถือ : ${record.brandmobile} ${record.modelmobile}`}</p></div>
                <div style={{ display: "flex", marginBottom: "-12px" }}><p style={{ fontSize: '18px' }}>{`อาการเสีย : ${record.detail}`}</p></div>

            </div>
            <div>
          {/* {record.bill_pay && (
            <img
              src={`${PATH_IMAGE_PAYMENT}${record.bill_pay}`}
              style={{ width: 100, height: 100 }}
              onClick={() =>
                this.clickImage(`${PATH_IMAGE_PAYMENT}${record.bill_pay}`)
              }
              className={"contain"}
            />
          )} */}
        </div>

            <div style={{ marginLeft: "1vw" }}>
                {record.date_pay && (
                    <div style={{ display: "flex", marginBottom: "-12px" }}>
                        <p style={{ margin: 0, width: "100px" }}>วันที่ชำระ</p>

                        <p>{record.date_pay}</p>
                    </div>
                )}
                {record.time_pay && (
                    <div style={{ display: "flex", marginBottom: "-12px" }}>
                        <p style={{ margin: 0, width: "100px" }}>เวลาชำระ</p>
                        <p>{record.time_pay}</p>
                    </div>
                )}
                {record.money_pay && (
                    <div style={{ display: "flex", marginBottom: "-12px" }}>
                        <p style={{ margin: 0, width: "100px" }}>ยอดเงินที่ชำระ</p>

                        <p>{record.money_pay}</p>
                    </div>
                )}
            </div>
        </div>
    </Modal>)
}