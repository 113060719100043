import { create } from "apisauce";
import user from "./mobx/user";

//export const ip = "http://localhost:2230/ablemen/api/v1";
// export const ip = "https://dev-ablemen.zetta-system.com:2083/ablemen/api/v1";
///export const ip = "https://dplus-service.com:2230/ablemen/api/v1";
export const ip = "https://ablemen.dplus-frontb.com:2083/ablemen/api/v1"; //production

export const PATH_IMAGE = `${ip}/images/claim&`;
export const PATH_IMAGE_PAYMENT = `https://warranty-space.sgp1.digitaloceanspaces.com/ablemen/payment/`;
export const ip_dpl = "http://dplus-system.com:9080/api";

export const version = 'VERSION. 2.5.0 | 2024-07-08 15:00'

const api = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});
const api_dplus = create({
  baseURL: ip_dpl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});

const apiupload = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    Accept: "multipart/form-data",
  },
});

const apiapp = create({
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj, token = user.accessToken) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
export const POST_DPL = (path, obj) =>
  new Promise((resolve, reject) => {
    api_dplus
      .post(path, obj)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = user.accessToken) =>
  new Promise((resolve, reject) => {
    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata, token = user.accessToken) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const CHECK = () => {
  new Promise((resolve, reject) => {
    api
      .post()
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

export const GETAPP = (path) =>
  new Promise((resolve, reject) => {
    apiapp
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const LOGIN = "/authen/login";
export const GET_FIND_CUSTOMER = `/authen/findCustomer`;

export const UPDATE_TRACKING = "/tracking/updateTracking";
export const UNACTIVE = "/warranty/unactiveWarranty";
export const GET_REPORT_COVER_SHEET = "/report/getCoverSheet";

export const UPDATE_CLAIM_STATUS = "/claim/updateClaim";
export const EDIT_CLAIM_BY_ID = "/claim/editClaim";
export const EDIT_PAY_BY_ID = "/payment/editPay";

export const GET_ALL_CUSTOMER = "/authen/getUserAll";
export const INSERT_WARRANTY = "/warranty/insertWarranty";
export const SEND_SMS_REGISTER = "/sms/registersend";

export const INSERT_CLAIM = "/claim/insertClaim";
export const INSERT_ADDRESS = "/address/insertAddress";
export const UPDATE_ADDRESS = "/address/updateAddress";
export const SEND_SMS_CLAIM = "/sms/createclaimsend";
export const GET_HISTORY = "/claim/getHistory";
// export const FOCUS_GETPAYMENT = "/payment/getPaymentById";
export const FOCUS_INSERTPAYMENT = "/payment/insertPaymentByAdmin";
export const FOCUS_ACCOUNTINGREPORT = "/report/focus_getaccountingreport";
export const FOCUS_GETDATAIMPORTAX = "/report/focus_getdatatoimportax";
//export const FOCUS_UPDATEWARRABTY = "/warranty/updateWarranty";
export const FOCUS_IMPORTAX = "/insertProduct";
export const FOCUS_GETBRAND_CATEGORY = "/product/getBrandByCategory";
// export const FOCUS_GETCODEACTIVEBYID = "/warranty/getCodegenerateActiveById";
export const FOCUS_GETALLPRODUCT = "/product/getAllProduct";
export const GET_ALL_WARRANTY = "/warranty/getAllWarranty";
export const GET_OPTION_CHANNEL_BUY = "/option/getChannelBuy";
export const GET_OPTION_WARRANTY_TYPE = "/option/get-warranty-type";
export const FOCUS_GETSTORE = "/store/checkStoreNo/";
export const FOCUS_CHECKTEL = "/authen/checkTelephone/";
export const FOCUS_RESETLINEID = "/authen/resetLineId";

// export const FOCUS_WARRANTY_ACTIVE_ID = "/warranty/getCodegenerateActiveById";
export const UPDATE_WARRANTY = "/warranty/updateWarranty";
export const FOCUS_GET_PRODUCTS_WARRANTY = "/product/getAllProduct";
export const FOCUS_UPDATE_PRODUCTS_WARRANTY = "/product/updateProduct";
export const FOCUS_INSERT_PRODUCTS_WARRANTY = "/product/insertProduct";

export const FOCUS_GET_STORE_LIST = "/store/getStoreList";
export const FOCUS_GET_OPTION_TYPESTORE = "/option/getTypeStore";
export const FOCUS_SEARCH_STORE_NO = "/store/checkStoreNo";
export const FOCUS_INSERT_DEALER = "/store/insertStore";
export const FOCUS_EDIT_DEALER = "/store/updateStore";
export const FOCUS_STORE_BY_TYPE = "/store/getStoreByType";
export const FOCUS_GET_OPTION_CHENNEL_CALIM = "/option/getChannelClaim";
export const GET_OPTION_BREAKDOWN = (product_type) =>
  `/option/getBreakdown/${product_type}`;
export const GET_PRODUCT_BY_CATEGORY = (category) =>
  `/product/getProductByCategory/${category}`;
export const GET_ADDRESS_BY_ZIPCODE = (zipcode) =>
  `/address/getAddressByZipcode/${zipcode}`;
export const VALIDATE_QRCODE = (qrCode) => `/qrcode/validate/${qrCode}`;
export const GET_WARRANTY_BY_TEL = (tel) => `/warranty/getWarrantyByTel/${tel}`;
export const GET_OPTION_SHIPPING = (shipping_type) =>
  `/option/getShipping/${shipping_type}`;
export const CREATE_TRACKING = `/tracking/createTracking`;

export const GET_ALL_CLAIM = "/claim/getAll";
export const LARK_NOTI_MONITOR = `/system/larkNoTi`;
export const GET_DETAIL_CLAIM = "/claim/getClaimById";
export const INSERT_CUSTOMER = `/authen/insertCustomer`;

//CLAIM
export const EDIT_NOTE = `/claim/edit-note`;
export const EDIT_STATUS_INTERNAL = `/claim/edit-status-internal`;



//CUSTOMER
export const GET_ALL_USER = `/customer/get-all`;
export const UPDATE_USER = `/customer/update`;

//OPTION
export const GET_LEVEL = `/option/get-level`;
export const GET_CHANNEL_ORDER = `/option/get-channel-order`;
export const GET_ORDER_STATUS = `/option/get-order-status`;
export const GET_OPTION_PROMOTION = `/option/get-promotion`;
export const GET_OPTION_STATUS_INTERNAL = `/option/get-status-internal`;
export const GET_PRODUCTS_BY_CATEGORY = (category)=>`/product/get-product-detail?category=${category}`


//ORDER
export const GET_ALL_ORDER = `/order/get-all`;
export const ADD_ORDER = `/order/add`;
export const EDIT_ORDER = `/order/edit`;

//REPORT
export const GET_REPORT_SUPER_MEMBER = `/report/get-super-member`;
export const GET_REPORT_USED_COUPON = `/report/get-used-coupon`;
export const GET_REPORT_FREQUENCY_CUSTOMER = `/report/get-frequency-customer`;
export const GET_REPORT_ALL_COUPON = `/report/get-all-coupon`;

//PROMOTION
export const GET_PROMOTION = `/promotion/get-promotion`;

//STORE
export const GET_ALL_STORE = "/store/get-all";
export const ADD_STORE = "/store/add";
export const EDIT_STORE = "/store/edit";

//SERVICE
export const SEND_MESSAGE_LINE = `/service/send-message-line`;

//PAYMENT
export const UPDATE_PAYMENT = `/payment/updateClaimPayment`;

