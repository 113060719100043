import React from "react";
import { radioTracking } from "../resource/constant";
import {
  Input,
  Row,
  DatePicker,
  Modal,
  Col,
  Switch,
  Button,
  Select,
  Form,
  Radio,
} from "antd";
import { Label } from "../styles/styled";
import {
  DownloadOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import QRCode from "qrcode.react";
//import { downloadQR } from "../functions/functions";
const fn = require("../functions/functions");

export const ModalEMS = ({ obj = {}, onChange = () => {} }) => {
  return (
    <div>
      <Row>
        <Label>
          <font color="red">* </font>การจัดส่ง
        </Label>
        <Radio.Group
          value={obj["shipping_type"]}
          onChange={(e) => onChange(e.target.value, "shipping_type")}
        >
          {radioTracking.map((el, i) => {
            return (
              <Radio value={el.value} key={i}>
                {el.label}
              </Radio>
            );
          })}
        </Radio.Group>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Label>
          <font color="red">* </font>ขนส่ง
        </Label>
        <Select
          style={{ width: "80%" }}
          onChange={(value) => onChange(value, "shipping")}
          placeholder="กรุณาเลือกขนส่ง"
          value={
            obj["shipping_id"]
              ? `${obj["shipping_id"]}|${obj["shipping_name"]}`
              : ""
          }
        >
          {obj["option_shipping"].map((el, i) => {
            return <Select.Option value={el.value}>{el.label}</Select.Option>;
          })}
        </Select>
      </Row>

      {obj["shipping_type"] === "SELF" ? (
        <Row style={{ marginTop: "20px" }}>
          <Label>
            <font color="red">* </font>เลขพัสดุ
          </Label>
          <Input
            //autoFocus
            //placeholder={"กรุณาใส่เลขพัสดุ"}
            style={{ width: "80%" }}
            value={obj["tracking"]}
            onChange={(e) => onChange(e.target.value, "tracking")}
          />
        </Row>
      ) : (
        ""
      )}

      <Row style={{ marginTop: "20px" }}>
        <Label>
          <font color="red">* </font>วันที่จัดส่ง
        </Label>
        <DatePicker
          style={{ width: "80%" }}
          placeholder={"กรุณาเลือกวันจัดส่ง"}
          value={obj["shipping_date"]}
          format={"DD/MM/YYYY"}
          onChange={(date) => onChange(date, "shipping_date")}
        />
      </Row>
    </div>
  );
};
export const ModalActive = ({ obj = {}, onChange = () => {} }) => {
  return (
    <div>
      <Row>
        <Label>รหัสรับประกัน</Label>
        <Input
          autoFocus
          placeholder={"กรุณาใส่เลขรหัสรับประกัน"}
          style={{ width: "80%" }}
          value={obj["unactive_code"]}
          onChange={(e) => onChange(e.target.value, "unactive_code")}
        />
      </Row>
    </div>
  );
};
export const ModalReject = ({ obj = {}, onChange = () => {} }) => {
  return (
    <div>
      <Row>
        <Input
          autoFocus
          placeholder={"กรุณาใส่เหตุผล"}
          style={{ width: "100%" }}
          value={obj["reject_detail"]}
          onChange={(e) => onChange(e.target.value, "reject_detail")}
        />
      </Row>
    </div>
  );
};
export const ModelSuccess = ({ title, detail }) => {
  Modal.success({
    title: { title },
    content: { detail },
  });
};
export const ModelError = ({ title, error }) => {
  Modal.error({
    title: { title },
    content: { error },
  });
};

export const ModalEditProduct = ({
  title,
  detail,
  openModal,
  closeModal,
  product_image_url,
  product_name,
  product_status,
  onChange,
  updateProduct,
}) => {
  return (
    <Modal
      title={<h2 className="text-title">แก้ไขสินค้ารับประกัน</h2>}
      visible={openModal}
      onCancel={closeModal}
      footer={[
        <Button key="submit" type="primary" onClick={() => updateProduct()}>
          บันทึก
        </Button>,
      ]}
    >
      <Row style={{ textAlign: "center" }}>
        <Col>
          <img src={product_image_url} style={{ width: "50%" }} />
          <Button className="btn-upload">
            <input
              className="fileImage"
              type="file"
              accept="image/*"
              onChange={(e) => onChange("img", e)}
            />
            อัพโหลดรูปภาพ
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: "1em" }}>
        <Col>
          <Label>ชื่อสินค้า</Label>
          <Input
            size="small"
            value={product_name}
            onChange={(e) => onChange("product_name", e)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>สถานะ</Label>
          <Switch
            defaultChecked={product_status === "1" ? true : false}
            onChange={(e) => onChange("product_status", e)}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export const ModalAddProduct = ({
  title,
  detail,
  openModal,
  closeModal,
  product_image_url,
  product_name,
  product_status,
  onChange,
  AddProduct,
}) => {
  const category = ["U", "S", "P"];

  return (
    <Modal
      title={<h2 className="text-title">เพิ่มสินค้ารับประกัน</h2>}
      visible={openModal}
      onCancel={closeModal}
      footer={[
        <Button key="submit" type="primary" onClick={() => AddProduct()}>
          บันทึก
        </Button>,
      ]}
    >
      <Row style={{ textAlign: "center" }}>
        <Col>
          <img src={product_image_url} style={{ width: "50%" }} />
          <br />
          <Button className="btn-upload">
            <input
              className="fileImage"
              type="file"
              accept="image/*"
              onChange={(e) => onChange("img", e)}
            />
            อัพโหลดรูปภาพ
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: "1em" }}>
        <Col>
          <Label>ชื่อสินค้า</Label>
          <Input
            size="small"
            value={product_name}
            onChange={(e) => onChange("product_name", e)}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "1em" }}>
        <Col>
          <Label>หมวดหมู่</Label>
          <Select
            showSearch
            className="select-warranty"
            onChange={(value) => this.onChange("category", value)}
          >
            {Array.isArray(category) &&
              category.map((el) => {
                return <Select.Option value={el}>{el}</Select.Option>;
              })}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>สถานะ</Label>
          <Switch
            defaultChecked={product_status === "1" ? true : false}
            onChange={(e) => onChange("product_status", e)}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export const ModalDownloadQR = ({
  isModalVisible,
  handleCancel,
  genQR,
  titleQR,
}) => {
  return (
    <Modal footer={null} visible={isModalVisible} onCancel={handleCancel}>
      <div style={{ marginTop: "2em", textAlign: "center" }}>
        <h2 style={{ marginBottom: "1em" }}>{`ร้าน : ${titleQR.storeName}`}</h2>
        <h3
          style={{ marginBottom: "1em" }}
        >{`สาขา : ${titleQR.branchName}`}</h3>
        <QRCode value={genQR} size={200} id="qrCode" />
        <Input type="hidden" id="name-img" value={titleQR.storeName} />
        <br />
        <br />
        <Button
          className="btn-blue"
          onClick={fn.downloadQR}
          style={{ marginTop: "1em" }}
        >
          <DownloadOutlined /> Download QR
        </Button>
      </div>
    </Modal>
  );
};

export const ModalAddDealer = ({
  openModal,
  closeModal,
  formDataDealer,
  optionTypeStore,
  onChangeForm = () => {},
  handleSelect = () => {},
  handleOnSave = () => {},
  handleSearchStoreNo = () => {},
}) => {
  const style = { paddingRight: "8px" };
  return (
    <Modal
      title={<h2 style={{ marginBottom: "0px" }}>เพิ่มร้านค้า</h2>}
      visible={openModal}
      onCancel={closeModal}
      maskClosable={false}
      footer={
        <Button type="primary" className="btn-save" onClick={handleOnSave}>
          <SaveOutlined /> บันทึก
        </Button>
      }
    >
      <Form name="basic" layout="vertical">
        <Row gutter={24}>
          {Array.isArray(formDataDealer) &&
            formDataDealer.map((val, i) => {
              return (
                <Col span={val.span} style={style} key={i}>
                  <Form.Item
                    label={val.label}
                    name={val.fieldName}
                    style={{
                      marginBottom: val.fieldName === "store_no" ? "0px" : null,
                    }}
                    required
                  >
                    {val.type === "text" ? (
                      <Input
                        size="small"
                        value={val.value}
                        placeholder={val.message}
                        onChange={(e) => onChangeForm("add", val.fieldName, e)}
                        disabled={val.disabled}
                      />
                    ) : val.type === "option" ? (
                      <Select
                        size="large"
                        value={val.value}
                        placeholder={val.message}
                        onChange={handleSelect}
                        disabled={val.disabled}
                      >
                        <Select.Option value={""}>
                          ---กรุณาเลือก---
                        </Select.Option>
                        {optionTypeStore.map((props, s) => {
                          return (
                            <Select.Option value={props.value} key={s}>
                              {props.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : val.type === "search" ? (
                      <>
                        <Input
                          placeholder={val.message}
                          value={val.value}
                          // onSearch={handleSearchStoreNo}
                          onChange={(e) =>
                            onChangeForm("add", val.fieldName, e)
                          }
                          disabled={val.disabled}
                        />

                        <Button
                          type="button"
                          Butt
                          className="btn-search"
                          onClick={() => handleSearchStoreNo(val.value)}
                        >
                          <SearchOutlined />
                        </Button>
                        {val.textAlert ? (
                          <div style={{ textAlign: "right" }}>
                            <span
                              className={
                                val.textAlert == "มีข้อมูลร้านค้านี้"
                                  ? "alert-success"
                                  : "alert-info"
                              }
                            >
                              {val.textAlert}
                            </span>
                          </div>
                        ) : null}
                      </>
                    ) : val.type === "switch" ? (
                      <Switch
                        defaultChecked={val.value == 1 ? true : false}
                        onChange={(e) => onChangeForm("add", val.fieldName, e)}
                        disabled={val.disabled}
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              );
            })}
        </Row>
      </Form>
    </Modal>
  );
};

export const ModalEditDealer = ({
  openModal,
  closeModal,
  formEditDealer,
  onChangeForm = () => {},
  handleOnSave = () => {},
}) => {
  const style = { paddingRight: "8px" };
  return (
    <Modal
      title={<h2 style={{ marginBottom: "0px" }}>แก้ไขร้านค้า</h2>}
      visible={openModal}
      onCancel={closeModal}
      maskClosable={false}
      footer={
        <Button type="primary" className="btn-save" onClick={handleOnSave}>
          <SaveOutlined /> บันทึก
        </Button>
      }
    >
      <Form name="basic" layout="vertical">
        <Row gutter={24}>
          {Array.isArray(formEditDealer) &&
            formEditDealer.map((val, i) => {
              return (
                <Col span={val.span} style={style} key={i}>
                  <Form.Item label={val.label} name={val.fieldName} required>
                    {val.type === "text" ? (
                      <Input
                        size="small"
                        value={val.value}
                        placeholder={val.message}
                        onChange={(e) => onChangeForm("edit", val.fieldName, e)}
                        disabled={val.disabled}
                      />
                    ) : val.type === "switch" ? (
                      <Switch
                        checked={+val.value == 1 ? true : false}
                        onChange={(e) => onChangeForm("edit", val.fieldName, e)}
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              );
            })}
        </Row>
      </Form>
    </Modal>
  );
};
