import React, { Component } from "react";
import { observer } from "mobx-react";
import autobind from "autobind-decorator";
import { PATH_IMAGE, PATH_IMAGE_PAYMENT } from "../../service";
import {
  Row,
  Col,
  Timeline,
  Icon,
  Button as BT,
  Modal,
  Spin,
  Input,
  Form,
  message,
  Select,
  TimePicker,
  DatePicker,
  InputNumber
} from "antd";
import moment from "moment";
import { BtnGoback } from "../../components/Button";
import {
  GET,
  POST,
  UPLOAD,
  GET_DETAIL_CLAIM,
  EDIT_CLAIM_BY_ID,
  FOCUS_GETALLPRODUCT,
  FOCUS_GET_OPTION_CHENNEL_CALIM,
  FOCUS_STORE_BY_TYPE,
  GET_ADDRESS_BY_ZIPCODE,
  GET_PRODUCTS_BY_CATEGORY,
  GET_OPTION_BREAKDOWN,
  VALIDATE_QRCODE,
  UPDATE_PAYMENT,
} from "../../service";
import { color } from "../../resource";
import user from "../../mobx/user";

const fn = require("../../functions/functions");
const styles = {
  font: {
    fontSize: "0.4cm",
    marginTop: 3,
  },
  border: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  border_part1: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    marginTop: 15,
  },
};
@Form.create({ name: "EditClaimScene" })
@observer
class EditClaimScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      claim_doc: query.get("id"),
      loading: true,
      visible: false,
      address: [],
      product: [],
      optionTypeClaim: [],
      optionStore: [],
      optionBranch: [],
      dataStoreAll: [],
      amphoe_option: [],
      district_option: [],
      province_option:[],
      brand_option: [],
      dataAddress: [],
      dataModelByBrand: [],
      district: "",
      amphoe: "",
      province: "",
      breakdown_option: [],
      model_option: [],
      product_id: "",
      pay: {},
      file_pay: "",
    };
  }

  componentDidMount() {
    this.onFetchClaimById();
    this.getOptionType();

    console.log({ user });
  }

  @autobind
  async onFetchClaimById() {
    const { claim_doc } = this.state;
    try {
      let res = await POST(GET_DETAIL_CLAIM, { no_claim: claim_doc });
      let product = await GET(FOCUS_GETALLPRODUCT);

      if (res.success) {
        let data = res.result;
        let pd = product.result;

        this.setState(
          {
            loading: false,
            status: data.user.status,
            ...data.user,
            ...data.address,
            ...data.product,
            // pay: {
            //   bank_pay: data.pay.bank_pay || "ไทยพาณิชย์",
            //   money_pay: data.pay.money_pay || 50,
            //   bill_pay: data.pay.bill_pay || "",
            //   date_pay: data.pay.date_pay && new Date(data.pay.date_pay) || moment(new Date()).format("DD/MM/YYYY"),
            //   time_pay: data.pay.time_pay || moment(new Date()).format("HH:mm:ss"),
            // },
            district: data.address.district,
            amphoe: data.address.amphoe,
            addressId: data.address.address_id,
            no_warranty: data.no_warranty,
            user_id: data.user.user_id,
            item_code: data.product.itemcode,
            dd_product: pd.product,
            dd_brand: pd.brand,
            dd_model: `${pd.itemcode}|${pd.model}`,
            model_name: `${data.product.itemcode || ''}|${data.product.model_name}`,
            type_store: data.claim.type,
            product_id: data.product.product_id,
          },
          () => this.getAddressByZipCode(data.address.zipcode, "default"),
          this.getProductList(data.product.category),
          this.getBreakdown(data.product.product_type),
          data.product.category !== "EC"
            ? this.getBrandByCode(data.product.qrcode, data.product.brand_name)
            : ""
        );
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("editClaim => onFetchClaimById", error.message);
    }
  }

  getAddressByZipCode = async (zipcode, ref) => {
    try {
      const res = await GET(GET_ADDRESS_BY_ZIPCODE(zipcode));
      const { success, result } = res;
      if (success) {
        let amphoe = [];
        let district = [];
        let province = "";
        let province_arr = [];
        result.forEach((el) => {
          amphoe.push({ label: el.amphoe, value: el.amphoe });
          if (amphoe[0].value === el.amphoe) {
            district.push({ label: el.district, value: el.district });
          }
          province_arr.push({ label: el.province, value: el.province });
          //province = el.province;
        });

        if (ref !== "default") {
          this.setState({
            province,
            amphoe: amphoe[0].value,
            district: district[0].value,
          });
        }

        this.setState({
          amphoe_option: fn.removeDuplicates(amphoe),
          district_option: fn.removeDuplicates(district),
          province_option: fn.removeDuplicates(province_arr),
          dataAddress: result,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor(
        "editClaim => getAddressByZipCode",
        error.message
      );
    }
  };

  getBrandByCode = async (qrcode, brandDefault) => {
    try {
      const res = await GET(VALIDATE_QRCODE(qrcode));
      const { brand } = res.result;

      if (res.success) {
        if (brandDefault) {
          const dataFilter = brand.filter(
            (item) => item.brand_name === brandDefault
          );

          const model = dataFilter[0].model.map((val) => {
            return {
              value: `${val.item_code}|${val.model_name}`,
              label: val.model_name,
            };
          });
          this.setState({ model_option: model });
        }

        const option = brand.map((val) => {
          return {
            value: val.brand_name,
            label: val.brand_name,
          };
        });
        this.setState({
          brand_option: option,
          dataModelByBrand: brand,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("editClaim => getBrandByCode", error.message);
    }
  };

  getBreakdown = async (product_type) => {
    try {
      const res = await GET(GET_OPTION_BREAKDOWN(product_type));
      const { success, result } = res;
      if (success) {
        const option = result.map((el) => {
          return { value: el.breakdown, label: el.breakdown };
        });
        this.setState({ breakdown_option: option });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  getProductList = async (category) => {
    try {
      const res = await GET(GET_PRODUCTS_BY_CATEGORY(category));
      if (res.success) {
        this.setState({
          dd_product: res.result.products,
        });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("editClaim => getProductList", error.message);
    }
  };

  @autobind
  async getOptionType() {
    try {
      let res = await GET(FOCUS_GET_OPTION_CHENNEL_CALIM);
      if (res.success) {
        const dataOption = res.result.map((val, i) => {
          return { value: val.type_store, label: val.channel_claim_name };
        });
        this.setState({ optionTypeClaim: dataOption });
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  @autobind
  async getStoreByType(type_store) {
    try {
      let res = await GET(`${FOCUS_STORE_BY_TYPE}/${type_store}`);
      if (res.success) {
        const dataStore = res.result.map((val, i) => {
          return { value: val.store_id, label: val.branch_name };
        });
        this.setState({ optionStore: dataStore, dataStoreAll: res.result });
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  @autobind
  showModal() {
    this.setState({
      visible: true,
    });
  }

  @autobind
  onChange(e, ref) {
    const { dd_product, dataAddress, dataModelByBrand, pay } = this.state;
    const cat = dd_product.filter((item) => item.product_name === e);
    switch (ref) {
      case "product_name":
        this.setState(
          { category: cat[0].category, product_id: cat[0].product_id },
          () => this.getBreakdown(cat[0].product_type)
        );
        break;
      case "zipcode":
        if (e.length === 5) {
          this.getAddressByZipCode(e, "onchange");
        }
        break;
        case "province":
          const amphoe = [];
          dataAddress.forEach((el) => {
            if (el.province === e) {
              amphoe.push({ label: el.amphoe, value: el.amphoe });
            }
          });
          this.props.form.setFieldsValue({
            amphoe: null,
          });
          this.setState({ amphoe_option: fn.removeDuplicates(amphoe), amphoe: null });
          break;
      case "amphoe":
        const district = [];
        dataAddress.forEach((el) => {
          if (el.amphoe === e) {
            district.push({ label: el.district, value: el.district });
          }
        });
        this.setState({ district_option: district, district: "" });
        break;
      case "brand_name":
        let filterBrand = dataModelByBrand.filter((el) => el.brand_name === e);

        const option = filterBrand[0].model.map((el) => {
          return {
            value: `${el.item_code}|${el.model_name}`,
            label: el.model_name,
          };
        });

        this.setState({ model_option: option, model_name: "" });
        break;
        case "money_pay":
          pay.money_pay = e;
          this.setState({ pay });
          break;
        case "date_pay":
          pay.date_pay = e;
          this.setState({ pay });
          break;
        case "time_pay":
          pay.time_pay = moment(e,'HH:mm:ss');
          this.setState({ pay });
          break;
      case "bank_pay":
        pay.bank_pay = e;
          this.setState({ pay });
        break;
      
    }
    this.setState({ [ref]: e });
  }

  _handleImageChange(e, type = "claim") {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        type === "claim"
          ? this.setState({
              file: file,
              url: reader.result,
            })
          : this.setState({
              file_pay: file,
              pay: { ...this.state.pay, bill_pay: reader.result },
            });
      };

      reader.readAsDataURL(file);
    }
  }
  @autobind
  checkValidate() {
    const {
      name,
      telephone,
      province,
      district,
      amphoe,
      type_store,
    } = this.state;
    if (
      !name || !telephone || type_store === "COMPANY"
        ? !province && !district && !amphoe
        : ""
    ) {
      message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
    } else {
      this.onUpdate();
    }
  }

  successReturn(data) {
    this.setState(
      { loading: false },
      () => message.success(data),
      this.goBack()
    );
  }
  @autobind
  async onUpdate() {
    const {
      telephone,
      file,
      province,
      district,
      amphoe,
      zipcode,
      other,
      description,
      note,
      claim_doc,
      no_warranty,
      user_id,
      product_name,
      brand_name,
      model_name,
      claimdate,
      product_id,
      type_store,
      detail2,
      pay,
      file_pay,
    } = this.state;
    const objClaim = {
      telephone,
      file: file || "",
      province,
      district,
      amphoe,
      zipcode,
      other,
      description,
      note,
      claim_doc,
      no_warranty,
      user_id,
      product_name,
      brand_name,
      model_name: model_name.split("|")[1],
      item_code: model_name.split("|")[0],
      claimdate,
      product_id,
      type_store,
      detail2: detail2 || "",
    };

    console.log({model_name});

    const objPayment = {
      no_warranty,
      no_claim:claim_doc,
      tel: telephone,
      userLogin: user.name,
      paymentImage: file_pay,
      ...pay,
      date_pay:moment(pay.date_pay,'DD/MM/YYYY').format('YYYY-MM-DD')|| "",
      time_pay:moment(pay.time_pay,'HH:mm:ss').format('HH:mm')|| "",
    };

    const formDataClaim = fn.appendClaim(objClaim);
    const formDataPayment = fn.appendPayment(objPayment);

    // console.log({ objPayment, objClaim });
    // console.log('time_pay',moment(pay.time_pay,'HH:mm:ss').format('HH:mm'));

   this.setState({ loading: true });

    try {
      let res = await UPLOAD(EDIT_CLAIM_BY_ID, formDataClaim);

      if (res.success) {
        if (file_pay) {
          const r_payment = await POST(UPDATE_PAYMENT, formDataPayment);
          if (r_payment.success) {
            this.successReturn(r_payment.message);
          }
        } else {
          this.successReturn(res.message);
        }
      }
    } catch (error) {
      Modal.error({ title: error.message });
      //return fn.larkNotiMonitor("editClaim => onUpdate", error.message);
    }
  }

  @autobind
  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      dd_product,
      brand_option,
      claim_doc,
      claim_id,
      status,
      visible,
      loading,
      name,
      telephone,
      other,
      url,
      product_name,
      brand_name,
      model_name,
      description,
      note,
      no_warranty,
      category,
      file,
      zipcode,
      province,
      amphoe_option,
      amphoe,
      district_option,
      district,
      model_option,
      breakdown_option,
      claimdate,
      detail2,
      pay,
      file_pay,
      province_option
    } = this.state;

    console.log({pay});

    const { getFieldDecorator } = this.props.form;
    getFieldDecorator("EditClaimScene", { initialValue: [] });
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }

    const uploadButton = (
      <div
        style={{
          width: "6cm",
          height: "7cm",
          marginTop: 20,
          borderColor: "#EFF2F5",
        }}
      ></div>
    );

    return (
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
        onSubmit={this.handleSubmit}
      >
        <div>
          <BtnGoback title={"กลับ"} onClick={() => this.goBack()}></BtnGoback>
          <Modal
            title="ยืนยันการรับงาน"
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={"30vw"}
            cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
            okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
          >
            <p>กรุณากดยืนยันเพื่อรับงาน {claim_id}</p>
          </Modal>
          {/* <Edit address={address.zipcode}></Edit> */}
          {/* #################################   สถานะ  #################################*/}
          <Row>
            <Col span={6} push={18}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#FFFFFF",
                  margin: 15,
                }}
              >
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3>สถานะ</h3>
                </div>
                <div
                  className={"column"}
                  style={{
                    margin: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Timeline>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 1 ? "26px" : "20px",
                            color:
                              status === 1 ? color.focus_waittocheck : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>รอชำระเงิน</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 2 ? "26px" : "20px",
                            color:
                              status === 2 ? color.focus_waittocheck : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>รอการตรวจสอบ</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            fontSize: status === 3 ? "26px" : "20px",
                            color: status === 3 ? color.dplus_main : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>กำลังเตรียมจัดส่ง</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            margintop: 5,
                            fontSize: status === 4 ? "26px" : "20px",
                            color: status === 4 ? "green" : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>จัดส่งเรียบร้อย</font>
                    </Timeline.Item>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="check-circle"
                          style={{
                            margintop: 5,
                            fontSize: status === 99 ? "26px" : "20px",
                            color: status === 99 ? color.focus_reject : "black",
                          }}
                        />
                      }
                      style={{ marginTop: 15 }}
                    >
                      <font className={"text-status"}>ไม่อนุมัติ</font>
                    </Timeline.Item>
                  </Timeline>
                </div>
              </div>
            </Col>
            {/* #################################   ข้อมูลส่วนตัว  #################################*/}
            <Col span={18} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลส่วนตัว</h3>
                </div>

                <div
                  className={"column"}
                  style={{ margin: 15, marginTop: -15, marginLeft: 25 }}
                >
                  <div className={"row-between"} style={{ fontSize: "1cm" }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>ชื่อ-นามสกุล
                    </font>
                    <font style={styles.font}>{name}</font>
                  </div>
                  <div className={"row-between"}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>หมายเลขโทรศัพท์
                    </font>
                    <font style={styles.font}>{telephone}</font>
                  </div>
                  <div className={"row-between"}>
                    <div>
                      <font style={styles.font}>
                        <font style={{ color: "red" }}>* </font>
                        ที่อยู่
                      </font>
                    </div>
                    <div className={"column"}>
                      <div style={{ display: "flex", marginBottom: "1em" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Input
                            style={{ marginRight: 10, width: "95%" }}
                            value={zipcode}
                            placeholder={"รหัสไปรษณีย์"}
                            onChange={(e) =>
                              this.onChange(e.target.value, "zipcode")
                            }
                          />
                          {/* <Input
                            style={{ marginRight: 10, width: "95%" }}
                            value={province}
                            placeholder={"จังหวัด"}
                            disabled
                          /> */}
                           <Select
                            style={{ width: "100%", marginRight: "10px" }}
                            placeholder="จังหวัด"
                            value={province}
                            onChange={(e) => this.onChange(e, "province")}
                          >
                            {province_option.map((item) => (
                              <Select.Option value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginBottom: "1em" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Select
                            style={{ width: "190px", marginRight: "10px" }}
                            placeholder="เขต/อำเภอ"
                            value={amphoe}
                            onChange={(e) => this.onChange(e, "amphoe")}
                          >
                            {amphoe_option.map((item, i) => (
                              <Select.Option value={item.value} key={i}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                          <Select
                            style={{ width: "190px", marginRight: "10px" }}
                            placeholder="แขวง/ตำบล"
                            value={district}
                            onChange={(e) => this.onChange(e, "district")}
                          >
                            {district_option.map((item, i) => (
                              <Select.Option value={item.value} key={i}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"row-between"}>
                    <font style={styles.font}>เพิ่มเติม</font>
                    {/* <font style={styles.font}>{other}</font> */}
                    <Input.TextArea
                      style={{
                        width: "45%",
                        alignItems: "right",
                        marginRight: "10px",
                      }}
                      defaultValue={other}
                      value={other}
                      onChange={(e) => this.onChange(e.target.value, "other")}
                      autosize={{ minRows: 2, maxRows: 2 }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* #################################   button  #################################*/}
          <Row>
            <Col span={6} push={18}></Col>
            {/* #################################   ข้อมูลการแจ้งเคลม  #################################*/}
            <Col span={9} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                  marginTop: -3,
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลการแจ้งเคลม</h3>
                </div>
                <div
                  className={"column"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 35,
                  }}
                >
                  <img
                    src={file ? url : `${PATH_IMAGE}${url}`}
                    alt="new"
                    style={{ width: "6cm", height: "7cm", marginBottom: 20 }}
                  />
                  <input
                    className="fileInput"
                    style={{ marginLeft: 30 }}
                    type="file"
                    onChange={(e) => this._handleImageChange(e)}
                  />
                </div>
              </div>
            </Col>
            {/* #################################   รายละเอียดการเคลม  #################################*/}
            <Col span={9} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                  marginTop: -3,
                }}
              >
                <div style={{ margin: 15, marginLeft: 15 }}>
                  <div className={"row-between"}>
                    <font style={styles.font}>เลขที่การเคลม</font>
                    <font style={styles.font}>{claim_doc}</font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>รหัสลงทะเบียนสินค้า</font>
                    <font style={styles.font}>{no_warranty}</font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>วันที่แจ้งเคลม</font>
                    <font style={styles.font}>
                      {moment(claimdate).format("DD-MM-YYYY HH:mm")}
                    </font>
                  </div>
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>ชื่อสินค้า
                    </font>
                    <Select
                      showSearch
                      style={{ width: "65%" }}
                      placeholder="เลือกสินค้า"
                      optionFilterProp="children"
                      onChange={(e) => this.onChange(e, "product_name")}
                      //onSearch={this.onSearchProduct}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={product_name}
                    >
                      {dd_product.map((item, i) => (
                        <Select.Option value={item.product_name} key={i}>
                          {item.product_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  {category !== "EC" ? (
                    <>
                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={styles.font}>
                          <font style={{ color: "red" }}>* </font>ยี่ห้อ
                        </font>
                        <Select
                          showSearch
                          style={{ width: "65%" }}
                          placeholder="เลือกยี่ห้อ"
                          optionFilterProp="children"
                          onChange={(e) => this.onChange(e, "brand_name")}
                          //onSearch={this.onSearchProduct}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={brand_name}
                        >
                          {brand_option.map((item, i) => (
                            <Select.Option value={item.value} key={i}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <div className={"row-between"} style={{ marginTop: 10 }}>
                        <font style={styles.font}>
                          <font style={{ color: "red" }}>* </font>รุ่น
                        </font>
                        <Select
                          showSearch
                          style={{ width: "65%" }}
                          placeholder="เลือกรุ่น"
                          optionFilterProp="children"
                          onChange={(e) => this.onChange(e, "model_name")}
                          //onSearch={this.onSearchProduct}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={model_name}
                        >
                          {model_option.map((item, i) => (
                            <Select.Option value={item.value} key={i}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={styles.font}>
                      <font style={{ color: "red" }}>* </font>อาการเสีย
                    </font>
                    {/* <font style={styles.font}>{description}</font> */}
                    {/* <Input.TextArea
                      style={{ width: "65%", alignItems: "right" }}
                      defaultValue={description}
                      value={description}
                      onChange={(e) =>
                        this.onChange(e.target.value, "description")
                      }
                      autosize={{ minRows: 1, maxRows: 2 }}
                    /> */}

                    <Select
                      showSearch
                      style={{ width: "65%" }}
                      placeholder="เลือกอาการเสีย"
                      optionFilterProp="children"
                      onChange={(e) => this.onChange(e, "description")}
                      //onSearch={this.onSearchProduct}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={description}
                    >
                      {breakdown_option.map((item, i) => (
                        <Select.Option value={item.value} key={i}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  {description === "อื่นๆ" ? (
                    <div className={"row-between"} style={{ marginTop: 10 }}>
                      <font style={{ ...styles.font }}>โปรดระบุ</font>
                      <Input
                        style={{ width: "65%", alignItems: "right" }}
                        value={detail2}
                        placeholder={"โปรดระบุ"}
                        onChange={(e) =>
                          this.onChange(e.target.value, "detail2")
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {/* เพิ่มช่องทางการเคลม */}
                  <div className={"row-between"} style={{ marginTop: 10 }}>
                    <font style={{ ...styles.font }}>หมายเหตุ</font>
                    <Input.TextArea
                      style={{ width: "65%", alignItems: "right" }}
                      defaultValue={note}
                      value={note}
                      onChange={(e) => this.onChange(e.target.value, "note")}
                      autosize={{ minRows: 1, maxRows: 2 }}
                    />
                  </div>
                  <div
                    className={"column"}
                    style={{
                      marginBottom: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <font style={{ fontSize: "0.5cm", marginTop: 60 }}>
                      แจ้งเคลมมาแล้ว {fn.dateDiff(claimdate)} วัน
                    </font>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={6} push={18}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "flex-end",
                  margin: 15,
                  // height: "11cm",
                  height: "auto",
                  marginTop: 30,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: "30cm",
                  }}
                >
                  <BT
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: color.dplus_main,
                    }}
                    type="primary"
                    onClick={() => this.checkValidate()}
                  >
                    บันทึก
                  </BT>
                </div>
              </div>
            </Col>
            {/* #################################   ข้อมูลส่วนตัว  #################################*/}

            {/* <Col span={18} pull={6}>
              <div
                style={{
                  border: "0.5px",
                  borderStyle: "solid",
                  borderColor: "#CCCCCC",
                  margin: 15,
                }}
              >
                <div className={"column"} style={{ margin: 10 }}>
                  <h3>ข้อมูลการโอนเงิน</h3>
                </div>

                <div
                  style={{
                    display: "flex",
                    margin: 15,
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      marginLeft: 30,
                      marginBottom: 10,
                    }}
                  >
                    <div
                      className={"column"}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 35,
                      }}
                    >
                      {pay.bill_pay ? (
                        <img
                          src={
                            file_pay
                              ? pay.bill_pay
                              : `${PATH_IMAGE_PAYMENT}${pay.bill_pay}`
                          }
                          alt="bill_pay"
                          style={{
                            width: "6cm",
                            height: "7cm",
                            marginBottom: 20,
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                      <input
                        className="fileInput"
                        style={{ marginLeft: 30, marginTop: 5 }}
                        type="file"
                        onChange={(e) => this._handleImageChange(e, "slip")}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>ธนาคาร</font>
                      <Input
                        style={{ width: "50%", alignItems: "right" }}
                        defaultValue={pay.bank_pay || 'ไทยพาณิชย์'}
                        value={pay.bank_pay|| 'ไทยพาณิชย์'}
                        onChange={(e) =>
                          this.onChange(e.target.value, "bank_pay")
                        }
                      />
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>วันที่โอน</font>
                     
                      <DatePicker
                         value={moment(pay.date_pay || new Date(), 'DD/MM/YYYY')}
                        onChange={(date) => this.onChange(date, "date_pay")}
                        format="DD/MM/YYYY"
                      />
                     
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>เวลาที่โอน</font>
                      <TimePicker
                        onChange={(time) => this.onChange(time, "time_pay")}
                        format="HH:mm:ss"
                        value={moment(pay.time_pay || new Date(), "HH:mm:ss")}
                      />
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>จำนวนเงิน</font>
                      <InputNumber
                        style={{ width: "50%", alignItems: "right" }}
                        defaultValue={pay.money_pay || '50'}
                       value={pay.money_pay || '50'}
                        onChange={(e) =>
                          this.onChange(e, "money_pay")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
      </Form>
    );
  }
}

export default EditClaimScene;
