import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import { observer } from "mobx-react";
import autobind from "autobind-decorator";
import { BtnGoback } from "../../components/Button";
import { Font } from "../../styles/styled";
import {
  Icon,
  Input,
  Row,
  Col,
  Button as BT,
  Spin,
  Modal,
  message,
  Select,
} from "antd";
import { color } from "../../resource";
import {
  GET,
  POST,
  GET_DETAIL_CLAIM,
  VALIDATE_QRCODE,
  UPDATE_TRACKING,
  GET_OPTION_SHIPPING,
} from "../../service";
import moment from "moment";
const fn = require("../../functions/functions");
const styles = {
  font: {
    fontSize: "0.4cm",
    marginTop: 3,
    marginBottom: 5,
    marginLeft: 5,
  },
};

@observer
class MultiClaimScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hiddenform: true,
      errordecode: true,
      errorclaim: false,
      claimdoc: "",
      trackingdoc: "",
      decode: "",
      item_code: "",
      claim_colect: "",
      claim_colect_color: "",
      decode_colect: "",
      decode_colect_color: "",
      shipping_date: moment(),
      option_shipping: [],
      shipping_type: "SELF",
      //shipping: "",
      user: {
        name: "",
        telephone: "",
        brandmobile: "",
        modelmobile: "",
      },
      address: {
        address: "",
      },
      product: {
        product_name: "",
      },
    };
  }

  componentDidMount() {
    this.getOptionShipping();
  }

  getOptionShipping = async () => {
    const { shipping_type } = this.state;
    try {
      const res = await GET(GET_OPTION_SHIPPING(shipping_type));
      const { success, result } = res;
      if (success) {
        const option = result.map((el) => {
          return {
            value: `${el.shipping_id}|${el.shipping_name}`,
            label: el.shipping_name,
          };
        });

        this.setState({ option_shipping: option });
      }
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("Claim => getOptionShipping", error.message);
    }
  };

  @autobind
  goBack() {
    this.props.history.goBack();
  }
  @autobind
  onKeyPressSearch(event, type, next_event = "") {
    if (event.key === "Enter") {
      // type === 'claimdoc' ? this.onSearch(next_event) : this.checkWarranty(next_event_)
      switch (type) {
        case "claimdoc":
          this.onSearch(next_event);
          break;
        case "trackingdoc":
          this.refs[next_event].focus();
          break;
        // case "item_code":
        //   this.refs[next_event].focus();
        //   break;
        // case "decode":
        //   this.checkWarranty();
        //   break;
      }
    }
  }
  @autobind
  async confirm() {
    const {
      errordecode,
      errorclaim,
      trackingdoc,
      no_warranty,
      shipping_date,
      user,
      status,
      shipping,
      shipping_type,
    } = this.state;

    if (errordecode || errorclaim || !trackingdoc || !shipping) {
      message.error("กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนการบันทึก");
      return;
    } else if (status !== 3) {
      Modal.error({
        title: "กรุณาตรวจสอบสถานะก่อนการส่งพัสดุ",
        content: "สถานะดังกล่าวไม่สามารถจัดส่งได้",
      });
    } else {
      this.setState({ loading: true });
      try {
        // let res = await POST(FOCUS_UNACTIVE, {
        //   unactive_code: decode,
        //   no_warranty,
        // });
        //  await POST(FOCUS_UPDATEWARRABTY, { no_warranty });

        const obj = {
          status: "TRANSPORTED",
          tracking: trackingdoc,
          shipping_id: shipping.split("|")[0],
          shipping_type,
          shipping_date,
          no_warranty,
          sorting_code: "",
        };

        try {
          let res_tracking = await POST(UPDATE_TRACKING, obj);
          if (res_tracking.success) {
            this.setState({ loading: false, claimdoc: "" });
            this.clear();
            Modal.success({
              title: "บันทึกข้อมูลสำเร็จ",
              content: "ระบบได้ทำการส่งข้อความการจัดส่ง เรียบร้อยแล้ว",
            });
          }
        } catch (error) {
          Modal.error({ title: error.message });
          return fn.larkNotiMonitor("mutiClaim => confirm", error.message);
        }
      } catch (error) {
        Modal.error({ title: error.message });
        return fn.larkNotiMonitor("mutiClaim => confirm", error.message);
      }
    }
  }
  @autobind
  async checkWarranty() {
    const { decode } = this.state;
    let strdecode = decode;
    try {
      if (decode.length >= 58) {
        strdecode = decode.substring(decode.length - 11, decode.length);
      }
      let res = await GET(VALIDATE_QRCODE(strdecode));
      if (res.result === 0) {
        this.setState({
          decode: strdecode,
          errordecode_detail: "ไม่พบรหัสรับประกันสินค้าดังกล่าว",
          decode_colect: "close-circle",
          error_color_decode: "red",
          decode_colect_color: "certain-category-icon-uncolect",
          errordecode: true,
        });
      } else {
        this.setState({
          decode: strdecode,
          errordecode_detail: "รหัสรับประกันใช้งานได้",
          decode_colect: "check-circle",
          error_color_decode: "green",
          decode_colect_color: "certain-category-icon-colect",
          errordecode: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  @autobind
  async onSearch(next_event) {
    const { claimdoc } = this.state;
    let obj = {
      claim_colect: "close-circle",
      claim_colect_color: "certain-category-icon-uncolect",
      error_color_claim: "red",
      errorclaim: true,
    };
    try {
      let res = await POST(GET_DETAIL_CLAIM, { no_claim: claimdoc });

      if (res.success) {
        let { user, address, product, no_warranty, claim } = res.result;

        if (user.status == 3) {
          this.setState({
            user,
            address,
            product,
            status: user.status,
            no_warranty,
            claim_colect: "check-circle",
            claim_colect_color: "certain-category-icon-colect",
            error_color_claim: "green",
            errorclaim_detail: "",
            errorclaim: false,
            status_detail: this.checkstatus(user.status),
            type_claim: claim.type,
            item_code: product.itemcode,
          });
          this.refs[next_event].focus();
        } else {
          Modal.error({
            title: "เลขที่เอกสารนี้ต้องอยู่ในสถานะเตรียมจัดส่งเท่านั้น",
          });
          this.setState({
            ...obj,
          });
        }
      }
    } catch (error) {
      this.setState({
        ...obj,
        errorclaim_detail: "ไม่พบชุดเอกสารดังกล่าว",
      });
    }
  }
  @autobind
  checkstatus(status) {
    switch (status) {
      case 2:
        return "รอการตรวจสอบ";

      case 3:
        return "กำลังเตรียมจัดส่ง";

      case 4:
        return "จัดส่งเรียบร้อย";

      case 99:
        return "ไม่อนุมัติ";
    }
  }
  @autobind
  onChange(e, ref) {
    this.setState({
      [ref]: e,
    });
    if (ref === "claimdoc") {
      this.clear();
    }
  }
  @autobind
  clear() {
    this.setState({
      user: {
        name: "",
        telephone: "",
        brandmobile: "",
        modelmobile: "",
      },
      address: {
        address: "",
      },
      product: {
        product_name: "",
      },
      type_claim: "",
      errorclaim_detail: "",
      claim_colect: "",
      claim_colect_color: "",
      error_color_claim: "",
      errorclaim: false,
      decode_colect: "",
      decode_colect_color: "",
      error_color_decode: "",
      status_detail: "",
      trackingdoc: "",
      decode: "",
      item_code: "",
      errordecode: false,
    });
  }

  render() {
    const {
      hiddenform,
      claimdoc,
      trackingdoc,
      claim_colect,
      user,
      address,
      product,
      claim_colect_color,
      errorclaim,
      errorclaim_detail,
      loading,
      option_shipping,
      shipping,
    } = this.state;
    const theme = {
      hidden: hiddenform,
    };
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Spin />
        </div>
      );
    }

    return (
      <div labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <BtnGoback title={"กลับ"} onClick={() => this.goBack()}></BtnGoback>
        <Row>
          <Col span={14} push={10}>
            <div className={"border-claim"} style={{ margin: 15 }}>
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลเอกสาร</h3>
              </div>

              <ThemeProvider theme={theme}>
                <div
                  className={"column"}
                  style={{ margin: 15, marginTop: -10, marginLeft: 25 }}
                >
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="เลขที่เคลม" />
                    <Font text={claimdoc} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="ชื่อ-นามสกุล" />
                    <Font text={user.name} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="หมายเลขโทรศัพท์" />
                    <Font text={user.telephone} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="ที่อยู่" />
                    <Font text={address.address_name} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="รุ่นสินค้า" />
                    <Font text={product.product_name} />
                  </div>

                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="รุ่นมือถือ" />
                    <Font text={user.brandmobile} />
                  </div>
                  <div className={"row-between"} style={{ marginTop: 15 }}>
                    <Font text="หมายเหตุ" />
                    <Font text={user.note} />
                  </div>
                  {/* <div
                    className={"row-between"}
                    style={{
                      marginTop: 15,
                      marginBottom: 25,
                      color: this.state.status === 4 ? "green" : "",
                    }}
                  >
                    <Font text="สถานะการเคลม" />
                    <Font text={this.state.type_claim} />
                  </div> */}
                  <div
                    className={"row-between"}
                    style={{
                      marginTop: 15,
                      marginBottom: 25,
                      color: this.state.status === 4 ? "green" : "",
                    }}
                  >
                    <Font text="สถานะ" />
                    <Font text={this.state.status_detail} />
                  </div>
                </div>
              </ThemeProvider>
            </div>
          </Col>
          <Col span={10} pull={14}>
            <div className={"border-claim"} style={{ margin: 15 }}>
              <div className={"column"} style={{ margin: 10 }}>
                <h3>ข้อมูลการยิงจ่ายงาน</h3>
              </div>

              <div className={"column-claim-cr"}>
                <font style={styles.font}>
                  <b style={{ color: "red" }}>*</b>เลขที่เอกสาร
                </font>
                <Input
                  suffix={
                    <Icon
                      type={claim_colect}
                      style={{ fontSize: "16px" }}
                      className={claim_colect_color}
                    />
                  }
                  style={{ width: "70%" }}
                  placeholder="เลขที่เอกสาร"
                  value={claimdoc}
                  autoFocus
                  onKeyPress={(e) =>
                    this.onKeyPressSearch(e, "claimdoc", "trackingdoc")
                  }
                  onChange={(e) => this.onChange(e.target.value, "claimdoc")}
                />

                <font
                  style={{
                    fontSize: "0.3cm",
                    color: this.state.error_color_claim,
                    marginTop: 5,
                    marginLeft: 5,
                  }}
                  hidden={!errorclaim}
                >
                  {errorclaim_detail}
                </font>
              </div>
              <div className={"column-claim-cr"} style={{ marginTop: -5 }}>
                <font style={styles.font}>
                  <b style={{ color: "red" }}>*</b>เลขที่พัสดุ
                </font>
                <Input
                  style={{ fontSize: "16px", width: "70%" }}
                  className={claim_colect_color}
                  placeholder="เลขที่พัสดุ"
                  ref="trackingdoc"
                  value={trackingdoc}
                  onKeyPress={(e) =>
                    this.onKeyPressSearch(e, "trackingdoc", "item_code")
                  }
                  onChange={(e) => this.onChange(e.target.value, "trackingdoc")}
                />
              </div>

              <div className={"column-claim-cr"} style={{ marginTop: -5 }}>
                <font style={styles.font}>
                  <b style={{ color: "red" }}>*</b>ขนส่ง
                </font>
                <Select
                  style={{ width: "70%" }}
                  onChange={(value) => this.onChange(value, "shipping")}
                  placeholder="กรุณาเลือกขนส่ง"
                  value={shipping}
                >
                  {option_shipping.map((el, i) => {
                    return (
                      <Select.Option value={el.value}>{el.label}</Select.Option>
                    );
                  })}
                </Select>
              </div>
              {/* <div className={"column-claim-cr"} style={{ marginTop: -5 }}>
                <font style={styles.font}><b style={{ color: "red" }}>*</b>รหัสสินค้า</font>
                <Input
                  style={{ fontSize: "16px", width: "70%" }}
                  className={claim_colect_color}
                  placeholder="รหัสสินค้า"
                  ref="item_code"
                  value={item_code}
                  onKeyPress={(e) =>
                    this.onKeyPressSearch(e, "item_code", "decode")
                  }
                  onChange={(e) => this.onChange(e.target.value, "item_code")}
                />
              </div> */}
              {/* <div className={"column-claim-cr"} style={{ marginBottom: 50 }}>
                <font style={styles.font}>รหัสรับประกันที่ต้องการยกเลิก</font>
                <Input
                  suffix={
                    <Icon
                      type={decode_colect}
                      style={{ fontSize: "16px" }}
                      className={decode_colect_color}
                    />
                  }
                  style={{ width: "70%" }}
                  placeholder="รหัสรับประกัน"
                  value={decode}
                  ref="decode"
                  onKeyPress={(e) => this.onKeyPressSearch(e, "decode")}
                  onChange={(e) => this.onChange(e.target.value, "decode")}
                />
                <font
                  style={{
                    fontSize: "0.3cm",
                    color: this.state.error_color_decode,
                    marginTop: 5,
                    marginLeft: 5,
                  }}
                  hidden={!errordecode}
                >
                  {errordecode_detail}
                </font>
              </div> */}
            </div>
          </Col>
          <Col span={10} pull={14}>
            <div style={{ margin: 15, marginTop: 20 }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignIttracking: "flex-end",
                  justifyContent: "flex-end",
                  margin: 15,
                }}
              >
                <BT
                  style={{
                    width: "10vw",
                    height: "20vw",
                    background: "#FFFFFF",
                  }}
                  onClick={() => this.clear()}
                  hidden={!claimdoc}
                >
                  เคลียร์ข้อมูล
                </BT>
                <BT
                  style={{
                    width: "10vw",
                    height: "20vw",
                    background: color.dplus_main,
                    color: "#FFFFFF",
                  }}
                  onClick={() => this.confirm()}
                >
                  บันทึก
                </BT>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MultiClaimScene;
