import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../components/hoc/WrapperComponents";
import {
  Table,
  Button as BT,
  Modal,
  Row,
  Col,
  Input,
  Icon,
  DatePicker,
  Select,
  Divider,
} from "antd";
import { Label } from "../../styles/styled";
import { UserColumnTable, color } from "../../resource";
import "antd/dist/antd.css";
import {
  GET,
  GET_ALL_USER,
  GET_LEVEL,
  POST,
  UPDATE_USER,
  SEND_MESSAGE_LINE,
} from "../../service";
import "react-image-lightbox/style.css";
import moment from "moment";
import axios from "axios";
const fn = require("../../functions/functions");

@WrapperComponents()
@observer
class UserScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      // visible: false,
      visibleModalEdit: false,
      visibleModalSendLine: false,
      visibleActive: false,
      search: "",
      data: [],
      optionLevel: [],
      dataSet: {},
      message: "",
    };
  }

  componentDidMount() {
    this.onGetAll();
    this.onGetLevel();
  }

  async onGetAll() {
    try {
      this.setState({ isLoadingData: true });

      const search = this.state.search || "";
      const res = await GET(`${GET_ALL_USER}?search=${search}`);
      if (res.success) {
        this.setState({
          data: res.result.users.map((e, i) => {
            return {
              no: i + 1,
              rowKey: i + 1,
              ...e,
            };
          }),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoadingData: false });
    }
  }

  async onGetLevel() {
    try {
      const res = await GET(`${GET_LEVEL}`);
      if (res.success) {
        this.setState({ optionLevel: res.result.option });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // @autobind
  async handleOk() {
    try {
      this.setState({ loading: true });
      const {
        user_id,
        name,
        telephone,
        level,
        birthday,
        line_id,
        line_name,
        telephoneOld,
      } = this.state.dataSet;
      const obj = {
        user_id,
        name,
        telephone,
        level,
        birthday,
        line_id,
        line_name,
        telephoneOld,
      };
      const r = await POST(UPDATE_USER, obj);
      const { success, message } = r;
      if (success) {
        await this.onGetAll();
        this.setState({ visibleModalEdit: false });
        Modal.success({ title: message });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleSendMessage() {
    try {
      this.setState({ loading: true });
      const { line_id } = this.state.dataSet;
      const message = this.state.message;

      if (!line_id) {
        Modal.error({ title: "ยังไม่ได้ OTP ผ่าน LINE" });
        return;
      }

      const r = await POST(SEND_MESSAGE_LINE, { line_id, message });
      if (r.success) {
        this.setState({ visibleModalSendLine: false });
        Modal.success({ title: r.message });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  // @autobind
  onModalEdit(record) {
    this.setState({ visibleModalEdit: true });
    this.setState({ dataSet: record });
  }

  onModalMessage(record) {
    this.setState({ visibleModalSendLine: true });
    this.setState({ dataSet: record, message: "" });
  }

  // @autobind
  onChangeDate(date) {
    const birthday = moment(date).format("YYYY-MM-DD");
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        birthday: !!date ? birthday : null,
      },
    });
  }

  onChange(e) {
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        [e.target.name]: e.target.value,
      },
    });
  }

  onChangeMessage(message) {
    this.setState({ message });
  }

  onSelectLevel(level) {
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        level,
      },
    });
  }

  resetLine = () => {
    Modal.confirm({
      title: "รีเซ็ตไลน์",
      content: `👋 หลังจากดปุ่ม "ตกลง" ต้องกดปุ่มบันทึกอีกครั้งเพิ่มยืนยันการรีเซ็ตไลน์ อีกครั้ง`,
      onOk: async () => {
        this.setState({
          dataSet: {
            ...this.state.dataSet,
            line_id: "",
            line_name: "",
          },
        });
      },
    });
  };

  render() {
    const { data, isLoadingData, dataSet } = this.state;

    return (
      <div>
        <Modal
          title={
            <p style={{ textAlign: "center", marginBottom: "0px" }}>
              <Icon type="user" /> แก้ไขข้อมูล
            </p>
          }
          visible={this.state.visibleModalEdit}
          onOk={() => this.handleOk()}
          onCancel={() => {
            this.setState({ visibleModalEdit: false });
          }}
          width={"35vw"}
          confirmLoading={this.state.loading}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
        >
          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={12}>
              <Label>รหัสลูกค้า</Label>
              <Input disabled value={dataSet.user_id} />
            </Col>
            <Col span={12}>
              <Label>
                ชื่อ <span style={{ color: "red" }}>* </span>
              </Label>
              <Input
                required
                name="name"
                value={dataSet.name}
                onChange={(e) => this.onChange(e)}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={12}>
              <Label>
                เบอร์โทร <span style={{ color: "red" }}>* </span>
              </Label>
              <Input
                required
                value={dataSet.telephone}
                name="telephone"
                maxLength={10}
                onChange={(e) => this.onChange(e)}
              />
            </Col>
            <Col span={12}>
              <Label>
                กลุ่มลูกค้า <span style={{ color: "red" }}>* </span>
              </Label>
              <Select
                required
                onSelect={(e) => this.onSelectLevel(e)}
                value={dataSet.level}
                style={{ width: "100%" }}
              >
                {this.state.optionLevel.map((e, i) => (
                  <Select.Option key={i} value={e.value}>
                    {e.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={12}>
              <Label>วันเกิด</Label>
              <div></div>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="เลือก"
                value={
                  dataSet.birthday && moment(dataSet.birthday, "YYYY-MM-DD")
                }
                format={"YYYY-MM-DD"}
                onChange={(e) => this.onChangeDate(e)}
              />
            </Col>
            <Col span={10}>
              <Label>ไลน์</Label>
              <Input disabled value={dataSet.line_name} />
            </Col>
            <Col span={2}>
              <Label>&nbsp;</Label>
              <BT
                type="primary"
                icon="undo"
                title="รีเซ็ตไลน์"
                disabled={!dataSet.line_name}
                onClick={this.resetLine}
              ></BT>
            </Col>
          </Row>
          <Divider orientation="left">{"ซิงค์ข้อมูลเบอร์เก่า"}</Divider>
          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={12}>
              <Label>เบอร์โทรเดิม</Label>
              <Input
                required
                value={dataSet.telephoneOld}
                name="telephoneOld"
                maxLength={10}
                onChange={(e) => this.onChange(e)}
              />
            </Col>
          </Row>
        </Modal>

        <Modal
          title={
            <p style={{ textAlign: "center", marginBottom: "0px" }}>
              <Icon type="message" /> ส่งข้อความ
            </p>
          }
          visible={this.state.visibleModalSendLine}
          onOk={(e) => this.handleSendMessage(e)}
          onCancel={() => {
            this.setState({ visibleModalSendLine: false });
          }}
          width={"35vw"}
          confirmLoading={this.state.loading}
          cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
          okText={<font style={{ fontSize: "1vw" }}>ส่ง</font>}
        >
          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={24}>
              <p>ชื่อลูกค้า: {`${dataSet.name || "-"}`}</p>
              <Input.TextArea
                style={{
                  width: "100%",
                  alignItems: "right",
                  marginRight: "10px",
                }}
                defaultValue={this.state.message}
                value={this.state.message}
                showCount={true}
                maxLength={500}
                onChange={(e) => this.onChangeMessage(e.target.value)}
                autosize={{ minRows: 5 }}
              />
            </Col>
          </Row>
        </Modal>

        <div className={"column"}>
          {/*###################### HEAD ######################*/}

          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}>
              <Input.Search
                placeholder="ค้นหาข้อมูลลูกค้า"
                name="search"
                onChange={(e) => this.setState({ search: e.target.value })}
                onPressEnter={() => {
                  this.onGetAll();
                }}
                onSearch={() => this.onGetAll()}
                // onKeyPress={()=>{this.onGetAll()}}
              />
            </Col>
            <Col span={1}>
              <BT
                onClick={() => {
                  this.onGetAll();
                }}
                type="primary"
                style={{
                  backgroundColor: color.dplus_primary,
                  borderColor: color.dplus_primary,
                }}
              >
                ค้นหา
              </BT>
            </Col>
          </Row>

          {/*###################### TABLE ######################*/}
          <Table
            style={{ textAlign: "cenetr" }}
            title={() => <h3>ข้อมูลลูกค้า</h3>}
            columns={UserColumnTable(
              (record) => this.onModalEdit(record),
              (record) => this.onModalMessage(record)
            )}
            dataSource={data}
            bordered
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
            pagination={{
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ถึง ${total} รายการ`,
              pageSize: 10,
              total: data.length || 0,
              pageSizeOptions: [10, 25, 50, 100, 500],
              defaultPageSize: 10,
              defaultCurrent: 1,
            }}
          />
        </div>
      </div>
    );
  }
}

export default UserScene;
