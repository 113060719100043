import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../components/hoc/WrapperComponents";
import { Table, Button as BT, Modal, Row, Col, Input, DatePicker } from "antd";
import { ColumnPrivilegeScene, color } from "../../resource";
import {
  GET,
  GET_PROMOTION
} from "../../service";
import "react-image-lightbox/style.css";
import moment from "moment";
const page_name = 'สิทธิพิเศษ'


@WrapperComponents()
@observer
class CouponScene extends Component {
  
  constructor(props) {
    
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      visible: false,
      visibleActive: false,
      search: '',
      data: [],
      optionLevel: [],
      dataSet: {}
    }
  }

  componentDidMount() {
    this.onGetAll()
  }

  async onGetAll(){
    try {

      this.setState({ isLoadingData: true})

      const search = this.state.search || ""

      const res = await GET(`${GET_PROMOTION}?search=${search}`);
      if (res.success) {
        this.setState({ 
          data: res.result.data.map( (e, i) => {
            return {
              no: i+1,
              ...e
            }
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
    finally{
      this.setState({ isLoadingData: false})
    }
  }

  onEdit(record){
    this.setState({
      action: 'EDIT',
      title: 'แก้ไขคำสั่งซื้อ',
      btnOK: 'แก้ไข',
      visible: true
     })
    this.setState({ dataSet: record })
  }

  onAdd = () => {
    this.setState({ 
      action: 'ADD',
      title: 'เพิ่ม',
      btnOK: 'บันทึก',
      visible: true
    })
    this.setState({ 
      dataSet: {} 
    })
  }


  renderExpand = (e) => {
    const columns = [
      {
        title: 'ชื่อคูปอง',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'ประเภท',
        dataIndex: 'coupon_type',
        key: 'coupon_type',
      },
      {
        title: 'รูป',
        dataIndex: 'image',
        key: 'image',
      },
      {
        title: 'ขั้นต่ำ',
        dataIndex: 'min',
        key: 'min',
      },
      {
        title: 'จำนวน',
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: 'วันที่เริ่ม',
        dataIndex: 'start_date',
        key: 'start_date',
      },
      {
        title: 'วันที่สิ้นสุด',
        dataIndex: 'end_date',
        key: 'end_date',
      },
      {
        title: 'รหัสสินค้า',
        dataIndex: 'ref_item',
        key: 'ref_item',
      }

      
      
      
    ]
      
    const data = e.detail.map((e, i) => { 
                        return {
                          no: i + 1, 
                          ...e 
                        }
                      }); 


    return <Table columns={columns} dataSource={data} pagination={false} />;
  
  }
  
  render() {
    const {
      data,
      isLoadingData,
    } = this.state;

    return (
      <div>
        <div className={"column"}>
            {/*###################### HEAD ######################*/}

            <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}> 
              <Input.Search
                  placeholder="ค้นหารายการ" 
                  name="search" 
                  onChange={e => this.setState({ search: e.target.value })}
                  onPressEnter={()=>{this.onGetAll()}}
                  onSearch={() => this.onGetAll()}
                  // onKeyPress={()=>{this.onGetAll()}}
                 
                  />  
            </Col>
            <Col span={1}><BT 
                  onClick={()=>{this.onGetAll()}}
                  type="primary"
                  style={{
                    backgroundColor: color.dplus_primary,
                    borderColor: color.dplus_primary,
                  }}
                  >ค้นหา</BT></Col>
          </Row>


          {/*###################### TABLE ######################*/}

          <Table
            style={{ textAlign: "cenetr" }}
            title={() => 
                    <div className={"row-between"}>
                    <h3>{page_name}</h3>  
                    {/* <BT 
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#fff",
                        backgroundColor: "#007bff",
                        borderColor: "#007bff",
                        alignItems: "center",
                      }}
                    onClick={this.onAdd} 
                      > {'เพิ่ม'}
                    </BT>  */}
                    </div>
                  }
            expandedRowRender={(record) => this.renderExpand(record)}
            columns={ ColumnPrivilegeScene (
              (record) => this.onEdit(record)
            )}
            dataSource={data}
            bordered
            pagination={{ pageSize: 10 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
          />
        </div>
      </div>
    );
  }

}

export default CouponScene;
