import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../components/hoc/WrapperComponents";
import { Table, Button as BT, Modal, Row, Col, Input, DatePicker } from "antd";
import { ColumnReportAllCoupon, color } from "../../resource";
import {
  GET,
  GET_REPORT_ALL_COUPON
} from "../../service";
import "react-image-lightbox/style.css";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
const page_name = 'รายงาน / คูปองทั้งหมด'


@WrapperComponents()
@observer
class AllCouponScene extends Component {
  
  constructor(props) {
    
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      visible: false,
      visibleActive: false,
      search: '',
      data: [],
      optionLevel: [],
      dataSet: {}
    }
  }

  componentDidMount() {
    this.onGetAll()
  }

  async onGetAll(){
    try {

      this.setState({ isLoadingData: true})

      const search = this.state.search || ""

      const res = await GET(`${GET_REPORT_ALL_COUPON}?search=${search}`);
      if (res.success) {
        this.setState({ 
          data: res.result.data.map( (e, i) => {
            return {
              no: i+1,
              ...e
            }
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
    finally{
      this.setState({ isLoadingData: false})
    }
  }


  onExcel = () => {
      const fileName = `${moment().format("YYYYMMDD_mmss")}`
      const column = ColumnReportAllCoupon().map(e => e.title)
      // const field = ColumnReportUsedCoupon().map(e => e.dataIndex)
      const data = this.state.data.map(e => {
        return {
          no: e.no,
          title: e.title,
          title: e.product_name,
          coupon_type_desc: e.coupon_type_desc,
          qty: e.qty,
          used: e.used
        }
      })

      const datas = [{
          sheetData: data,
          sheetName: ['รายงาน'],
          sheetHeader: column
        }
      ]
      const toExcel = new ExportJsonExcel({ fileName, datas });
      toExcel.saveExcel();
  }

  render() {
    const {
      data,
      isLoadingData,
    } = this.state;

    return (
      <div>

        

        <div className={"column"}>
            {/*###################### HEAD ######################*/}

            <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}> 
              <Input.Search
                  placeholder="ค้นหารายการ" 
                  name="search" 
                  onChange={e => this.setState({ search: e.target.value })}
                  onPressEnter={()=>{this.onGetAll()}}
                  onSearch={() => this.onGetAll()}
                  // onKeyPress={()=>{this.onGetAll()}}
                 
                  />  
            </Col>
            <Col span={1}><BT 
                  onClick={()=>{this.onGetAll()}}
                  type="primary"
                  style={{
                    backgroundColor: color.dplus_primary,
                    borderColor: color.dplus_primary,
                  }}
                  >ค้นหา</BT></Col>
          </Row>


          {/*###################### TABLE ######################*/}

          <Table
            style={{ textAlign: "cenetr" }}
            title={() => 
                    <div className={"row-between"}>
                    <h3>{page_name}</h3>  
                    <BT 
                      disabled={ this.state.data.length === 0 ? true : false }
                      style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: this.state.data.length && "#fff",
                          backgroundColor: this.state.data.length > 0 && "#4caf50",
                          borderColor: this.state.data.length > 0 && "#4caf50",
                          alignItems: "center",
                          
                      }}
                      onClick={this.onExcel} 
                        > {'Export excel'}
                      </BT> 
                    </div>
                  }
            columns={ ColumnReportAllCoupon (
              (record) => this.onEdit(record)
            )}
            dataSource={data}
            bordered
            pagination={{ pageSize: 10 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
          />
        </div>
      </div>
    );
  }

}

export default AllCouponScene;
