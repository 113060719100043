export const formAddDealer = [
  {
    label: "รหัสร้านค้า",
    fieldName: "store_no",
    type: "search",
    span: 12,
    required: true,
    message: "กรอกรหัสร้านค้า",
    textAlert: "",
    requiredMessage: "กรุณากรอกรหัสร้านค้า",
    value: "",
  },
  {
    label: "ชื่อร้านค้า",
    fieldName: "store_name",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกชือร้านค้า",
    requiredMessage: "กรุณากรอกชือร้านค้า",
    value: "",
    disabled: true,
  },
  {
    label: "สาขา",
    fieldName: "branch_name",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกสาขา",
    requiredMessage: "กรุณากรอกสาขา",
    value: "",
    disabled: true,
  },
  {
    label: "รหัส Sale",
    fieldName: "sale_no",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกรหัส Sale",
    requiredMessage: "กรุณากรอกรหัส Sale",
    value: "",
    disabled: true,
  },
  {
    label: "เบอร์โทร",
    fieldName: "store_tel",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกเบอร์โทรศัพท์ 10 หลัก",
    requiredMessage: "กรุณากรอกเบอร์โทรศัพท์",
    value: "",
    disabled: true,
  },

  {
    label: "ประเภท",
    fieldName: "type_store",
    type: "option",
    span: 12,
    required: true,
    message: "กรุณาเลือกประเภท",
    requiredMessage: "กรุณาเลือกประเภท",
    value: "",
    disabled: true,
  },
  {
    label: "สถานะ",
    fieldName: "store_status",
    type: "switch",
    span: 12,
    required: true,
    message: "",
    value: 1,
    disabled: true,
  },
  {
    label: "",
    fieldName: "branch_no",
    type: "hidden",
    span: 12,
    required: false,
    message: "",
    value: 1,
  },
];

export const formEditDealer = [
  {
    label: "รหัสร้านค้า",
    fieldName: "store_no",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกรหัสร้านค้า",
    textAlert: "",
    requiredMessage: "กรุณากรอกรหัสร้านค้า",
    value: "",
    disabled: true,
  },
  {
    label: "ชื่อร้านค้า",
    fieldName: "store_name",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกชือร้านค้า",
    requiredMessage: "กรุณากรอกชือร้านค้า",
    value: "",
    disabled: true,
  },
  {
    label: "สาขา",
    fieldName: "branch_name",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกสาขา",
    requiredMessage: "กรุณากรอกสาขา",
    value: "",
    disabled: true,
  },
  {
    label: "ประเภท",
    fieldName: "type_store",
    type: "text",
    span: 12,
    required: true,
    message: "กรุณาเลือกประเภท",
    requiredMessage: "กรุณาเลือกประเภท",
    value: "",
    disabled: true,
  },
  {
    label: "เบอร์โทร",
    fieldName: "store_tel",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกเบอร์โทรศัพท์ 10 หลัก",
    requiredMessage: "กรุณากรอกเบอร์โทรศัพท์",
    value: "",
  },
  {
    label: "รหัส Sale",
    fieldName: "sale_no",
    type: "text",
    span: 12,
    required: true,
    message: "กรอกรหัส Sale",
    requiredMessage: "กรุณากรอกรหัส Sale",
    value: "",
  },

  {
    label: "สถานะ",
    fieldName: "store_status",
    type: "switch",
    span: 24,
    required: true,
    message: "",
    value: "",
  },
  {
    label: "",
    fieldName: "store_id",
    type: "hidden",
    span: 12,
    required: false,
    message: "",
    value: "",
  },
];

export const formFilterDealer = [
  {
    label: "รหัสร้านค้า",
    fieldName: "store_no",
    span: 8,
    value: "",
  },
  {
    label: "ชื่อร้านค้า",
    fieldName: "store_name",
    span: 8,
    value: "",
  },
];

export const radioTracking = [
  {
    label: "จัดส่งเอง",
    value: "SELF",
  },
  {
    label: "ระบบจัดส่ง",
    value: "SYSTEM",
  },
];
