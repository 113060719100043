import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../components/hoc/WrapperComponents";
import { Table, Button as BT, Modal, Row, Col, Input, DatePicker } from "antd";
import { ColumnReportRegisterSuperMember, color } from "../../resource";
import {
  GET,
  GET_REPORT_SUPER_MEMBER
} from "../../service";
import "react-image-lightbox/style.css";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
const page_name =  'รายงาน / สมัครเมมเบอร์'


@WrapperComponents()
@observer
class RegisterSuperMember extends Component {
  
  constructor(props) {
    
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      visible: false,
      visibleActive: false,
      search: '',
      data: [],
      optionLevel: [],
      dataSet: {},
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD')
    }
  }

  componentDidMount() {
    this.onGetAll()
  }

  async onGetAll(){
    try {

      this.setState({ isLoadingData: true})

      const search = this.state.search || ""
      const { start_date, end_date } = this.state

      const res = await GET(`${GET_REPORT_SUPER_MEMBER}?search=${search}&start_date=${start_date}&end_date=${end_date}`);
      if (res.success) {
        this.setState({ 
          data: res.result.data.map( (e, i) => {
            return {
              no: i+1,
              ...e
            }
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
    finally{
      this.setState({ isLoadingData: false})
    }
  }

  onChangeRangePicker(dates) {
    const start_date = moment(dates[0]).format("YYYY-MM-DD");
    const end_date = moment(dates[1]).format("YYYY-MM-DD");
    this.setState({ start_date, end_date } );
  }

  onExcel = () => {
      const fileName = `${moment().format("YYYYMMDD_mmss")}`
      const column = ColumnReportRegisterSuperMember().map(e => e.title)
      // const field = ColumnReportRegisterSuperMember().map(e => e.dataIndex)
      const data = this.state.data.map(e => {
        return {
          no: e.no,
          channel_order_desc: e.channel_order_desc,
          inv_no: e.inv_no,
          description: e.description,
          name: e.name,
          telephone: e.telephone,
          order_date: e.order_date,
        }
      })

      const datas = [{
          sheetData: data,
          sheetName: ['รายงาน'],
          sheetHeader: column
        }
      ]
      const toExcel = new ExportJsonExcel({ fileName, datas });
      toExcel.saveExcel();
  }

  render() {
    const {
      data,
      isLoadingData,
    } = this.state;

    return (
      <div>

        

        <div className={"column"}>
            {/*###################### HEAD ######################*/}

            <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}> 
              <Input.Search
                  placeholder="ค้นหารายการ" 
                  name="search" 
                  onChange={e => this.setState({ search: e.target.value })}
                  onPressEnter={()=>{this.onGetAll()}}
                  onSearch={() => this.onGetAll()}
                  // onKeyPress={()=>{this.onGetAll()}}
                 
                  />  
            </Col>
            <Col span={6}> 
            <DatePicker.RangePicker
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(this.state.start_date),
                  moment(this.state.end_date)
                ]}
                onChange={(e) => this.onChangeRangePicker(e)}
                size="default"
              />
              </Col>
            <Col span={1}><BT 
                  onClick={()=>{this.onGetAll()}}
                  type="primary"
                  style={{
                    backgroundColor: color.dplus_primary,
                    borderColor: color.dplus_primary,
                  }}
                  >ค้นหา</BT></Col>
          </Row>


          {/*###################### TABLE ######################*/}

          <Table
            style={{ textAlign: "cenetr" }}
            title={() => 
                    <div className={"row-between"}>
                    <h3>{page_name}</h3>  
                    <BT 
                      disabled={ this.state.data.length === 0 ? true : false }
                      style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: this.state.data.length && "#fff",
                          backgroundColor: this.state.data.length > 0 && "#4caf50",
                          borderColor: this.state.data.length > 0 && "#4caf50",
                          alignItems: "center",
                          
                      }}
                      onClick={this.onExcel} 
                        > {'Export excel'}
                      </BT> 
                    </div>
                  }
            columns={ ColumnReportRegisterSuperMember (
              (record) => this.onEdit(record)
            )}
            dataSource={data}
            bordered
            pagination={{ pageSize: 10 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
          />
        </div>
      </div>
    );
  }

}

export default RegisterSuperMember;
