import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../components/hoc/WrapperComponents";
import { Table, Button as BT, Modal, Row, Col, Input, Icon, DatePicker, Select, InputNumber, Upload, Form  } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { Label } from "../../styles/styled";
import { ColumnTableOrderList, color } from "../../resource";
import {
  GET,
  GET_ALL_ORDER,
  GET_CHANNEL_ORDER,
  GET_ORDER_STATUS,
  POST,
  UPLOAD,
  ADD_ORDER,
  EDIT_ORDER,
  GET_OPTION_PROMOTION,
  ip
} from "../../service";
import "react-image-lightbox/style.css";
import moment from "moment";
import { ModalEMS, ModalActive } from "../../components/Modal";
import Lightbox from "react-image-lightbox";


// const fn = require("../../functions/functions");

@WrapperComponents()
@observer
class OrderListScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoadingData: false,
      visible: false,
      visibleActive: false,
      action: '',
      title: '',
      btnOK: '',
      search: '',
      data: [],
      optionChannelBuy: [],
      optionOrderStatus: [],
      optionPromotion: [],
      dataSet: {},
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
      selectedFile: {},
      imageUrl: '',
      isOpen: false,
    }
  }

  componentDidMount() {
    this.onGetAll()
    this.onGetChannelOrder()
    this.onGetOrderStatus()
    this.onGetPromotion()
  }

  async onGetAll(){
    try {

      this.setState({ isLoadingData: true})

      const search = this.state.search || ""
      const { start_date, end_date } = this.state

      const res = await GET(`${GET_ALL_ORDER}?search=${search}&start_date=${start_date}&end_date=${end_date}`);
      console.log({res});
      if (res.success) {
        this.setState({ 
          data: res.result.orders.map( (e, i) => {
            return {
              ...e,
              no: i+1,
              inv_image: `${ip}${e.inv_image}`
            }
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
    finally{
      this.setState({ isLoadingData: false})
    }
  }

  async onGetChannelOrder(){
    try {

      const res = await GET(`${GET_CHANNEL_ORDER}`);
      if (res.success) {
          this.setState({ optionChannelBuy: res.result.option })
      }

    } catch (error) {
      console.log(error)
    }
    
  }

  async onGetOrderStatus(){
    try {

      const res = await GET(`${GET_ORDER_STATUS}`);
      if (res.success) {
          this.setState({ optionOrderStatus: res.result.option })
      }

    } catch (error) {
      console.log(error)
    }
    
  }

  async onGetPromotion(){
    try {

      const res = await GET(`${GET_OPTION_PROMOTION}`);
      if (res.success) {
          this.setState({ optionPromotion: res.result.option })
      }

    } catch (error) {
      console.log(error)
    }
    
  }

  async handleOk(e){
    try {

        const { action } = this.state
        if(!action){
          Modal.error({ title: "NO ACTION" })
        }
        this.setState({ loading: true })
        let r = {}

        const { dataSet, selectedFile } = this.state

        let data = new FormData()
        
        data.append("inv_no", dataSet.inv_no)
        data.append("telephone", dataSet.telephone)
        data.append("channel_order", dataSet.channel_order)
        data.append("promotion_id", dataSet.promotion_id)
        data.append("order_status", dataSet.order_status)
        data.append("order_date", dataSet.order_date)
        data.append("qty", dataSet.qty)
        data.append("remark", dataSet.remark)
        data.append("inv_image", selectedFile)

        if(action === 'ADD'){
          r = await UPLOAD(ADD_ORDER, data)
        }else{
          data.append("id", dataSet.id)
          r = await UPLOAD(EDIT_ORDER, data)
        }
        
        const { success, message } = r
        if(success){
          await this.onGetAll()
          Modal.success({ title: message })
        }
        

    } catch (error) {
      Modal.error({ title: error.message })
    }
    finally{
      this.setState({ loading: false, visible: false, imageUrl: '' })
    }
   
  }

  onEdit(record){
    this.setState({
      action: 'EDIT',
      title: 'แก้ไขคำสั่งซื้อ',
      btnOK: 'แก้ไข',
      visible: true,
      imageUrl: record.inv_image
     })
    this.setState({ dataSet: record })
  }

  onAdd = () => {
    this.setState({ 
      action: 'ADD',
      title: 'เพิ่มคำสั่งซื้อ',
      btnOK: 'บันทึก',
      visible: true,
      imageUrl: ''
    })
    this.setState({ 
      dataSet: { 
        qty: 1, 
        order_date: moment().format("YYYY-MM-DD"),
        order_status: 'WAIT_USE'
    } })
  }
  
  onChangeDate(date){
    const order_date = moment(date).format("YYYY-MM-DD")
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        order_date
      }
    })
  }

  onChange(e){
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        [e.target.name]: e.target.value
      }
    })
  }

  onSelect(obj){
    this.setState({
      dataSet: {
        ...this.state.dataSet,
        [obj.e.props.name]: obj.v
      }
    })
  }

  onChangeRangePicker(dates) {
    const start_date = moment(dates[0]).format("YYYY-MM-DD");
    const end_date = moment(dates[1]).format("YYYY-MM-DD");
    this.setState({ start_date, end_date } );
  }

  handleChange = (info) => {

    if(info.file.status){

      const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
      if (!isJpgOrPng) {
        Modal.warning({ title: 'ไฟล์รูปต้องเป็น JPG/PNG เท่านั้น!' })
        return
      }
      const isLt2M = info.file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        Modal.warning({ title: 'Image must smaller than 2MB!' })
        return
      }


      this.getBase64(info.file.originFileObj, (url) => {
       this.setState({ imageUrl: url })
      })

      this.setState({ 
        selectedFile: info.file.originFileObj
      })
    }


    console.log("selectedFile", this.state.selectedFile);
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  clickImage(src) {

    this.setState({
      zoomImage: `${src}`,
      isOpen: true,
    });
  }

  render() {
    const {
      data,
      isLoadingData,
      isOpen
    } = this.state;

    return (
      <div>
        <Modal
            title={
              <p style={{ textAlign: "center", marginBottom: "0px" }}>
                <Icon type='shopping' /> {this.state.title}
              </p>
            }
            visible={this.state.visible}
            onOk={()=> this.handleOk()}
            onCancel={()=> { this.setState({ visible: false }) }}
            width={"35vw"}
            confirmLoading={this.state.loading}
            cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
            okText={<font style={{ fontSize: "1vw" }}>{this.state.btnOK}</font>}
          >

        <Row style={{ marginBottom: "1em" }} gutter={16}> 
          <Col span={12} >
            <Label>เลขคำสั่งซื้อ <span style={{ color: "red" }}>* </span></Label>
            <Input
              disabled={this.state.action === 'EDIT' ? true : false }
              required
              name="inv_no"
              value={this.state.dataSet.inv_no}
              onChange={(e) => this.onChange(e)}
            />
          </Col>
          <Col span={12}>
            <Label>เบอร์โทร <span style={{ color: "red" }}>* </span></Label>
            <Input 
              required
              value={this.state.dataSet.telephone}
              name="telephone"
              maxLength={10}
              onChange={(e) => this.onChange(e)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "1em" }} gutter={16}>
          <Col span={24}>
            <Label>โปรโมชั่น <span style={{ color: "red" }}>* </span></Label>
            <Select  
                required
                onSelect={((v, e) => this.onSelect({ v, e }))}
                placeholder="เลือก"
                value={this.state.dataSet.promotion_id}
                style={{ width: '100%'}}>
                {this.state.optionPromotion.map((e, i) => (
                  <Select.Option name={'promotion_id'} key={i+1} value={e.value}>{e.label}</Select.Option>
                ))}
              </Select>
          </Col>
     
        </Row>
        <Row style={{ marginBottom: "1em" }} gutter={16}>
          <Col span={12}>
            <Label>ช่องทาง <span style={{ color: "red" }}>* </span></Label>
            <Select  
                required
                onSelect={((v, e) => this.onSelect({ v, e }))}
                value={this.state.dataSet.channel_order}
                style={{ width: '100%'}}>
                {this.state.optionChannelBuy.map((e, i) => (
                  <Select.Option name={'channel_order'} key={i} value={e.value}>{e.label}</Select.Option>
                ))}
              </Select>
          </Col>
          <Col span={12}>
            <Label>สถานะ <span style={{ color: "red" }}>* </span></Label>
              <Select  
                required
                onSelect={((v, e) => this.onSelect({ v, e }))}
                value={this.state.dataSet.order_status}
                disabled={this.state.action === 'ADD' ? true : false}
                style={{ width: '100%'}}>
                {this.state.optionOrderStatus.map((e, i) => (
                  <Select.Option name={'order_status'} key={i} value={e.value}>{e.label}</Select.Option>
                ))}
              </Select>
           
          </Col>
        </Row>


        <Row style={{ marginBottom: "1em" }} gutter={16}>
          <Col span={12}>
            <Label>วันที่ซื้อ <span style={{ color: "red" }}>* </span></Label>
            <div></div>
            <DatePicker
              style={{ width: '100%'}}
              placeholder='เลือก'
              value={ this.state.dataSet.order_date && moment(this.state.dataSet.order_date, 'YYYY-MM-DD')} 
              format={'YYYY-MM-DD'} 
              onChange={(e)=> this.onChangeDate(e)}
              />
          </Col>
          <Col span={12}>
            <Label>จำนวน <span style={{ color: "red" }}>* </span></Label>

            <InputNumber 
              required
              min={1} 
              max={100} 
              value={this.state.dataSet.qty || 1}
              style={{ width: '100%'}}
              onChange={e => this.onChange({ target: { name: 'qty', value: e } })} 
            />
          </Col>
        
    
        </Row>

        <Row style={{ marginBottom: "1em" }} gutter={16}>
        <Col span={24}>
            <Label>สลิปโอนเงิน <span style={{ color: "red" }}>* </span></Label>
            <div></div>
          <Upload 
            name="inv_image"
            required
            fileList={[]}
            listType="picture-card"
            accept={"image/jpeg,image/png"}
            customRequest={this.dummyRequest}
            onChange={this.handleChange}
            
          >
          {this.state.imageUrl ? (
                  <img
                    src={this.state.imageUrl}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  <>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                  </>
                )}
          </Upload>
          </Col>
        </Row>

        <Row style={{ marginBottom: "1em" }} gutter={16}>
        <Col span={24}>
            <Label>หมายเหตุ</Label>
            <div></div>
            <Input 
              value={this.state.dataSet.remark}
              name="remark"
              onChange={(e) => this.onChange(e)}
            />
          </Col>
        </Row>

        </Modal>

        {isOpen && (
          <Lightbox
            mainSrc={this.state.zoomImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}

        <div className={"column"}>
          {/*###################### HEAD ######################*/}

          <Row style={{ marginBottom: "1em" }} gutter={16}>
            <Col span={6}> 
              <Input.Search
                  placeholder="ค้นหารายการ" 
                  name="search" 
                  onChange={e => this.setState({ search: e.target.value })}
                  onPressEnter={()=>{this.onGetAll()}}
                  onSearch={() => this.onGetAll()}
                  />  
            </Col>
            <Col span={6}> 
            <DatePicker.RangePicker
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(this.state.start_date),
                  moment(this.state.end_date)
                ]}
                onChange={(e) => this.onChangeRangePicker(e)}
                size="default"
              />
              </Col>
            <Col span={1}><BT 
                  onClick={()=>{this.onGetAll()}}
                  type="primary"
                  style={{
                    backgroundColor: color.dplus_primary,
                    borderColor: color.dplus_primary,
                  }}
                  >ค้นหา</BT></Col>
          </Row>
 

          {/*###################### TABLE ######################*/}
          {/* <div className={"row-between"}>
         
              </div> */}
          <Table
            style={{ textAlign: "cenetr" }}
            title={() => 
              <div className={"row-between"}>
              <h3>รายการสั่งซื้อ</h3>  
              <BT 
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "#fff",
                    backgroundColor: "#007bff",
                    borderColor: "#007bff",
                    alignItems: "center",
                  }}
                onClick={this.onAdd} 
                  > {'เพิ่ม'}
                </BT> 
              </div>
                  }
            columns={ ColumnTableOrderList (
              (record) => this.onEdit(record),
              (record) => this.clickImage(record)
            )}
            dataSource={data}
            bordered
            pagination={{ pageSize: 10 }}
            loading={isLoadingData}
            size="small"
            scroll={{ x: true }}
          />
        </div>
      </div>
    );
  }

}

export default OrderListScene;
