export const image = {
  rizz_logo: require("./logo_ablemen.png"),
  brandblack: require("./logo_ablemen.png"),
  brandwhite: require("./logo_ablemen.png"),
  calendar: require("./calendar.png"),
  logo_brand: require("./logo_ablemen.png"),
  focus: require("./logo_ablemen.png"),
  logo_focusform: require("./logo_ablemen.png"),
  logo_brand: require("./logo_ablemen.png"),
  ablemen_brand_black: require("./logo_ablemen_black.png"),
  fa_ablemen: require("./fa_ablemen.png"),
  logo_jt: require("./logo_jt.jpg"),
};
