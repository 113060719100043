import { observable, action, computed } from "mobx";
export const KEY = "DkdL8OVPdp";
class User {
  @observable role = "";
  @observable name = "";
  @observable accessToken = "";
  @observable auth = false;
  @observable loading = true;

  constructor() {
    this.initial();
  }

  @action
  async initial() {
    try {
      const res = await localStorage.getItem(KEY);
      if (res) {
        const obj = JSON.parse(res);
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const element = obj[key];
            key === "accessToken" && console.log(element);
            this[key] = element;
          }
        }
        this.auth = true;
      }

      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async login(result) {
    try {
      await localStorage.setItem(
        KEY,
        JSON.stringify({
          role: result.role,
          name: result.name,
          accessToken: result.accessToken,
        })
      );
console.log({result});

      this.accessToken = result.accessToken;
      this.role = result.role;
      this.name = result.name;
      this.auth = true;
    } catch (error) {
      console.log(error);
    }
  }

  @computed
  get getRole() {
    return this.role;
  }

  @action
  async logout() {
    try {
      await localStorage.removeItem(KEY);
      await localStorage.removeItem("token");
      this.auth = false;
      this.role = "";
      this.name = "";
      this.accessToken = "";
    } catch (error) {
      console.log(error);
    }
  }

  @computed
  get getLoading() {
    return this.loading;
  }
}

export default new User();
