import React, { Component } from "react";
import { observer } from "mobx-react";
import { Table, Input, DatePicker, Button as BT, Modal } from "antd";
import { ColumnTableWarranty, color } from "../../resource";
import autobind from "autobind-decorator";
import moment from "moment";
import { POST, GET_ALL_WARRANTY, PATH_IMAGE, ip } from "../../service";
import dayjs from "dayjs";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import ExportJsonExcel from "js-export-excel";
const fn = require("../../functions/functions");
let defaultData = [];

@observer
class WarrantyScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isLoadingData: true,
      searchText: "",
      start_date: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      fullTextSearch: "",
      user_role:
        !!JSON.parse(localStorage.getItem("DkdL8OVPdp")) &&
        JSON.parse(localStorage.getItem("DkdL8OVPdp")).role,
        isOpen: false,
    };
  }

  componentDidMount() {
    this.onFetchWarranty("");
  }

  @autobind
  async onFetchWarranty(fullSearch) {
    const { user_role } = this.state;
    try {
      this.setState({ isLoadingData: true })
      const start = moment(new Date());
      let obj = {
        date_start: this.state.start_date,
        date_end: this.state.end_date,
        search: fullSearch === "" ? "" : fullSearch,
        role: user_role,
      };
      const res = await POST(GET_ALL_WARRANTY, obj);
      let data = [];
      if (res.success) {
        data = res.result.map((el, i) => {
          const { no_warranty, status, dateexp } = el;
          const end = moment(dateexp);
          const diff = end.diff(start, "days");
          return {
            no: i + 1,
            key: no_warranty,
            ...el,
            telephone: el.tel,
            create: dayjs(el.datenow).format("DD/MM/YYYY"),
            status: this.convertStatus(status),
            status_name: status,
            dateexp: dayjs(dateexp).format("DD/MM/YYYY"),
            time_warranty: diff <= 0 ? 0 : diff,
          };
        });
      }
      defaultData = data;

      this.setState({ data, excel_data: res.result });
    } catch (error) {
      Modal.error({ title: error.message });
      return fn.larkNotiMonitor("warranty => onFetchWarranty", error.message);
    }
    finally{
      this.setState({ isLoadingData: false });
    }
  }
  @autobind
  convertStatus(status) {
    switch (status) {
      case "PAYMENT":
        return 1;
      case "WAIT":
        return 2;
      case "USED":
        return 3;
      case "TRANSPORTED":
        return 4;
      case "REJECT":
        return 99;
      default:
        return 0;
    }
  }

  @autobind
  onChange(e, ref) {
    this.setState({ [ref]: e });
  }

  @autobind
  onKeyPressSearch(event, type) {
    if (event.key === "Enter") {
      if (type === "fullTextSearch") {
        this.onFetchWarranty(event.target.value);
      } else if (type === "searchText") {
        this.onSearch();
      }
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          : false;
      });
    });

    this.setState({ data: d });
  }
  @autobind
  onChangeRangePicker(dates) {
    let start = moment(dates[0]).format("YYYY-MM-DD");
    let end = moment(dates[1]).format("YYYY-MM-DD");

    this.setState(
      {
        start_date: start,
        end_date: end,
        isLoadingData: true,
      },
      () => this.onFetchWarranty("")
    );
  }

  @autobind
  clickImage(src_image) {
    this.setState({
      zoomImage: `${ip}${src_image}`,
      isOpen: true,
    });
  }


  @autobind
  downloadExcel() {
    const data_excel = this.state.data;
    let option = {};
    let datatable = [];
    let date_export = dayjs(new Date()).format("DD-MM-YYYY");

    let sheetHeader = [
      "ลำดับ",
      "เลขที่ประกัน",
      "รหัสรับประกัน",
      "หมวดหมู่",
      "สถานะ",
      "แบรนด์",
      "รุ่น",
      "รหัสสินค้า",
      "ชื่อสินค้า",
      "ช่องทางการสั่งซื้อ",
      "ชื่อ-นามสกุล",
      "เบอร์โทร",
      "ประเภทรับประกัน",
      "อีเมล์",
      "วันที่ลงทะเบียน",
      "เลขที่เคลม",
      "สาเหตุการเคลม",
      "วันที่เคลม",
      "ที่อยู่",
      "เพิ่มเติม",
      "เลขพัสดุ",
      // "unactive_code",
    ];

    if (data_excel) {
      data_excel.map((el, i) => {
        const {
          no,
          no_warranty,
          qrcode,
          category,
          status_name,
          brandmobile,
          modelmobile,
          item_code,
          nameproduct,
          name,
          tel,
          warranty_type_desc,
          email,
          datenow,
          no_claim,
          detail,
          dateclaim,
          address,
          note,
          tracking,
          namestore,
        } = el;

        let obj = {
          no,
          no_warranty,
          qrcode,
          category,
          status_name,
          brandmobile,
          modelmobile,
          item_code,
          nameproduct,
          namestore,
          name,
          tel,
          warranty_type_desc,
          email,
          datenow: moment(datenow).format("DD-MM-YYYY HH:mm:ss"),
          no_claim,
          detail,
          dateclaim: dateclaim
            ? moment(dateclaim).format("DD-MM-YYYY HH:mm:ss")
            : "",
          address,
          note,
          tracking,
        };
        datatable.push(obj);
      });
    }
    option.fileName = `รายงานลงทะเบียนรับประกันสินค้า ${date_export}`;
    option.datas = [
      {
        sheetData: datatable,
        sheetName: date_export,
        sheetHeader: sheetHeader,
      },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }
  @autobind
  goDetail(record) {
    window.open(`/warranty_detail?id=${record.no_warranty}`, "_blank");
  }

  render() {
    const {
      data,
      isLoadingData,
      searchText,
      loading,
      fullTextSearch,
      isOpen
    } = this.state;

    return (
      <div>

        {isOpen && (
          <Lightbox
            mainSrc={this.state.zoomImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}


        <div className={"column"}>
          {/*###################### HEAD ######################*/}

          <div className={"row-between"}>
            <BT
              type="primary"
              style={{
                backgroundColor: color.dplus_primary,
                borderColor: color.dplus_primary,
              }}
              onClick={this.downloadExcel}
              loading={loading}
            >
              Export excel
            </BT>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Input.Search
                style={{
                  width: "20vw",
                  borderRadius: "10vw",
                  paddingLeft: "1vw",
                }}
                placeholder={"ค้นหาข้อมูลตามวันที่"}
                value={searchText}
                onKeyPress={(e) => this.onKeyPressSearch(e, "searchText")}
                onSearch={() => this.onSearch()}
                onChange={(e) => this.onChange(e.target.value, "searchText")}
              />
            </div>
          </div>
          <div className={"row-between"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <DatePicker.RangePicker
                //value={moment(new Date())}
                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                format="YYYY-MM-DD"
                defaultValue={[
                  moment(this.state.start_date),
                  moment(this.state.end_date),
                ]}
                onChange={this.onChangeRangePicker}
                size="default"
              />

              {/* <Button title={'ยืนยัน'} type={'focus'} onPress={() => this.goCreate()} /> */}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Input.Search
              style={{
                width: "20vw",
                borderRadius: "10vw",
                paddingLeft: "1vw",
              }}
              placeholder={"ค้นหาข้อมูลทั้งหมดโดยไม่อิงตามวันที่"}
              value={fullTextSearch}
              onKeyPress={(e) => this.onKeyPressSearch(e, "fullTextSearch")}
              onChange={(e) => this.onChange(e.target.value, "fullTextSearch")}
            />
          </div>
          <div className="text-subtitle">
            <font>
              {
                "(* ค้นหาได้เฉพาะ เลขที่รับประกัน, ชื่อนามสกุล, ชื่อไลน์,รหัสรับประกัน และเบอร์โทรศัพท์)"
              }
            </font>
          </div>

          {/*###################### TABLE ######################*/}
          <Table
            title={() => <h2>รายงานการลงทะเบียน</h2>}
            // rowSelection={rowSelection}
            scroll={{ x: true }}
            columns={ColumnTableWarranty((record) =>
              this.goDetail(record)
            )}
            rowClassName={(record, i) => (i % 2 === 1 ? "even" : "")}
            dataSource={data}
            expandedRowRender={record => this.renderExpand(record)}
            bordered
            pagination={{ pageSize: 15 }}
            loading={isLoadingData}
            size="small"
          />
        </div>
      </div>
    );
  }


  renderExpand(record) {
    return (
      <div style={{ padding: "1vw", display: "flex", alignItems: "center" }}>
        
        <div style={{ paddingRight: '30px' }} >
          <p style={{ fontSize: "0.4cm", lineHeight: "40px", textAlign: 'center' }}>รูปใบเสร็จ</p>
          <img
            src={`${ip}${record.inv_image}`}
            style={{ width: 120, height: 120 }}
            onClick={() => this.clickImage(record.inv_image)}
            className={"contain"}
          />
        </div>
      

        <div>
          <p style={{ fontSize: "0.4cm", lineHeight: "40px", textAlign: 'center' }}>รูปสินค้า</p>
          <img
            src={`${ip}${record.package_image}`}
            style={{ width: 120, height: 120 }}
            onClick={() => this.clickImage(record.package_image)}
            className={"contain"}
          />
        </div>

       
      </div>
    );
  }

}

export default WarrantyScene;
