import cryptr from "crypto-js";
import { KEY_BILL } from "../resource";
import moment from "moment";
import { POST, LARK_NOTI_MONITOR } from "../service";
export const encryptParameter = (object) => {
  const encrypt = cryptr.AES.encrypt(
    JSON.stringify(object),
    KEY_BILL
  ).toString();
  return encrypt;
};

export const decryptParameter = (encrypt) => {
  return cryptr.AES.decrypt(encrypt, KEY_BILL).toString(cryptr.enc.Utf8);
};
export const append = (data) => {
  //file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, claim_id, status, line_id, claimdate }
  let fd = new FormData();
  fd.append("userId", data.line_id);
  fd.append("status", parseInt(data.status, 10));
  fd.append("name", data.name);
  fd.append("telephone", data.telephone);
  fd.append("province", data.province);
  fd.append("district", data.district);
  fd.append("amphoe", data.subdistrict);
  fd.append("zipcode", data.zipcode);
  fd.append("other", data.other);
  fd.append("description", data.description);
  fd.append("note", data.note);
  fd.append("product_id", data.product_id);
  fd.append("claim_doc", data.claim_doc);
  fd.append("image", data.file);
  fd.append("create_at", data.claimdate);
  fd.append("addressId", data.addressId);
  fd.append("type_sending", data.type_sending);

  return fd;
};
export const append_edit = (data) => {
  //file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, claim_id, status, line_id, claimdate }
  let fd = new FormData();
  fd.append("userId", data.line_id);
  fd.append("status", parseInt(data.status, 10));
  fd.append("name", data.name);
  fd.append("telephone", data.telephone);
  fd.append("province", data.province);
  fd.append("district", data.district);
  fd.append("amphoe", data.subdistrict);
  fd.append("zipcode", data.zipcode);
  fd.append("other", data.other);
  fd.append("description", data.description);
  fd.append("note", data.note);
  fd.append("product_id", data.product_id);
  fd.append("claim_doc", data.claim_doc);
  fd.append("image", data.file);
  fd.append("create_at", data.claimdate);
  fd.append("addressId", data.addressId);
  fd.append("type_sending", data.type_sending);
  fd.append("product_name", data.product_name);

  return fd;
};
export const append_focus_createclaim = (data) => {
  let fd = new FormData();

  fd.append("no_warranty", data.no_warranty);
  fd.append("detail", data.description);
  fd.append("detail2", data.other_description || "");
  fd.append("claim_image", data.file);
  fd.append("zipcode", data.zipcode);
  fd.append("province", data.province);
  fd.append("amphoe", data.amphoe);
  fd.append("district", data.district);
  fd.append("other", data.other);
  fd.append("type", data.type);
  fd.append("channel", data.channal);
  fd.append("user_id", data.user_id);
  fd.append("name", data.name);
  fd.append(
    "address",
    `${data.other} ต.${data.district} อ.${data.amphoe} จ.${data.province} ${data.zipcode}`
  );

  return fd;
};

export const appendClaim = (data) => {
  let fd = new FormData();

  fd.append("province", data.province);
  fd.append("district", data.district);
  fd.append("amphoe", data.amphoe);
  fd.append("zipcode", data.zipcode);
  fd.append("other", data.other);
  fd.append("detail", data.description);
  fd.append("detail2", data.detail2 || "");
  fd.append("note", data.note || "");
  fd.append("no_claim", data.claim_doc);
  fd.append("image", data.file);
  fd.append("item_code", data.item_code || "");
  fd.append("no_warranty", data.no_warranty);
  fd.append("product_id", data.product_id);
  fd.append("nameproduct", data.product_name);
  fd.append("brandmobile", data.brand_name);
  fd.append("modelmobile", data.model_name);
  fd.append("type_store", data.type_store);
  fd.append(
    "book_date",
    data.type_store === "FOCUS"
      ? moment(data.book_date).format("YYYY-MM-DD")
      : ""
  );

  return fd;
};
export const appendPayment = (dataPay) => {
  let dataUpdatePayment = new FormData();

  console.log({dataPay});

  dataUpdatePayment.append(["no_claim"], dataPay.no_claim);
  dataUpdatePayment.append(["no_warranty"], dataPay.no_warranty);
  dataUpdatePayment.append(["userLogin"], dataPay.userLogin || "");
  dataUpdatePayment.append(["tel"], dataPay.tel);
  dataUpdatePayment.append(["paymentImage"], dataPay.paymentImage || "");
  dataUpdatePayment.append(["bank_pay"], dataPay.bank_pay || "");
  dataUpdatePayment.append(["date_pay"], dataPay.date_pay || "");
  dataUpdatePayment.append(["time_pay"], dataPay.time_pay || "");
  dataUpdatePayment.append(["money_pay"], dataPay.money_pay || "");
  return dataUpdatePayment;
};

export const appen_register = (data) => {
  let fd = new FormData();

  fd.append("image", data.fileProduct);
  fd.append("line_id", data.line_id);
  fd.append("name", data.name);
  fd.append("telephone", data.telephone);
  fd.append("register_date", data.register_date);
  fd.append("product_name", data.product_name);
  fd.append("warranty_day", data.warranty_month);
  if (data.fileSlip) {
    fd.append("image", data.fileSlip);
  }
  return fd;
};
export const dateDiff = (endDate) => {
  const now = moment(new Date());
  const end = moment(endDate);
  const days = now.diff(end, "days");
  return days;
};
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
export const phonNumberValidate = (phone) => {
  let isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
    phone
  );

  return isphone;
};

export const downloadQR = () => {
  const canvas = document.getElementById("qrCode");
  const nameImg = document.getElementById("name-img");

  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = `${nameImg.defaultValue}.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const removeDuplicates = (data) => {
  return [...new Map(data.map((x) => [x.value, x])).values()];
};
export const larkNotiMonitor = async (module, message) => {
  const obj = { module, section: "WEB ADMIN", message };
  await POST(LARK_NOTI_MONITOR, obj);
};

export const cutURL = (url) => {
  if (url && url.includes(`https://www.ablemenbrand.com/warranty-store/`)) {
    return url.split("?fw=")[1];
  } else {
    return url;
  }
};
